/**
 * Modifying cities group array as the need of select
 * @param initial_cities_group_array It takes cities group array retrived from the api
 */
export const getAlteredCitiesGroup = array => {
    let modifiedList = array.map(e => {
      return {
        label: e.name === "" ? " " : e.name,
        value: e.id,
        id: e.id,
      };
    });
    return modifiedList;
  };
  
  /**
   * Modifying cities array as the need of select
   * @param initial_cities_group_array It takes cities array retrived from the api
   */
  export const getAlteredCities = array => {
    let modifiedList = array.map(e => {
      return {
        label: e.city===''?' ':e.city,
        value: e.city===''?' ':e.city,
        id: e.city_id,
      };
    });
    return modifiedList;
  };
  
