import { httpClient } from "lib/axios";
import { isCounterAdmin } from "pages/Auth/permissionGuard";

export const stock_apis = {
  get_stock_list: async (pageSize, pageNumber, counterId) => {
    let url = `stock_list/?page_size=${pageSize}&page_number=${pageNumber}`;
    if (counterId) {
      url = `stock_list/?page_size=${pageSize}&page_number=${pageNumber}&counter=${counterId}`;
    }

    return await httpClient
      .get(url)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_stock_list_for_counter: async counterId => {
    let url = "counter_stock/";
    if (isCounterAdmin() || counterId) {
      url = `counter_stock/?counter_id=${counterId}`;
    } else {
      url = `counter_stock/`;
    }
    return await httpClient
      .get(url)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_stock_list_for_counter_view: async counterId => {
    let url = "counter_stock/";
    if (isCounterAdmin() || counterId) {
      url = `counter_stock/?counter_id=${counterId}&view=true`;
    } else {
      url = `counter_stock/`;
    }
    return await httpClient
      .get(url)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_stock_products: async () => {
    return await httpClient
      .get(`stock_list/?product=true`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_stock_transfer_details: async stockId => {
    return await httpClient
      .get(`stock_list/?stock_id=${stockId}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  change_transfer_status: async payload => {
    return await httpClient.post(`stock_transfer/`, payload);
  },
  get_products: async () => {
    return await httpClient
      .get(`stock_list/?product=true`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  transfer_stock: async payload => {
    return await httpClient.post(`stock_list/`, payload);
  },
  edit_stock_transfer: async payload => {
    return await httpClient.put(`stock_list/`, payload);
  },
};
