import { joiResolver } from "@hookform/resolvers/joi";
import {
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Col,
  Container,
  Divider,
  Flex,
  Grid,
  Modal,
  NumberInput,
  Select,
  Switch,
  Text,
  TextInput,
  Textarea,
  Tooltip,
  TransferList,
  useMantineTheme,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons";
import { AnimatePresence, motion } from "framer-motion";
import Joi, { number } from "joi";
import { MantineReactTable } from "mantine-react-table";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";

export default function AddBatch(props) {
  const theme = useMantineTheme();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(false);


  const addBatchForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      batch_id: "",
      batch_no: "",
      mfd: new Date(),
      exp_date: new Date(),
      applicable_date: new Date(),
      mrp: "",
      selling_price: "",
      tally_status: false,
      stock: "",
      product: props.product?.id,
    },
  });
  useEffect(() => {
    if (!props.isEditing) {
      addBatchForm.reset();
    }
    if (props.isEditing && props.editingBatch !== null) {
      addBatchForm.setFieldValue("batch_id", props.editingBatch?.id);
      addBatchForm.setFieldValue("batch_no", props.editingBatch?.batch_no);
      addBatchForm.setFieldValue("mfd", new Date(props.editingBatch?.mfd));
      addBatchForm.setFieldValue("exp_date", new Date(props.editingBatch?.exp_date));
      addBatchForm.setFieldValue("applicable_date", new Date(props.editingBatch?.applicable_date));
      addBatchForm.setFieldValue("mrp", props.editingBatch?.mrp);
      addBatchForm.setFieldValue("selling_price", props.editingBatch?.selling_price);
      addBatchForm.setFieldValue("tally_status", props.editingBatch?.tally_status);
      addBatchForm.setFieldValue("stock", props.editingBatch?.stock);
    }
  }, [props.editingBatch, props.isEditing])


  return (
    <div>
      {props.batch ? <form
        onSubmit={addBatchForm.onSubmit(values => {
          console.log(values);
          //isEditing ? editCustomer(values) : addCustomer(values);
        })}
      >
        <Grid>
          <Col span={isMobileScreen ? 12 : 6}>
            <TextInput
              label="Batch Code"
              placeholder="Enter Batch Code"
              {...addBatchForm.getInputProps("batch_no")}
            />
          </Col>
          <Col span={isMobileScreen ? 12 : 6}>
            <DatePickerInput
              label="Mfd. Date"
              placeholder="Enter Mfd. Date"
              {...addBatchForm.getInputProps("mfd")}
            />
          </Col>
          <Col span={isMobileScreen ? 12 : 6}>

            <DatePickerInput
              label="Exp. Date"
              placeholder="Enter Exp. Date"
              {...addBatchForm.getInputProps("exp_date")}
            />
          </Col>
          <Col span={isMobileScreen ? 12 : 6}>

            <DatePickerInput
              label="Applicable Date"
              placeholder="Enter Applicable Date"
              {...addBatchForm.getInputProps("applicable_date")}
            />
          </Col>
          <Col span={isMobileScreen ? 12 : 6}>
            <NumberInput
              label="MRP"
              placeholder="Enter MRP"
              {...addBatchForm.getInputProps("mrp")}
              hideControls
            />
          </Col>
          <Col span={isMobileScreen ? 12 : 6}>
            <NumberInput
              label="Selling Price"
              placeholder="Enter Selling Price"
              {...addBatchForm.getInputProps("selling_price")}
              hideControls
            />
          </Col>
          <Col span={isMobileScreen ? 12 : 6}>
            <NumberInput
              label="Stock"
              placeholder="Enter Stock"
              {...addBatchForm.getInputProps("stock")}
              hideControls
            />
          </Col>
          <Col span={isMobileScreen ? 12 : 6}>
            <Text fz={"sm"} fw={500} mb={9}>Tally Status</Text>
            <Switch
              {...addBatchForm.getInputProps("tally_status", { type: "checkbox" })} />
          </Col>
          <Col span={12}>
            <Button mt={10} w={"100%"} type="submit" loading={loading}>
              {props.isEditing ? "Edit Batch" : "Add New Batch"}
            </Button>
          </Col>
        </Grid>
      </form> : <></>}
    </div>
  )
}