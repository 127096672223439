export const getFilterPayload = (columns, columnFilters) => {
  let payload = {};
  columnFilters?.map(e => {
    const data = columns.find(c => c.id === e.id);
    if (data?.filterVariant === "date-range") {
      if (
        e.value.length === 2 &&
        (e.value[0] === null || e.value[0] === "") &&
        (e.value[1] === null || e.value[1] === "")
      ) {
      } else if (
        e.value.length === 1 ||
        (e.value.length === 2 && (e.value[0] === null || e.value[0] === ""))
      ) {
        payload[e.id + "__icontains"] =
          e.value[1].getFullYear() +
          "-" +
          (e.value[1].getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          e.value[1].getDate().toString().padStart(2, "0");
      } else if (
        e.value.length === 1 ||
        (e.value.length === 2 && (e.value[1] === null || e.value[1] === ""))
      ) {
        payload[e.id + "__icontains"] =
          e.value[0].getFullYear() +
          "-" +
          (e.value[0].getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          e.value[0].getDate().toString().padStart(2, "0");
      } else {
        payload[e.id + "__gte"] =
          e.value[0].getFullYear() +
          "-" +
          (e.value[0].getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          e.value[0].getDate().toString().padStart(2, "0");
        payload[e.id + "__lte"] =
          e.value[1].getFullYear() +
          "-" +
          (e.value[1].getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          e.value[1].getDate().toString().padStart(2, "0");
      }
    } else if (data?.filterVariant === "range") {
      //payload[e.id] = e.value === "true" ? 1 : 0;

      // console.log(e.value);
      if (
        e.value.length === 2 &&
        (e.value[0] === null || e.value[0] === "") &&
        (e.value[1] === null || e.value[1] === "")
      ) {
      } else if (e.value.length === 1 || (e.value.length === 2 && e.value[0] === "~")) {
        payload[e.id + "__lte"] = e.value[1];
      } else if (
        e.value.length === 1 ||
        (e.value.length === 2 && (e.value[0] === null || e.value[0] === ""))
      ) {
        payload[e.id + "__icontains"] = e.value[1];
      } else if (e.value.length === 1 || (e.value.length === 2 && e.value[1] === "~")) {
        payload[e.id + "__gte"] = e.value[0];
      } else if (
        e.value.length === 1 ||
        (e.value.length === 2 && (e.value[1] === null || e.value[1] === ""))
      ) {
        payload[e.id + "__icontains"] = e.value[0];
      } else {
        payload[e.id + "__gte"] = e.value[0];
        payload[e.id + "__lte"] = e.value[1];
      }
    } else if (e.id === "active") {
      payload[e.id] = e.value === "true" ? 1 : 0;
    } else {
      payload[e.id + "__icontains"] = e.value;
    }
  });
  return payload;
};

export const getFields = columns => {
  let fields = [];
  columns.map(column => {
    fields.push(column.id);
  });
  return fields;
};

export const getHeaders = columns => {
  let headers = [];
  columns.map(column => {
    headers.push(column.header);
  });
  return headers;
};

export const getBodyContent = (data, fields) => {
  let body_content = [];
  data?.map(e => {
    let row = [];
    fields?.map(f => {
      row.push(e[f]);
    });
    body_content.push(row);
  });
  return body_content;
};

export const getCsvContent = (head_content, body_content, header) => {
  let csv_data = [header];
  csv_data.push(head_content);
  body_content.map((e, i) => {
    csv_data.push(e);
  });
  return csv_data;
};

export const getCustomId = (columns, columnFilters, appendData) => {
  console.log(columns, columnFilters, appendData);
  let custom = {
    columns: [],
    columnFilters: [],
  };
  appendData?.map(e => {
    let column = { ...columns?.find(c => c.id === e.id) };
    if (column) {
      if (column.id === undefined) {
        column.id = e?.append + e.id;
      } else {
        column.id = e?.append + column.id;
      }
    }
    let columnFilter = {
      ...columnFilters?.find(
        c => c.id === (e.append + e.id).replace("__", "_") || c.id === e?.replace_id
      ),
    };
    if (columnFilter && columnFilter?.id) {
      console.log(columnFilter);
      columnFilter.id = e?.append + e?.id;
      custom.columnFilters.push(columnFilter);
    }
    custom.columns.push(column);
  });
  return custom;
};

export const getTotal = (rows, columnTotalIndex) => {
  // console.log(rows, columnTotalIndex);
  if (rows.length === 0 || rows[0].length === 0) {
    return [];
  }

  let total = new Array(rows[0].length);
  rows[0].map((e, i) => {
    total[i] = "";
  });
  // console.log(total);

  rows.map(r => {
    columnTotalIndex?.map(v => {
      total[v] = (
        (isNaN(parseFloat(total[v])) ? 0 : parseFloat(total[v])) +
        (isNaN(parseFloat(r[v])) ? 0 : parseFloat(r[v]))
      ).toFixed(2);
    });
  });
  // console.log(total);
  return total;
};
