import {
  Box,
  Button,
  Col,
  Divider,
  Flex,
  Grid,
  LoadingOverlay,
  Modal,
  Table,
  Text,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import { isCounterAdmin,getCounterId } from "pages/Auth/permissionGuard";
import { api_getPaymentReceipt } from "pages/transactions/payment-receipt/paymentReceipt.service";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import AddPaymentReceipt from "./AddPaymentReceipt";

function ViewPaymentReceipts({ opened, onClose, invoiceId, invoiceData }) {
  const [counter, setCounter] = useState(isCounterAdmin() ? getCounterId(): '');
  const [paymentReceipts, setPaymentReceipts] = useState([]);
  const [balanceAmount, setBalanceAmount] = useState(null);

  // Add Payment receipt modal
  const [addPaymentReceiptOpened, setAddPaymentReceiptOpened] = useState(false);

  const payment_receipts = useQuery(
    ["payment_receipts", invoiceId],
    () => api_getPaymentReceipt(counter, invoiceId),
    {
      onSuccess: data => {
        console.log("Actual data", data);
        setPaymentReceipts(data.data);
      },
    }
  );

  useEffect(() => {
    const total_receipt_amount = paymentReceipts.reduce((sum, { amount }) => sum + amount, 0);
    console.log("total of receipts :", total_receipt_amount);
    setBalanceAmount(+invoiceData.total - +total_receipt_amount);
  }, [invoiceId, invoiceData, paymentReceipts]);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          onClose();
          setBalanceAmount(null);
        }}
        styles={{ header: { borderBottom: "1px solid #eee" }, body: { padding: 0 } }}
        title={
          <Box px={10}>
            <Text> Payment Receipts </Text>
          </Box>
        }
        size={"800px"}
        centered
        pos={"relative"}
      >
        <LoadingOverlay visible={payment_receipts.isLoading} />
        <Box>
          <Box my={20} px={30}>
            <Grid>
              <Col span={3}>
                <Text c="dimmed" fw={500} size={"xs"}>
                  Invoice No.
                </Text>
                <Text>{invoiceData?.invoice_id}</Text>
              </Col>
              <Col span={3}>
                <Text c="dimmed" fw={500} size={"xs"}>
                  Total Amount
                </Text>
                <Text> ₹ {invoiceData?.total}</Text>
              </Col>
              <Col span={3}>
                <Text c="dimmed" fw={500} size={"xs"}>
                  Balance Amount
                </Text>
                <Text>₹ {balanceAmount?.toFixed(2).toLocaleString(2) || 0}</Text>
              </Col>
            </Grid>
          </Box>

          <Divider opacity={0.5} />

          <Box mt={20} mb={40} px={30}>
            <Text c="dimmed" fw={500} size={"sm"} mb={10}>
              Payment Receipts
            </Text>
            <Table withColumnBorders striped withBorder fontSize={13} mb={20}>
              <thead>
                <tr>
                  <th>Payment Id</th>
                  <th>Payment Type</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {paymentReceipts?.map((paymentReceipt, index) => (
                  <tr key={index}>
                    <td>{paymentReceipt !== undefined ? paymentReceipt.id : ""}</td>
                    <td>{paymentReceipt !== undefined ? paymentReceipt.mode_of_payment : ""}</td>
                    <td>₹ {paymentReceipt !== undefined ? paymentReceipt.amount : ""}</td>
                    <td>{paymentReceipt !== undefined ? paymentReceipt.date : ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Box>
      </Modal>

      <AddPaymentReceipt
        opened={addPaymentReceiptOpened}
        onClose={() => {
          setAddPaymentReceiptOpened(false);
        }}
      />
    </>
  );
}

export default ViewPaymentReceipts;
