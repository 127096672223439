import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconRuler2 } from "@tabler/icons";
import Joi from "joi";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import AppBreadcrumb from "../../components/AppBreadcrumb";

export default function ManageAccount(props) {
  const tableInstanceRef = useRef(null);

  const pageTitle = "Manage Your Account";
  const theme = useMantineTheme();

  const isMobileScreen = useMediaQuery("(max-width: 768px)");

  const emptyMessage = label => {
    return {
      "string.empty": `${label} is required.`,
      "number.base": `${label} is required.`,
    };
  };

  const [passwordFormDisabled, setPasswordFormDisabled] = useState(true);

  const validationSchema = Joi.object({
    current_password: Joi.string().required().messages(emptyMessage("Current Password")),
    new_password: Joi.string().required().messages(emptyMessage("New Password")),
    confirm_password: Joi.any()
      .equal(Joi.ref("new_password"))
      .required()
      .label("Confirm password")
      .messages({ "any.only": "Confirm password must match new password." }),
  });

  const {
    control,
    register: form,
    handleSubmit,
    reset,
    setValue,
    setValues,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    resolver: joiResolver(validationSchema),
  });

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      {!props.onlyTable && (
        <Container size={"xl"}>
          <Box px={isMobileScreen ? 0 : 25} py={isMobileScreen ? 15 : 25}>
            <Flex justify={"space-between"} align="end" wrap={"wrap"}>
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  {pageTitle}
                </Text>
                <AppBreadcrumb />
              </Box>
            </Flex>
          </Box>
        </Container>
      )}

      {/* STARTS :: Main content */}

      <Container size={"xl"}>
        <Box px={isMobileScreen ? 0 : 25}>
          <Paper
            mb={20}
            sx={theme => ({
              border: "1px solid #eee",
              borderRadius: 2,
            })}
          >
            <Box
              sx={theme => ({
                borderBottom: `1px solid ${theme.colors.brand[0]}`,
                borderRadius: 2,
              })}
            >
              <Flex px={28} py={14} align={"center"}>
                <Text opacity={0.5} tt={"uppercase"} fw={600} size={"12px"}>
                  Profile Details
                </Text>
              </Flex>
            </Box>
            <Box p={30} pt={20} bg="white" sx={{ borderRadius: 2 }}>
              <Grid>
                <Grid.Col span={1}>
                  <Image
                    src={"https://picsum.photos/200"}
                    width={"60px"}
                    height={"60px"}
                    radius={100}
                  ></Image>
                </Grid.Col>
                <Grid.Col span={11} pt={14}>
                  <Grid>
                    <Grid.Col sm={3}>
                      <Box>
                        <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                          Name
                        </Text>
                        <Text size={"14px"} fw={500} mb={5} tt={"capitalize"}>
                          {user?.user.first_name || "-"}
                        </Text>
                      </Box>
                    </Grid.Col>

                    <Grid.Col sm={3}>
                      <Box>
                        <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                          Email Address
                        </Text>
                        <Text size={"14px"} fw={500} mb={5} tt={"capitalize"}>
                          {user?.user.email || "-"}
                        </Text>
                      </Box>
                    </Grid.Col>
                    <Grid.Col sm={3}>
                      <Box>
                        <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                          Username / Phone
                        </Text>
                        <Text size={"14px"} fw={500} mb={5} tt={"capitalize"}>
                          {user?.user.username || "-"}
                        </Text>
                      </Box>
                    </Grid.Col>
                    <Grid.Col sm={3}>
                      <Box>
                        <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                          Role
                        </Text>
                        <Text size={"14px"} fw={500} mb={5} tt={"capitalize"}>
                          {localStorage.getItem("role") || ""}
                        </Text>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
            </Box>
          </Paper>
          <Paper
            mb={20}
            sx={theme => ({
              border: "1px solid #eee",
              borderRadius: 2,
            })}
          >
            <Box
              sx={theme => ({
                borderBottom: `1px solid ${theme.colors.brand[0]}`,
                borderRadius: 2,
              })}
            >
              <Flex px={28} py={14} align={"center"} justify="space-between">
                <Text opacity={0.5} tt={"uppercase"} fw={600} size={"12px"}>
                  Password
                </Text>
                <Button
                  onClick={() => {
                    setPasswordFormDisabled(false);
                  }}
                  size={"xs"}
                  variant={"outline"}
                >
                  Change Password
                </Button>
              </Flex>
            </Box>
            <Box p={30} pt={20} bg="white" sx={{ borderRadius: 2 }}>
              <form
                onError={() => {}}
                onSubmit={handleSubmit(values => {
                  console.log(values);
                })}
              >
                <Grid>
                  <Grid.Col span={4}>
                    <Controller
                      name="current_password"
                      control={control}
                      render={({ field }) => (
                        <PasswordInput
                          disabled={passwordFormDisabled}
                          label="Current Password"
                          placeholder="Enter current password"
                          error={errors.current_password?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Controller
                      name="new_password"
                      control={control}
                      render={({ field }) => (
                        <PasswordInput
                          disabled={passwordFormDisabled}
                          label="New Password"
                          placeholder="Enter new password"
                          error={errors.new_password?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Controller
                      name="confirm_password"
                      control={control}
                      render={({ field }) => (
                        <PasswordInput
                          disabled={passwordFormDisabled}
                          label="Confirm Password"
                          placeholder="Confirm password"
                          error={errors.confirm_password?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Button disabled={passwordFormDisabled} type="submit" w={"100%"}>
                      Change Password
                    </Button>
                  </Grid.Col>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>

      {/* ENDS :: Main content */}

      {/* ENDS :: Add User Modal */}
    </>
  );
}
