import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Col,
  Divider,
  Flex,
  Grid,
  Modal,
  Select,
  Slider,
  Switch,
  Text,
  TextInput,
  Textarea,
  useMantineTheme,
} from "@mantine/core";
import Joi from "joi";
import { getAlteredCities } from "pages/master/products/helperFunctions";
import { products_api } from "pages/master/products/products.service";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import { counters_api } from "./counter.service";

function AddCounterModal({ opened, onClose, isEditing, editData }) {
  const queryClient = useQueryClient();
  const theme = useMantineTheme();

  const [cityGroups, setCityGroups] = useState([]);

  const emptyMessage = (label, length) => {
    return {
      "string.empty": `${label} is required.`,
      "number.base": `${label} is required.`,
      "string.length": `${label} must be ${length} digits.`,
    };
  };

  const validationSchema = Joi.object({
    name: Joi.string().required().messages(emptyMessage("Counter Name")),
    contact_person: Joi.string().required().messages(emptyMessage("Counter Person")),
    contact_number: Joi.number().required().min(10).messages(emptyMessage("Contact No.")),
    fssai: Joi.string().length(14).required().messages(emptyMessage("FSSAI Number", 14)),
    // .test("len", "Must be exactly 14 characters", val => val.length === 14)
    address: Joi.string().required().messages(emptyMessage("Address")),
    gst: Joi.string().length(15).required().messages(emptyMessage("GST Number", 15)),
    invoice_prefix: Joi.optional().allow(null).messages(emptyMessage("Invoice Prefix")),
    location_id: Joi.number().required().messages(emptyMessage("Location")),
    id: Joi.optional().allow(null),
    warehouse: Joi.optional().allow(null),
    lt_500: Joi.number().required().messages(emptyMessage("lt_500")),
    gt_500_lt_1500: Joi.number().required().messages(emptyMessage("gt_500_lt_1500")),
    gt_1500_lt_3000: Joi.number().required().messages(emptyMessage("gt_1500_lt_3000")),
    gt_3000: Joi.number().required().messages(emptyMessage("gt_3000")),
    pk_lt_500: Joi.number().required().messages(emptyMessage("pk_lt_500")),
    pk_gt_500_lt_1500: Joi.number().required().messages(emptyMessage("pk_gt_500_lt_1500")),
    pk_gt_1500_lt_3000: Joi.number().required().messages(emptyMessage("pk_gt_1500_lt_3000")),
    pk_gt_3000: Joi.number().required().messages(emptyMessage("pk_gt_3000")),
  });

  const {
    control,
    register: form,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      contact_person: "",
      contact_number: "",
      fssai: "",
      address: "",
      gst: "",
      invoice_prefix: "",
      location_id: "",
      id: null,
      warehouse: false,
      lt_500: null,
      gt_500_lt_1500: null,
      gt_1500_lt_3000: null,
      gt_3000: null,
      pk_lt_500: null,
      pk_gt_500_lt_1500: null,
      pk_gt_1500_lt_3000: null,
      pk_gt_3000: null,
    },
    resolver: joiResolver(validationSchema),
  });

  const counterMutation = useMutation({
    mutationFn: data => (isEditing ? counters_api.edit(data) : counters_api.add(data)),
    onSuccess: data => {
      console.log(data);
      showSuccessToast({
        title: "Success",
        message: isEditing ? "Edited Counter" : "Added Counter.",
      });
      queryClient.invalidateQueries(["counters-data"]);
      onClose();
    },
    onError: () => {
      showErrorToast({
        title: "Error",
        message: isEditing ? "Error editing counter" : "Error adding Counter.",
      });
    },
  });

  const get_cities = useQuery("cities", products_api.getCities, {
    onSuccess: data => {
      const actual_data = getAlteredCities(data.data);
      console.log(actual_data);

      console.log("city data", data.data);

      const flat = data.data.flatMap(e => e.city);

      console.log("flat", flat);

      const cities = flat.map(e => {
        return {
          label: e.city,
          value: e.id,
          group: e.city_group_name,
        };
      });

      setCityGroups(cities);
    },
  });

  const submit = async values => {
    counterMutation.mutate(values);
  };

  useEffect(() => {
    if (isEditing) {
      console.log("Edit data", editData);
      reset({
        id: editData.id,
        name: editData.name,
        contact_person: editData.contact_person,
        contact_number: editData.contact_number,
        address: editData.address,
        fssai: editData.fssai,
        gst: editData.gst,
        invoice_prefix: editData.invoice_prefix,
        location_id: editData.location,
        warehouse: editData.warehouse,
        lt_500: editData.lt_500,
        gt_500_lt_1500: editData.gt_500_lt_1500,
        gt_1500_lt_3000: editData.gt_1500_lt_3000,
        gt_3000: editData.gt_3000,
        pk_lt_500: editData.pk_lt_500,
        pk_gt_500_lt_1500: editData.pk_gt_500_lt_1500,
        pk_gt_1500_lt_3000: editData.pk_gt_1500_lt_3000,
        pk_gt_3000: editData.pk_gt_3000,
      });
    }
  }, [isEditing, editData]);

  return (
    <div>
      <Modal
        opened={opened}
        onClose={onClose}
        title={
          <Box px={10}>
            <Text fw={600}>{isEditing ? "Edit conuter" : "Add Counter"}</Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={3} />
          <Box>
            <form
              onSubmit={handleSubmit(values => {
                console.log(values);
                submit(values);

                // console.log("gey");
              })}
            >
              <Grid>
                <Col sm={6}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label="Counter Name"
                        placeholder="Enter Counter Name"
                        error={errors.name?.message}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Controller
                    name="contact_person"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label="Contact person"
                        placeholder="Enter Counter Person Name"
                        error={errors.contact_person?.message}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Controller
                    name="contact_number"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label="Counter contact"
                        placeholder="Enter Counter contact"
                        error={errors.contact_number?.message}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Controller
                    name="invoice_prefix"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label="Invoice Prefix"
                        placeholder="Enter Invoice Prefix"
                        error={errors.invoice_prefix?.message}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={12}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <Textarea
                        label="Address"
                        placeholder="Enter Address"
                        error={errors.address?.message}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Controller
                    name="fssai"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label="FSSAI Number"
                        placeholder="Enter FSSAI Number"
                        error={errors.fssai?.message}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Controller
                    name="gst"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label="GST Number"
                        placeholder="Enter GST Number"
                        error={errors.gst?.message}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Controller
                    name="location_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        searchable
                        data={cityGroups}
                        label="Select City"
                        placeholder="Enter City"
                        error={errors.location_id?.message}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Flex h={"100%"} align={"center"}>
                    <Controller
                      name="warehouse"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          ml={5}
                          defaultChecked={editData.warehouse || false}
                          // defaultValue={editData.warehouse || false}
                          label="Is Warehouse ?"
                          error={errors.warehouse?.message}
                          {...field}
                        />
                      )}
                    />
                  </Flex>
                </Col>
                <Col sm={12}>
                  <Text ta={"center"} w={"100%"} color="white" bg={"grey"}>
                    Delivery Charges
                  </Text>
                </Col>
                <Col sm={6}>
                  <Grid>
                    <Col span={5}>
                      <Flex direction={"column"} justify={"center"} h="100%">
                        <Text>LT 500</Text>
                      </Flex>
                    </Col>
                    <Col span={7}>
                      <Controller
                        name="lt_500"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Enter lt_500"
                            error={errors.lt_500?.message}
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Grid>
                </Col>
                <Col sm={6}>
                  <Grid>
                    <Col span={5}>
                      <Flex direction={"column"} justify={"center"} h="100%">
                        <Text>GT 500 LT 1500</Text>
                      </Flex>
                    </Col>
                    <Col span={7}>
                      <Controller
                        name="gt_500_lt_1500"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Enter gt_500_lt_1500"
                            error={errors.gt_500_lt_1500?.message}
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Grid>
                </Col>
                <Col sm={6}>
                  <Grid>
                    <Col span={5}>
                      <Flex direction={"column"} justify={"center"} h="100%">
                        <Text>GT 1500 LT 3000</Text>
                      </Flex>
                    </Col>
                    <Col span={7}>
                      <Controller
                        name="gt_1500_lt_3000"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Enter gt_1500_lt_3000"
                            error={errors.gt_1500_lt_3000?.message}
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Grid>
                </Col>
                <Col sm={6}>
                  <Grid>
                    <Col span={5}>
                      <Flex direction={"column"} justify={"center"} h="100%">
                        <Text>GT 3000</Text>
                      </Flex>
                    </Col>
                    <Col span={7}>
                      <Controller
                        name="gt_3000"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Enter gt_3000"
                            error={errors.gt_3000?.message}
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Grid>
                </Col>
                <Col sm={12}>
                  <Text ta={"center"} w={"100%"} color="white" bg={"grey"}>
                    Packaging Charges
                  </Text>
                </Col>
                <Col sm={6}>
                  <Grid>
                    <Col span={5}>
                      <Flex direction={"column"} justify={"center"} h="100%">
                        <Text>LT 500</Text>
                      </Flex>
                    </Col>
                    <Col span={7}>
                      <Controller
                        name="pk_lt_500"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Enter lt_500"
                            error={errors.pk_lt_500?.message}
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Grid>
                </Col>
                <Col sm={6}>
                  <Grid>
                    <Col span={5}>
                      <Flex direction={"column"} justify={"center"} h="100%">
                        <Text>GT 500 LT 1500</Text>
                      </Flex>
                    </Col>
                    <Col span={7}>
                      <Controller
                        name="pk_gt_500_lt_1500"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Enter gt_500_lt_1500"
                            error={errors.pk_gt_500_lt_1500?.message}
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Grid>
                </Col>
                <Col sm={6}>
                  <Grid>
                    <Col span={5}>
                      <Flex direction={"column"} justify={"center"} h="100%">
                        <Text>GT 1500 LT 3000</Text>
                      </Flex>
                    </Col>
                    <Col span={7}>
                      <Controller
                        name="pk_gt_1500_lt_3000"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Enter gt_1500_lt_3000"
                            error={errors.pk_gt_1500_lt_3000?.message}
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Grid>
                </Col>
                <Col sm={6}>
                  <Grid>
                    <Col span={5}>
                      <Flex direction={"column"} justify={"center"} h="100%">
                        <Text>GT 3000</Text>
                      </Flex>
                    </Col>
                    <Col span={7}>
                      <Controller
                        name="pk_gt_3000"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Enter gt_3000"
                            error={errors.pk_gt_3000?.message}
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Grid>
                </Col>
                <Col span={12} my={10}>
                  <Flex h={"100%"} direction={"column"} justify="end" align={"center"}>
                    <Button
                      w={"100%"}
                      type="submit"
                      onClick={() => {
                        console.log(errors);
                      }}
                    >
                      {isEditing ? "Edit Counter" : "Add New Counter"}
                    </Button>
                  </Flex>
                </Col>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

AddCounterModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  counterId: PropTypes.number,
  editData: PropTypes.object,
};

export default AddCounterModal;
