import {
  ActionIcon,
  Box,
  Button,
  Container,
  Flex,
  Modal,
  Rating,
  Switch,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconCheck, IconEye, IconTrash, IconX } from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import produce from "immer";
import _ from "lodash";
import { MantineReactTable } from "mantine-react-table";
import React, { useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { showErrorToast } from "utilities/Toast";

import { feedback_api } from "./feedback.service";

/**
 *
 * @param {{ review : Object }} props
 */

const delete_confirm_props = (deleteFunction, id) => {
  return {
    title: "Please confirm delete feedback",
    children: <Text size="sm">Are you sure you want to delete selected feedback ?</Text>,
    labels: { confirm: "Delete Feedback", cancel: "Cancel" },
    onCancel: () => console.log("Cancel"),
    onConfirm: async () => await deleteFunction(id),
    confirmProps: { color: "red" },
  };
};

export default function FeedbackList(props) {
  const pageTitle = "Feedback List";
  const tableInstanceRef = useRef(null);
  const theme = useMantineTheme();

  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  const [feeaback, setFeedback] = useState([]);

  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [modalFeedback, setModalFeedback] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const feeabacks_data = useQuery({
    queryKey: ["feedbacks-data", pagination.pageIndex, pagination.pageSize],
    queryFn: () => feedback_api.get(pagination.pageSize, pagination.pageIndex + 1),
  });

  const columns = useMemo(
    () => [
      {
        header: "User",
        accessorKey: "user",
        size: "auto",
        Cell: ({ cell }) => <Text tt={"capitalize"}>{cell.getValue()}</Text>,
      },
      {
        header: "Title",
        accessorKey: "title",
        size: "auto",
      },
      {
        header: "Description",
        accessorKey: "description",
        size: "auto",
        Cell: ({ renderedCellValue, cell, row }) => (
          <Box>
            <Text
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              maw={400}
            >
              {row.original.description}
            </Text>
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <>
      {!props.onlyTable && !isMobileScreen && (
        <Container fluid>
          <Box px={isMobileScreen ? 0 : 25} py={isMobileScreen ? 15 : 25}>
            <Flex justify={"space-between"} align="end" wrap={"wrap"}>
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  {pageTitle}
                </Text>
                <AppBreadcrumb />
              </Box>
            </Flex>
          </Box>
        </Container>
      )}

      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : props.onlyTable ? 0 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              // tableInstanceRef={tableInstanceRef}
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Feedback List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  <Tooltip label="View Feedback">
                    <ActionIcon
                      sx={theme => ({
                        color: theme.colors.brand[7],
                      })}
                      onClick={() => {
                        setFeedbackModalVisible(true);
                        setModalFeedback(row.original);
                      }}
                    >
                      <IconEye style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              )}
              initialState={{ density: "xs" }}
              state={{
                isLoading: feeabacks_data.isLoading || feeabacks_data.isRefetching,
                pagination,
              }}
              onPaginationChange={setPagination}
              manualPagination
              columns={columns}
              getRowId={originalRow => originalRow.id}
              enableDensityToggle={false}
              enableFullScreenToggle={isMobileScreen}
              enableColumnActions={false}
              rowCount={feeabacks_data.data?.data?.count}
              data={feeabacks_data.data?.data?.data || []}
              enableStickyHeader
              enableGlobalFilter={true} //turn off a feature
            />
          </Box>
        </Box>
      </Container>
      <Modal
        opened={feedbackModalVisible}
        onClose={() => {
          setFeedbackModalVisible(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}> See Feedback </Text>
          </Box>
        }
        size={"960px"}
        centered
      >
        <Box p={10}>
          <Flex mb={15}>
            <Box>
              <Text> {modalFeedback.user} </Text>
            </Box>
          </Flex>
          <Text size={"16px"} fw={500} mb={5}>
            {modalFeedback.title}
          </Text>
          <Text size={"14px"}>{modalFeedback.description}</Text>
        </Box>
      </Modal>
    </>
  );
}
