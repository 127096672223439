import {
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Flex,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconDownload, IconPrinter } from "@tabler/icons";
import FloatingMenu from "components/FloatingMenu";
import { PrintModal } from "components/PrintModal";
import { MantineReactTable } from "mantine-react-table";
import { getCounterId, isCounterAdmin } from "pages/Auth/permissionGuard";
import { counters_api } from "pages/master/counter/counter.service";
import { getAlteredCounters } from "pages/master/customers/helperFunctions";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useReactToPrint } from "react-to-print";
import {
  clearCustomId,
  getBodyContent,
  getCsvContent,
  getCustomId,
  getFields,
  getFilterPayload,
  getHeaders,
  getTotal,
} from "services/filterHelperFunctions";
import { api_filter } from "services/master.service";
import { showErrorToast } from "utilities/Toast";

import { stock_apis } from "./stock-transfer/stock-transfer.service";
import AppBreadcrumb from "../../components/AppBreadcrumb";
import { api_getProducts } from "../../services/product.service";

export default function StockList() {
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  const [countersList, setCountersList] = useState([]);
  const [counter, setCounter] = useState(isCounterAdmin() ? getCounterId() : "");
  let isMobileScreen = useMediaQuery("(max-width: 768px)");
  const [columnFilters, setColumnFilters] = useState([]);
  const [filename, setFilename] = useState("file");

  const counter_data = useQuery({
    queryKey: ["counters-data-list"],
    queryFn: () => counters_api.get(),
    onSuccess: data => {
      setCountersList(getAlteredCounters(data.data));
    },
  });

  const stock_data = useQuery({
    queryKey: ["stock-list-data", counter],
    queryFn: () => stock_apis.get_stock_list_for_counter_view(counter),
  });

  const columns = useMemo(
    () => [
      {
        header: "Product Code",
        accessorKey: "product_code",
        size: "auto",
        filterVariant: "range",
      },
      {
        header: "Product Name",
        accessorKey: "title",
        size: "auto",
      },
      {
        header: "MRP",
        accessorKey: "mrp",
        size: "auto",
        filterVariant: "range",
        Cell: ({ cell }) => <span> ₹ {cell.getValue()}</span>,
      },
      {
        header: "Price",
        accessorKey: "selling_price",
        size: "auto",
        filterVariant: "range",
        Cell: ({ cell }) => <span> ₹ {cell.getValue()}</span>,
      },
      {
        header: "Stock Qty.",
        accessorKey: "stock_qty",
        size: "auto",
        filterVariant: "range",
        Cell: ({ cell }) => <Text fw={"500"}> {cell.getValue()}</Text>,
      },
      {
        header: "Total",
        size: "auto",
        Cell: ({ cell }) => {
          return (
            <>
              <Text fw={"500"}>
                {" "}
                {(cell.row.original.selling_price * cell.row.original.stock_qty).toFixed(2)}
              </Text>
            </>
          );
        },
      },
    ],
    []
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  //   //return parseFloat(text.replace(/[^0-9.]/g, ""));
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   return total;
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length !== i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   const cols = [2, 3, 4, 5];
  //   let sum = [];
  //   get_sum(removeAction).map((e, i) => {
  //     if (isNaN(e) || !cols?.includes(i, 0)) {
  //       sum.push("");
  //     } else {
  //       sum.push(e);
  //     }
  //   });
  //   csv_data.push(sum);
  //   setCsv(csv_data);
  // };

  const printFilter = () => {
    const model = "CounterStock";

    const appendData = [
      { id: "product_code", append: "product__", replace_id: "product_code" },
      { id: "title", append: "product__", replace_id: "title" },
      { id: "mrp", append: "product__", replace_id: "mrp" },
      { id: "selling_price", append: "product__", replace_id: "selling_price" },
      { id: "stock_qty", append: "product__", replace_id: "stock_qty" },
      // { id: "id", append: "" },
    ];
    const custom = getCustomId(columns, columnFilters, appendData);
    const filter = getFilterPayload(custom?.columns, custom?.columnFilters);
    const fields = getFields(custom?.columns);

    api_filter(model, filter, fields)
      .then(res => {
        if (res.success) {
          console.log(res.data);
          const head_content = getHeaders(columns);
          const body_content = getBodyContent(res.data, fields);
          console.log(body_content);
          body_content.map(e => {
            e.push(((e[3] === null ? 0 : e[3]) * (e[4] === null ? 0 : e[4])).toFixed(2));
            return e;
          });
          const csv_content = getCsvContent(head_content, body_content, ["STOCKS"]);
          const columnTotalIndex = [2, 3, 4, 5];
          const foot_content = getTotal(body_content, columnTotalIndex);
          setFilename(
            "STOCKS" +
              "_" +
              countersList?.find(e => e.id === counter)?.label +
              "_" +
              new Date().toLocaleDateString() +
              ".csv"
          );
          setCsv(csv_content);
          setHead(head_content);
          setBody(body_content);
          setFoot(foot_content);
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      {isMobileScreen ? (
        <>
          <Select
            p={5}
            label="Counter"
            defaultValue={isCounterAdmin() ? counter : null}
            placeholder="Select Counter"
            data={countersList || []}
            onChange={setCounter}
            clearable
            disabled={isCounterAdmin()}
          />
        </>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Stock List
                </Text>
                <AppBreadcrumb
                  crumbs={[
                    {
                      text: "Inventory",
                    },
                    {
                      text: "Stock List",
                    },
                  ]}
                />
              </Box>

              <Flex align={"flex-end"} gap={10}>
                <Button
                  onClick={() => {
                    setIsCsv(true);
                    printFilter();
                  }}
                  leftIcon={<IconDownload />}
                >
                  CSV
                </Button>
                {/* <Button
                  leftIcon={<IconPrinter />}
                  onClick={() => {
                    print(false);
                  }}
                >
                  Print
                </Button> */}
                {/* <Button
                  leftIcon={<IconPrinter />}
                  onClick={() => {
                    printFilter();
                  }}
                >
                  Print
                </Button> */}
                <Select
                  label="Counter"
                  defaultValue={isCounterAdmin() ? counter : null}
                  placeholder="Select Counter"
                  data={countersList || []}
                  onChange={setCounter}
                  clearable
                  disabled={isCounterAdmin()}
                />
              </Flex>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        {/* Add Product Form */}

        {/* Add Product Form */}

        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={columnFilters} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable", striped: true }}
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    List
                  </Text>
                </Box>
              )}
              initialState={{ density: "xs" }}
              // state={{
              //   isLoading: stock_data.isLoading || stock_data.isRefetching,
              // }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              // data={products_data.data?.data || []}
              data={stock_data.data?.data || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}
      <div style={{ display: "none" }}>
        <PrintModal
          cols={[2, 3, 4, 5]}
          title="Stock List"
          head={head}
          body={body}
          foots={foot}
          ref={componentRef}
        />
      </div>

      {isMobileScreen ? (
        <>
          <FloatingMenu
            m={5}
            left
            size={50}
            onClick={() => {
              setIsCsv(true);
              printFilter();
            }}
          >
            <IconDownload color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
