import { Box, Col, Divider, Grid, LoadingOverlay, Modal, Table, Text } from "@mantine/core";
import PropTypes from "prop-types";
import { useState } from "react";
import { useQuery } from "react-query";

import { stock_apis } from "./stock-transfer.service";

function StockTransferDetailsModal({ opened, onClose, stockTransferId }) {
  const [details, setDetails] = useState({});

  const data = useQuery(
    ["order_data", stockTransferId],
    () => stock_apis.get_stock_transfer_details(stockTransferId),
    {
      enabled: stockTransferId !== null,
      onSuccess: data => {
        setDetails(data.data);
      },
    }
  );

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      zIndex={10}
      styles={{ header: { borderBottom: "1px solid #eee" } }}
      title={
        <Box px={10}>
          <Text fw={600}> Stock Transfer Details </Text>
        </Box>
      }
      size={"960px"}
      centered
    >
      <Box px={10} pos={"relative"}>
        <LoadingOverlay visible={data.isLoading} overlayBlur={2} />
        <Divider mb={20} opacity={0.4} />
        <Grid mb={20}>
          <Col span={12}>
            <Text size={"sm"} mb={15}>
              Products Details
            </Text>
            <Table withBorder withColumnBorders striped>
              <thead>
                <tr>
                  <th> No. </th>
                  <th> Product </th>
                  <th> Batch </th>
                  <th> MRP </th>
                  <th> Price </th>
                  <th> Qty. </th>
                  <th> Total Amount </th>
                </tr>
              </thead>
              <tbody>
                {details.stock_list?.map((e, index) => (
                  <tr>
                    <td width={50}> {index+1} </td>
                    <td>{e.product}</td>
                    <td>{e.batch_no}</td>
                    <td> ₹ {e.mrp} </td>
                    <td> ₹ {e.selling_price} </td>
                    <td> {e.stock} </td>
                    <td> ₹ {(e.stock * e.selling_price).toFixed(2)} </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col span={3}>
            <Text size={"sm"} mb={15}>
              Payment Details
            </Text>
            <Table withBorder>
              <tbody>
                <tr>
                  <td>
                    <Text opacity={0.5}> Total Amount </Text>
                  </td>
                  <td>
                    <Text fw={600}>₹ {details.amount}</Text>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Grid>
      </Box>
    </Modal>
  );
}

StockTransferDetailsModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stockTransferId: PropTypes.number,
};

export default StockTransferDetailsModal;
