function SalesOrderPrintComponent (data,items,moment,qty) {
    return `<!DOCTYPE html>
            <html lang="en">

            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Quzone</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css">
                <style>
                    @media print {
                        body {
                            width: 104mm !important;
                            height: 3276mm !important;
                            margin: 0.2mm 0.2mm 0.2mm 0.2mm !important;
                            /* change the margins as you want them to be. */
                        }
                    }
                    @page {
                    width: 104mm !important;
                    height: 3276mm !important;
                    margin: 0.2mm 0.2mm 0.2mm 0.2mm !important;
                    /* change the margins as you want them to be. */
                }
                p{
                    margin: 0px !important;
                }
                </style>
            </head>

            <body style="font-family:  Calibri, sans-serif;">
                <div style="text-align: center;">
                    <h1>QUZONE</h1>
                </div>
                <div style="font-size:20px;text-align:center;">
                    <p>Sale Order</p>
                </div>
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <div style="display: flex;justify-content: space-evenly;">
                    <div>
                        <p>Order ID: </b>${data.order_id}</p>
                    </div>
                    <div>
                        <p>Data & Time :${moment(data.timestamp).format("YYYY-MM-DD HH:MM")}</p>
                    </div>
                </div>
                <div style="font-size: 20px;">
                    <b>Name:${data?.user?.first_name}</b> <br>
                    <b>Phone No:${data?.user?.username}</b>
                </div>
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <div style="display: flex;justify-content: space-evenly;">
                    <b style="width:46%;">Item/<br/>Batch</b>
                    <b style="width:20%;">Mrp</b>
                    <b style="width:17%;">Qty</b>
                    <b style="width:17%;">Rack</b>
                </div>
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                ${items}
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <div style="display: flex;justify-content: space-evenly;">
                    <div>Total</div>
                    <div>${data.cart.cartitem.length} Items</div>
                    <div>${qty} qty</div>
                    <div>Net Amount</div>
                    <div>${data.total}</div>
                </div>
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <br/>
                <br/>
                <br/>


            </body>

            </html>`



};

export default SalesOrderPrintComponent;