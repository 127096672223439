import { hasPermission } from "pages/Auth/permissionGuard";
import Restricted from "pages/Errors/Restricted";
import AddStockTransfer from "pages/Inventory/stock-transfer/AddStockTransfer";
import OrderPrint from "pages/OrderManagement/OrderPrint";
import ViewOrder from "pages/OrderManagement/ViewOrder";
import DeliveryList from "pages/delivery/List";
import ViewDelivery from "pages/delivery/ViewDelivery";
import Address from "pages/master/address/Address";
import Banners from "pages/master/banners/Banners";
import CountersList from "pages/master/counter/CounterList";
import Customers from "pages/master/customers/Customers";
import AddUserForm from "pages/master/users/AddUserForm";
import InvoicePrint from "pages/transactions/invoice/InvoicePrint";
import ViewInvoice from "pages/transactions/invoice/ViewInvoice";
import PaymentReceipt from "pages/transactions/payment-receipt/PaymentReceipt";
import DeliveryReport from "pages/transactions/reports/DeliveryReport";
import SalesReport from "pages/transactions/reports/SalesReport";
import { Route, Routes } from "react-router";

import { ProtectedRoutes } from "./ProtectedRoute";
import App from "../App";
import { Login } from "../pages/Auth/Login";
import { NotFound } from "../pages/Errors/NotFound";
import InventoryList from "../pages/Inventory/StockList";
import StockList from "../pages/Inventory/StockList";
import StockTransfer from "../pages/Inventory/stock-transfer/StockTransfer";
import OrdersList from "../pages/OrderManagement/OrdersList";
import ManageAccount from "../pages/Settings/ManageAccount";
import CategoriesList from "../pages/master/categories/CategoriesList";
import SubCategoriesList from "../pages/master/categories/SubCategoriesList";
import Cities from "../pages/master/city/Cities";
import CitiesGroup from "../pages/master/city/CityGroups";
import Coupons from "../pages/master/coupons/Cuopons";
import AddProductForm from "../pages/master/products/AddProductForm";
import ProductsList from "../pages/master/products/ProductsList";
import ViewProduct from "../pages/master/products/ViewProduct";
import ReviewsList from "../pages/master/reviews/ReviewsList";
import Users from "../pages/master/users/Users";
import InvoiceList from "../pages/transactions/invoice/InvoiceList";
import Invoice from "../pages/transactions/invoice/InvoiceMaster";
import ReturnsMaster from "../pages/transactions/returns/ReturnMaster";
import StockTransferPrint from "../pages/Inventory/stock-transfer/StockTransferPrint"
import FeedbackList from "pages/master/feedback/FeedbackList";

export const routes = (
  <Routes>
    <Route element={<ProtectedRoutes />}>
      <Route path="/restricted" element={<Restricted />} />
      <Route path="/" element={<App />}>
        {/* Master routes */}
        {/* Products */}
        <Route path="products">
          <Route
            element={hasPermission("view_products") ? <ProductsList /> : <Restricted />}
            index
          />
          <Route
            path="add"
            element={hasPermission("add_products") ? <AddProductForm /> : <Restricted />}
          />
          <Route
            path="edit"
            element={hasPermission("change_products") ? <AddProductForm /> : <Restricted />}
          />
          <Route
            path="view"
            element={hasPermission("view_products") ? <ViewProduct /> : <Restricted />}
          />
          <Route
            path="reviews"
            element={hasPermission("view_review") ? <ReviewsList /> : <Restricted />}
          />
        </Route>
        {/* Catagories */}
        <Route path="categories">
          <Route
            element={hasPermission("view_category") ? <CategoriesList /> : <Restricted />}
            index
          />
          <Route
            path="sub-categories"
            element={hasPermission("view_subcategory") ? <SubCategoriesList /> : <Restricted />}
            index
          />{" "}
          {/* Master routes */}
        </Route>
        <Route path="users">
          <Route element={hasPermission("view_user") ? <Users /> : <Restricted />} index />
        </Route>
        <Route path="customers" element={<Customers />} />

        <Route
          path="address"
          element={hasPermission("view_address") ? <Address /> : <Restricted />}
        />
        <Route
          path="reviews"
          element={hasPermission("view_review") ? <ReviewsList /> : <Restricted />}
        />
        <Route
          path="feedback"
          element={hasPermission("view_feedback") ? <FeedbackList /> : <Restricted />}
        />
        {/* Inventory Routes */}
        <Route
          path="stock-list"
          element={hasPermission("view_counterstock") ? <StockList /> : <Restricted />}
        />
        <Route path="stock-transfer">
          <Route
            index
            element={hasPermission("view_counterstock") ? <StockTransfer /> : <Restricted />}
          ></Route>
          <Route
            path="add"
            element={hasPermission("add_counterstock") ? <AddStockTransfer /> : <Restricted />}
          ></Route>
          <Route
            path="edit"
            element={hasPermission("add_counterstock") ? <AddStockTransfer /> : <Restricted />}
          ></Route>
          <Route
          path="print"
          element={hasPermission("view_counterstock") ? <StockTransferPrint /> : <Restricted />}
          ></Route>
        </Route>
        {/* Transaction routes */}
        <Route path="invoices">
          <Route element={hasPermission("view_invoice") ? <InvoiceList /> : <Restricted />} index />
          <Route
            element={hasPermission("view_invoice") ? <ViewInvoice /> : <Restricted />}
            index
            path="view"
          />
          <Route
            element={hasPermission("add_invoice") ? <Invoice /> : <Restricted />}
            index
            path="add"
          />
          <Route
            element={hasPermission("change_invoice") ? <Invoice /> : <Restricted />}
            index
            path="edit"
          />
          <Route path="print" element={<InvoicePrint />} />
        </Route>

        <Route
          path="returns"
          element={hasPermission("view_returnstock") ? <ReturnsMaster /> : <Restricted />}
        />
        <Route
          path="sales-report"
          element={hasPermission("view_returnstock") ? <SalesReport /> : <Restricted />}
        />

        <Route
          path="delivery-report"
          element={hasPermission("view_returnstock") ? <DeliveryReport /> : <Restricted />}
        />

        <Route
          path="payment-receipt"
          element={hasPermission("view_paymentreceipt") ? <PaymentReceipt /> : <Restricted />}
        />

        {/* Cities */}
        <Route path="city-groups" element={<CitiesGroup />} />
        <Route path="cities" element={hasPermission("view_city") ? <Cities /> : <Restricted />} />
        {/* Coupons */}
        <Route
          path="coupons"
          element={hasPermission("view_coupon") ? <Coupons /> : <Restricted />}
        />
        {/* Orders */}
        <Route path="orders">
          <Route element={hasPermission("view_order") ? <OrdersList /> : <Restricted />} index />
          <Route path="view" element={<ViewOrder />} />
          <Route path="print" element={<OrderPrint />} />
        </Route>
        {/* Banners */}
        <Route path="banners" element={<Banners />} />
        {/* Counters */}
        <Route path="counters">
          <Route
            element={hasPermission("view_counter") ? <CountersList /> : <Restricted />}
            index
          />
        </Route>
        <Route path="deliveries">
          <Route element={<DeliveryList />} index />
          <Route path="view" element={<ViewDelivery />} />
        </Route>

        {/* Manage account */}
        <Route path="manage-account" element={<ManageAccount />} />
      </Route>
    </Route>
    <Route path="login" element={<Login />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
