import {
  Box,
  Button,
  Col,
  Divider,
  Flex,
  Grid,
  Group,
  List,
  Menu,
  Modal,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconTag } from "@tabler/icons";
import { coupons_api } from "pages/master/coupons/coupons.service";
import { api_addCustomer, api_getCustomers } from "pages/master/customers/customers.service";
import { getAlteredCustomers } from "pages/master/customers/helperFunctions";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

export default function CouponsModal(props) {
  const [coupons, setCoupons] = useState([]);

  const coupons_data = useQuery("coupons", coupons_api.get, {
    onSuccess: data => setCoupons(data.data),
  });

  const handleCouponClick = couponCode => {
    props.setCouponCode(couponCode);

    let coupon = coupons.find(value => value.id === couponCode.id);
    props.setCouponApplied(coupon);
    props.setModalOpened(false);
  };

  return (
    <>
      <Modal
        opened={props.modalOpened}
        onClose={() => {
          props.setModalOpened(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>Coupons</Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Box>
            {coupons.map((coupon, index) => {
              return (
                <Box key={index}>
                  <Box w={"100%"}>
                    <Flex
                      key={index}
                      mr={20}
                      mb={10}
                      direction="row"
                      justify="space-between"
                      width={"100%"}
                    >
                      <Box mr={20} mb={10}>
                        <Text style={{ cursor: "pointer" }} size="sm">
                          {coupon.coupon_code}
                        </Text>
                        <Text size="xs" color="dimmed">
                          {coupon.coupon_type} {coupon.value}
                          {coupon.coupon_type === "Flat" ? "%" : "Rs"}
                        </Text>
                      </Box>
                      <Button
                        variant="outline"
                        onClick={() => {
                          handleCouponClick(coupon);
                        }}
                      >
                        Apply
                      </Button>
                    </Flex>
                  </Box>
                  <Divider mb={20} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </>
  );
}
