import { httpClient } from "../../../lib/axios";

export const api_getAddress = async () => {
  return await httpClient
    .get("address/?admin=true&page_size=100&page_number=1")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_editAddress = async payload => {
  return await httpClient
    .put("address/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_filterAddress = async payload => {
  return await httpClient
    .post("address/?admin=true&page_size=100&page_number=1", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
