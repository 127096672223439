import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Checkbox,
  Col,
  Container,
  Divider,
  Flex,
  Grid,
  Modal,
  NumberInput,
  PasswordInput,
  Select,
  Switch,
  Text,
  TextInput,
  Textarea,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import {
  IconAlertCircle,
  IconEdit,
  IconPlus,
  IconShieldCheck,
  IconTrash,
  IconX,
} from "@tabler/icons";
import { IconShieldChevron } from "@tabler/icons-react";
import FloatingMenu from "components/FloatingMenu.js";
import { MantineReactTable } from "mantine-react-table";
import { hasPermission } from "pages/Auth/permissionGuard.js";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";

import PermissionGroupModal from "./PermissionGroupModal.js";
import PermissionsModal from "./PermissionsModal.js";
import {
  api_addUser,
  api_assignUserStatus,
  api_deleteUser,
  api_editUser,
  api_getRoles,
  api_getUserById,
  api_getUsers,
} from "./users.service.js";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";

export default function Users() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const theme = useMantineTheme();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // STARTS : States
  const [permissions, setPermissions] = useState([[], []]);
  const [permissionsGroups, setPermissionsGroups] = useState([[], []]);
  const [permissionGroupModalVisible, setPermissionGroupModalVisible] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isPermissions, setIsPermissions] = useState(false);
  const [permissionsData, setPermissionsData] = useState([[], []]);
  const [userPermissionsData, setUserPermissionsData] = useState([[], []]);
  const [userFormVisible, setUserFormVisible] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const [usersDataById, setUsersDataById] = useState({});
  const [assignPriceListModalVisible, setAssignPriceListModalVisible] = useState(false);

  const [rolesList, setRolesList] = useState(false);
  // ENDS : States

  const [permissionModalVisible, setPermissionModalVisible] = useState(false);

  useEffect(() => {
    users.refetch();
  }, [usersDataById]);

  const openDeleteConfirmation = id => {
    openConfirmModal({
      title: "Please confirm delete user",
      children: (
        <Text size="sm">
          Are you sure you want to delete this user ? Everything related to this user will be
          deleted.
        </Text>
      ),
      labels: { confirm: "Delete User", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteUser(id),
      confirmProps: { color: "red" },
    });
  };

  const deleteUser = async id => {
    await api_deleteUser(id)
      .then(res => {
        if (res.success) {
          showSuccessToast({ title: "Deleted User", message: res.message });
          users.refetch();
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  const users_data = useQuery("users", api_getUsers, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      //console.log(data.data);
      setUsersData(data.data);
    },
  });

  const user_data_by_id = useMutation({
    mutationFn: data => api_getUserById(data),
    onSuccess: data => {
      console.log(data.data);
      setUsersDataById(data.data);
    },
  });

  const roles = useQuery("user_roles", api_getRoles, {
    onSuccess: data => {
      console.log("roles", data);
      setRolesList(
        data.map(e => {
          return {
            label: e.value,
            value: e.label,
          };
        })
      );
    },
  });
  //const routes = useQuery("user_routes", api_getRoute);

  const assignUserStatus = async id => {
    let payload = { id: id };
    await api_assignUserStatus(payload).then(res => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.messge });
        setLoginError(false);
      } else {
        setLoginErrorMessage(res.message);
        setLoginError(true);
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Username",
        accessorKey: "username", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "First Name",
        accessorKey: "first_name", //simple accessorKey pointing to flat data
        size: "auto",
      },
      // {
      //   header: "Last Name",
      //   accessorKey: "last_name", //simple accessorKey pointing to flat data
      // },
      {
        header: "Role",
        accessorKey: "role", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Active",
        accessorKey: "is_active", //simple accessorKey pointing to flat data
        size: "auto",
        Cell: ({ cell, row }) => {
          return (
            <Switch
              size="xs"
              onChange={event => assignUserStatus(row.original.id)}
              defaultChecked={cell.getValue()}
            />
          );
        },
      },
    ],
    []
  );

  const addUserForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      is_superuser: false,
      is_staff: false,
      is_active: false,
      username: "",
      password: "",
      first_name: "",
      last_name: "",
      email: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      gstin_no: "",
      role: "",
      customer_id: "",
      device_token: "",
      verified: "",
      tally_master_id: "",
      tally_status: false,
    },
    validate: {
      username: value => (value ? null : "Username is required"),

      password: value => {
        if (!isEditing) {
          if (!value) {
            return "Password is required";
          } else {
            return null;
          }
        }
      },
      // first_name: value => (isPermissions || value ? null : "Firstname is required"),
      // last_name: value => (isPermissions || value ? null : "Lastname is required"),
      // email: value => {
      //   if (!isPermissions) {
      //     let valid_email = /^\S+@\S+$/.test(value);
      //     if (!value) {
      //       return "Email address is required";
      //     }
      //     if (!valid_email) {
      //       return "Email address is not valid.";
      //     }
      //   }
      // },
      // address: value => (isPermissions || value ? null : "Address is required"),
      // city: value => (isPermissions || value ? null : "City is required"),
      // state: value => (isPermissions || value ? null : "State is required"),
      // pincode: value => {
      //   if (!isPermissions) {
      //     if (value.toString().length === 0) {
      //       return "Pincode is required";
      //     }
      //     if (value.toString().length !== 6) {
      //       return "Pincode is not valid.";
      //     }
      //   }
      // },
      // gstin_no: value => {
      //   if (!isPermissions) {
      //     let valid_gstin =
      //       /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/.test(
      //         value
      //       );
      //     if (value === undefined || value === "") {
      //       return null;
      //     }
      //     if (!valid_gstin || value.length !== 15) {
      //       return "GSTIN number is not valid.";
      //     }
      //   }
      // },
      role: value => (isPermissions || value ? null : "Role is required"),
    },
  });

  // Fetching users
  const users = useQuery("users", api_getUsers, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      let editingUserData = {};
      if (isEditing || isPermissions) {
        editingUserData = data.data.find(field => field.id === editingUserId);
      }

      if (editingUserData && editingUserData.user_permissions !== undefined) {
        const permissionIds = editingUserData.user_permissions.map(group => group.id);

        let userPermissions = [[], []];
        permissionsData[0].map(group =>
          permissionIds.includes(group.id)
            ? userPermissions[1].push(group)
            : userPermissions[0].push(group)
        );
        setUserPermissionsData(userPermissions);
      }

      console.log(editingUserData);

      console.log(usersDataById);

      addUserForm.setValues({
        is_superuser: usersDataById ? usersDataById.is_superuser : false,
        is_staff: usersDataById ? usersDataById.is_staff : false,
        is_active: usersDataById ? usersDataById.is_active : false,
        username: usersDataById ? usersDataById.username : "",
        first_name: usersDataById ? usersDataById.first_name : "",
        last_name: usersDataById ? usersDataById.last_name : "",
        email: usersDataById ? usersDataById.email : "",
        address: usersDataById ? usersDataById.address : "",
        city: usersDataById ? usersDataById.city : "",
        state: usersDataById ? usersDataById.state : "",
        pincode: usersDataById ? Number(usersDataById.pincode) : "",
        gstin_no: usersDataById ? usersDataById.gstin_no : "",
        role: usersDataById ? usersDataById.role : "",
        customer_id: usersDataById ? Number(usersDataById.customer_id) : "",
        device_token: usersDataById ? usersDataById.device_token : "",
        verified: usersDataById ? usersDataById.verified : "",
        tally_master_id: usersDataById ? usersDataById.tally_master_id : "",
        tally_status: usersDataById ? usersDataById.tally_status : false,
      });
    },
  });

  const addUser = async values => {
    setLoading(true);
    console.log(values);

    let payload = {};

    if (isEditing) {
      payload = {
        id: editingUserId,
        is_superuser: values.is_superuser,
        is_staff: values.is_staff,
        is_active: values.is_active,
        username: values.username,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        address: values.address,
        city: values.city,
        state: values.state,
        pincode: values.pincode,
        gstin_no: values.gstin_no,
        role: values.role,
        customer_id: values.customer_id,
        device_token: "",
        verified: true,
        tally_master_id: values.tally_master_id,
        tally_status: values.tally_status,
      };

      // edit user api call
      api_editUser(payload).then(res => {
        setLoading(false);
        console.log(res);
        if (res.success) {
          showSuccessToast({ title: "Success", message: res.messge });
          setLoginError(false);
          //navigate("/users");
          setUserFormVisible(false);
        } else {
          setLoginErrorMessage(res.message);
          setLoginError(true);
        }
      });
    } else {
      payload = {
        is_superuser: values.is_superuser,
        is_staff: values.is_staff,
        is_active: values.is_active,
        username: values.username,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        address: values.address,
        city: values.city,
        state: values.state,
        pincode: values.pincode,
        gstin_no: values.gstin_no,
        role: values.role,
        customer_id: values.customer_id,
        device_token: "",
        verified: true,
        tally_master_id: values.tally_master_id,
        tally_status: values.tally_status,
      };

      // add user api call
      await api_addUser(payload).then(res => {
        setLoading(false);
        console.log(res);
        if (res.success) {
          showSuccessToast({ title: "Success", message: res.messge });
          setLoginError(false);
          //navigate("/users");
          setUserFormVisible(false);
        } else {
          setLoginErrorMessage(res.message);
          setLoginError(true);
        }
      });
    }
    users.refetch();
  };

  return (
    <>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={isMobileScreen ? 5 : 25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Users Master
                </Text>
                <AppBreadcrumb />
              </Box>
              <Box>
                {hasPermission("add_user") ? (
                  <Button
                    onClick={() => {
                      //navigate("add");
                      addUserForm.reset();
                      setUserFormVisible(true);
                    }}
                  >
                    <IconPlus style={{ width: "20px" }} />
                    <Text ml={10}> Add User </Text>
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box>
            <MantineReactTable
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Users List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  {hasPermission("change_user") ? (
                    <>
                      <Tooltip label="Edit Permission Group">
                        <ActionIcon
                          mr={10}
                          sx={theme => ({ color: theme.colors.brand[7] })}
                          onClick={() => {
                            setEditingUserId(row.original.id);
                            setPermissionGroupModalVisible(true);
                          }}
                        >
                          <IconShieldChevron style={{ width: 20 }} />
                        </ActionIcon>
                      </Tooltip>
                      <Tooltip label="Edit Permissions">
                        <ActionIcon
                          sx={theme => ({ color: theme.colors.brand[7] })}
                          onClick={() => {
                            setEditingUserId(row.original.id);
                            setPermissionModalVisible(true);
                          }}
                        >
                          <IconShieldCheck style={{ width: 20 }} />
                        </ActionIcon>
                      </Tooltip>
                      <Tooltip label="Edit User">
                        <ActionIcon
                          ml={10}
                          sx={theme => ({ color: theme.colors.brand[7] })}
                          onClick={() => {
                            setEditingUserId(row.original.id);
                            user_data_by_id.mutate(row.original.id);
                            setIsEditing(true);
                            setUserFormVisible(true);
                          }}
                        >
                          <IconEdit style={{ width: 20 }} />
                        </ActionIcon>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                  {hasPermission("delete_user") && (
                    <Tooltip label="Delete User">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.red[6] })}
                        ml={10}
                        onClick={() => {
                          openDeleteConfirmation(row.original.id);
                        }}
                      >
                        <IconTrash style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </Flex>
              )}
              initialState={{ density: "xs" }}
              state={{ isLoading: users_data.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={usersData || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}

      {/* ENDS :: Add User Modal */}
      <Modal
        opened={userFormVisible}
        onClose={() => {
          setIsEditing(false);
          setIsPermissions(false);
          setUserFormVisible(false);
          addUserForm.reset();
          //navigate("/users");
        }}
        overlayProps={{
          color: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        styles={{
          content: {
            border: "1px solid #ccc",
          },
        }}
        title={
          <Box px={10}>
            <Text fw={700}>
              {isEditing ? (isPermissions ? "Edit Permissions" : "Edit User") : "Add User"}
            </Text>
          </Box>
        }
        size={"900px"}
        centered
      >
        <Box p={10} pt={0}>
          <Divider mb={20} />

          {loginError ? (
            <Alert my={20} icon={<IconAlertCircle size={16} />} title="Error!" color="red">
              {loginErrorMessage}
            </Alert>
          ) : (
            ""
          )}

          <form
            onSubmit={addUserForm.onSubmit(values => {
              addUser(values);
            })}
          >
            <Grid>
              <Col sm={isMobileScreen ? 12 : 3}>
                <Checkbox
                  label="Super User"
                  {...addUserForm.getInputProps("is_superuser", { type: "checkbox" })}
                />
              </Col>
              <Col sm={isMobileScreen ? 12 : 3}>
                <Checkbox
                  label="Staff"
                  {...addUserForm.getInputProps("is_staff", { type: "checkbox" })}
                />
              </Col>
              <Col sm={isMobileScreen ? 12 : 3}>
                <Checkbox
                  label="Tally Status"
                  {...addUserForm.getInputProps("tally_status", { type: "checkbox" })}
                />
              </Col>
              <Col sm={isMobileScreen ? 12 : 3}>
                <Checkbox
                  checked={true}
                  label="Active"
                  {...addUserForm.getInputProps("is_active", { type: "checkbox" })}
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="Username ( Mobile No. )"
                  placeholder="Enter Username ( Mobile No. )"
                  {...addUserForm.getInputProps("username")}
                  hideControls
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <PasswordInput
                  label="Password"
                  placeholder="Enter Pasword"
                  {...addUserForm.getInputProps("password")}
                  hideControls
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="First Name"
                  placeholder="Enter First Name"
                  {...addUserForm.getInputProps("first_name")}
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="Last Name"
                  placeholder="Enter Last Name"
                  {...addUserForm.getInputProps("last_name")}
                />
              </Col>
              <Col span={12}>
                <TextInput
                  label="Email Address"
                  type="email"
                  placeholder="Enter Email address"
                  {...addUserForm.getInputProps("email")}
                />
              </Col>
              <Col span={12}>
                <Textarea
                  label="Address"
                  placeholder="Enter Address"
                  {...addUserForm.getInputProps("address")}
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="City"
                  placeholder="Enter city"
                  {...addUserForm.getInputProps("city")}
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="State"
                  placeholder="Enter state"
                  {...addUserForm.getInputProps("state")}
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <NumberInput
                  label="Pincode"
                  placeholder="Enter pincode"
                  {...addUserForm.getInputProps("pincode")}
                  hideControls
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="GSTIN Number"
                  placeholder="Enter GSTIN Number"
                  {...addUserForm.getInputProps("gstin_no")}
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <Select
                  label="Select Role"
                  placeholder="Select User Role"
                  value={"id"}
                  data={rolesList || []}
                  {...addUserForm.getInputProps("role")}
                ></Select>
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <Select
                  label="Select Tally Master"
                  placeholder="Select  Tally Master"
                  data={[]}
                  {...addUserForm.getInputProps("tally_master_id")}
                ></Select>
              </Col>
              <Col span={12}>
                <Button mt={10} w={"100%"} type="submit" loading={loading}>
                  {isEditing ? "Edit User" : "Add User"}
                </Button>
              </Col>
            </Grid>
          </form>
        </Box>
      </Modal>

      <PermissionsModal
        opened={permissionModalVisible}
        userId={editingUserId}
        onClose={() => {
          setPermissionModalVisible(false);
          users_data.refetch();
        }}
      />

      <PermissionGroupModal
        opened={permissionGroupModalVisible}
        userId={editingUserId}
        onClose={() => {
          setPermissionGroupModalVisible(false);
          users_data.refetch();
        }}
      />

      {hasPermission("add_user") && isMobileScreen ? (
        <FloatingMenu
          m={5}
          right
          size={50}
          onClick={() => {
            addUserForm.reset();
            setUserFormVisible(true);
          }}
        >
          <IconPlus color="white" />
        </FloatingMenu>
      ) : (
        <></>
      )}
    </>
  );
}
