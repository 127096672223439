import { httpClient } from "../../../lib/axios";

export const api_getPaymentReceipt = async (counter, invoice) => {
  let url = `payment_receipt/?page_size=100&page_number=1`;
  if (counter) {
    url = `payment_receipt/?page_size=100&page_number=1&counter_id=${counter}`;
  }
  if (invoice) {
    url = `payment_receipt/?page_size=100&page_number=1&counter_id=${counter}&invoice_id=${invoice}`;
  }

  return await httpClient
    .get(url)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_addPaymentReceipt = async payload => {
  return await httpClient
    .post("payment_receipt/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_editPaymentReceipt = async payload => {
  return await httpClient
    .put("payment_receipt/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
