import { httpClient } from "lib/axios";

export const counters_api = {
  get: async () => {
    return await httpClient
      .get(`counter/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  changeStatus: async payload => {
    return await httpClient.put("counter/", payload);
  },
  get_counter_users: async payload => {
    return await httpClient.get("counter/?get_counter_user=true/", payload);
  },
  assign_users: async payload => {
    return await httpClient.post("counter/?assign_user=true", payload);
  },
  unassign_users: async payload => {
    return await httpClient.post("counter/?remove_user=true", payload);
  },
  add: async payload => {
    return await httpClient.post(`counter/`, payload);
  },
  edit: async payload => {
    return await httpClient.put(`counter/`, payload);
  },
  get_counter_stocks: async () => {
    return await httpClient
      .get("counter_stock/?counter_id=3")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_delivery_partner: async () => {
    return await httpClient
      .get("get_delivery_partner/")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  assign_delivery_partner: async payload => {
    return await httpClient.put("order/admin/?assign_delivery=true/", payload);
  },

  cancleOrder: async payload => {
    return await httpClient.put("order/", payload);
  },
  delete: async review_id => {
    return await httpClient
      .delete(`review/?review_id=${review_id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  bulkDelete: async payload => {
    return await httpClient
      .delete(`review/?delete=true`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
};
