import { ActionIcon, Alert, Box, Flex, Text } from "@mantine/core";
import { IconUserMinus } from "@tabler/icons";
import produce from "immer";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";
import { openDeleteConfirmation } from "utilities/utility";

import { counters_api } from "./counter.service";

function CounterUsersList({ data }) {
  const queryClient = useQueryClient();

  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleRemoveUser = id => {
    setSelectedUserId(id);
    openDeleteConfirmation("Counter User", () => {
      unAssignUser.mutate({
        users: [id],
      });
    });
  };

  const removeUserFromList = () => {
    setUsers(
      produce(draft => {
        const userIndex = draft.findIndex(user => user.id === selectedUserId);
        if (userIndex !== -1) draft.splice(userIndex, 1);
      })
    );
  };

  const unAssignUser = useMutation({
    mutationFn: data => counters_api.unassign_users(data),
    onSuccess: data => {
      console.log(data);
      showSuccessToast({
        title: "Success",
        message: "Removed user from counter.",
      });
      removeUserFromList();
      queryClient.invalidateQueries(["counters-data"], ["counters-users"]);
    },
    onError: () => {
      showErrorToast({
        title: "Error",
        message: "Error unassigning user.",
      });
    },
  });

  useEffect(() => {
    setUsers(data);
  }, [data]);

  return (
    <Box my={30}>
      <Text fw={600} mb={10}>
        Conuter Users List
      </Text>
      {!users.length && <Alert color="yellow">No users assigned.</Alert>}
      {users?.map(user => (
        <Box mb={10} key={user.id}>
          <Flex
            justify={"space-between"}
            align={"center"}
            py={8}
            px={16}
            sx={theme => ({
              border: `1px solid ${theme.colors.brand[1]}`,
              borderRadius: "5px",
            })}
          >
            <Text size={"14px"}>
              {user.first_name} - {user.username}
            </Text>
            <Flex>
              <ActionIcon
                onClick={() => {
                  handleRemoveUser(user.id);
                }}
              >
                <IconUserMinus size={"20"} />
              </ActionIcon>
            </Flex>
          </Flex>
        </Box>
      ))}
    </Box>
  );
}

CounterUsersList.propTypes = {
  data: PropTypes.array,
  onUnassignUser: PropTypes.func.isRequired,
};

export default CounterUsersList;
