import { Box, Button, Col, Divider, Grid, Modal, NumberInput, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { order_api } from "pages/OrderManagement/order.service";
import React, { useEffect, useRef, useState } from "react";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

const PackingSlip = props => {
  const [isEnable, setIsEnable] = useState(false);
  const inputRef = useRef([]);

  const addForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      box: "",
      bag: "",
      rice_bag: "",
    },
  });

  const raiseInvoice = async (id, box, bag, rice_bag) => {
    await order_api.raiseInvoice(id, box, bag, rice_bag).then(res => {
      console.log(res);
      if (res.success) {
        setIsEnable(false);
        showSuccessToast({
          title: "Success",
          message: res.message,
        });
        props.orders_data.refetch();
        props.setModalOpened(false);
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  return (
    <Modal
      opened={props.modalOpened}
      onClose={() => {
        addForm.reset();
        props.setModalOpened(false);
      }}
      title={
        <Box px={10}>
          <Text fw={600}>Packing Slip</Text>
        </Box>
      }
      size={"600px"}
      centered
    >
      <Box px={10}>
        <Divider mb={20} />
        <Box>
          <form
            onSubmit={addForm.onSubmit(values => {
              console.log(values);

              if (isEnable) {
                if (values.box === "") {
                  values.box = 0;
                }
                if (values.bag === "") {
                  values.bag = 0;
                }
                if (values.rice_bag === "") {
                  values.rice_bag = 0;
                }
                raiseInvoice(
                  props.id,
                  addForm.values.box,
                  addForm.values.bag,
                  addForm.values.rice_bag
                );
              }
            })}
          >
            <Grid>
              <Col span={12}>
                <NumberInput
                  data-autofocus
                  ref={el => (inputRef.current[0] = el)}
                  label="No.of Box"
                  placeholder="Enter No.of Box"
                  {...addForm.getInputProps("box")}
                  hideControls
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      setIsEnable(false);
                      inputRef.current[1].focus();
                    }
                  }}
                />
              </Col>
              <Col span={12}>
                <NumberInput
                  ref={el => (inputRef.current[1] = el)}
                  label="No.of Bag"
                  placeholder="Enter No.of Bag"
                  {...addForm.getInputProps("bag")}
                  hideControls
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      setIsEnable(false);
                      inputRef.current[2].focus();
                    }
                  }}
                />
              </Col>
              <Col span={12}>
                <NumberInput
                  ref={el => (inputRef.current[2] = el)}
                  label="No.of Rice Bag"
                  placeholder="Enter No.of Rice Bag"
                  {...addForm.getInputProps("rice_bag")}
                  hideControls
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      setIsEnable(false);
                      inputRef.current[3].focus();
                    }
                  }}
                />
              </Col>
              <Col span={12}>
                <Button
                  ref={el => (inputRef.current[3] = el)}
                  onFocus={() => {
                    setIsEnable(e => (e = true));
                  }}
                  mt={10}
                  w={"100%"}
                  type="submit"
                >
                  Raise Invoice
                </Button>
              </Col>
            </Grid>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default PackingSlip;
