import {
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  Flex,
  Grid,
  Image,
  Loader,
  Paper,
  SimpleGrid,
  Table,
  Tabs,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconEdit,
  IconLineHeight,
  IconMessageCircle,
  IconPhoto,
  IconPlus,
  IconRuler2,
  IconRulerMeasure,
  IconSettings,
  IconStars,
  IconWeight,
} from "@tabler/icons";
import { IconStarsFilled } from "@tabler/icons-react";
import FloatingMenu from "components/FloatingMenu";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { products_api } from "./products.service";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { image_url } from "../../../config";
import ReviewsList from "../reviews/ReviewsList";

function ViewProduct() {
  useEffect(() => {
    console.log("dadadadadada");
  }, []);

  const navigate = useNavigate();
  const theme = useMantineTheme();

  const isMobileScreen = useMediaQuery("(max-width: 768px)");

  const [existingProductImages, setExistingProductImages] = useState([]);

  // const [isEditing, setIsEditing] = useState(false);
  const [specifications, setSpecifications] = useState([]);
  const [searchParams] = useSearchParams();

  const product = useQuery(
    ["product_product.data.data?", searchParams.get("id")],
    () => products_api.get_by_id(searchParams.get("id")),
    {
      onSuccess: data => {
        console.log(data);
        data.data.product_image && setExistingProductImages(data.data.product_image);
        data.data.specification && setSpecifications(JSON.parse(data.data.specification));
      },
    }
  );

  return (
    <div>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box px={isMobileScreen ? 0 : 25} py={isMobileScreen ? 15 : 25}>
            <Flex justify={"space-between"} align="end" wrap={"wrap"}>
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  {product.data?.data?.title}
                </Text>
                <AppBreadcrumb
                  crumbs={[
                    {
                      text: "Master",
                    },
                    {
                      text: "Products",
                      link: "/master/products",
                    },
                    {
                      text: "View Product",
                    },
                  ]}
                />
              </Box>
              <Box mt={isMobileScreen ? 10 : 0}>
                <Button
                  onClick={() => {
                    navigate(`/products/edit?id=${product.data.data.id}`);
                  }}
                >
                  <IconEdit style={{ width: "20px" }} />
                  <Text ml={10}> Edit Product </Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Tabs
            mb={20}
            styles={theme => ({
              tab: {
                ...theme.fn.focusStyles(),

                backgroundColor: theme.white,
                color: theme.colors.brand[8],
                border: `${1} solid ${theme.colors.brand[6]}`,
                padding: `${theme.spacing.xs} ${theme.spacing.md}`,
                cursor: "pointer",
                fontSize: theme.fontSizes.sm,
                display: "flex",
                alignItems: "center",
                width: "50%",

                "&:disabled": {
                  opacity: 0.5,
                  cursor: "not-allowed",
                },

                borderRadius: 0,

                "&[data-active]": {
                  backgroundColor: theme.colors.brand[8],
                  borderColor: theme.colors.brand[8],
                  color: theme.white,
                },
              },

              tabIcon: {
                marginRight: theme.spacing.xs,
                display: "flex",
                alignItems: "center",
              },

              tabsList: {
                display: "flex",
                border: `1px solid ${theme.colors.brand[7]}`,
                overflow: "hidden",
              },
            })}
            defaultValue="details"
          >
            <Tabs.List w={"100%"}>
              <Tabs.Tab value="details" icon={<IconPhoto size="1rem" />}>
                Product Details
              </Tabs.Tab>
              <Tabs.Tab value="reviews" icon={<IconStars size="1rem" />}>
                Product Reviews
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="details" pt="xs" mt={5}>
              {product.isLoading ? (
                <Loader />
              ) : (
                <Box>
                  {/* Starts :: Form */}
                  <Paper
                    mb={20}
                    sx={theme => ({
                      border: "1px solid #eee",
                      borderRadius: 2,
                    })}
                  >
                    <Box
                      sx={theme => ({
                        borderBottom: `1px solid ${theme.colors.brand[0]}`,
                        borderRadius: 2,
                      })}
                    >
                      <Flex px={28} py={14} align={"center"}>
                        <Text opacity={0.5} tt={"uppercase"} fw={600} size={"12px"}>
                          Product Details
                        </Text>
                      </Flex>
                    </Box>
                    <Box p={isMobileScreen ? 10 : 20} pt={10}>
                      <Grid m={0} p={0}>
                        <Col sm={2} px={isMobileScreen ? 0 : 10} mb={isMobileScreen ? 15 : 0}>
                          <Box
                            w={isMobileScreen ? "100px" : "190px"}
                            sx={{
                              border: "2px solid #eee",
                              overflow: "hidden",
                              borderRadius: 2,
                            }}
                          >
                            <Image
                              radius={2}
                              width={"100%"}
                              height={"100%"}
                              fit={"contain"}
                              src={
                                product.data.data.thumbnail
                                  ? image_url + product.data.data.thumbnail
                                  : "https://universalele.websites.co.in/obaju-turquoise/img/product-placeholder.png"
                              }
                            ></Image>
                          </Box>
                        </Col>
                        <Col sm={10} px={isMobileScreen ? 0 : 30}>
                          <Grid m={0} p={0}>
                            <Col sm={12}>
                              <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                                Product Name
                              </Text>
                              <Text size={"14px"} fw={500} mb={5}>
                                {product.data.data.title}
                              </Text>
                            </Col>
                            <Col span={isMobileScreen ? 12 : 4}>
                              <Box
                                sx={{
                                  border: "1px solid #eee",
                                }}
                              >
                                <Text px={12} py={10} fw={"600"} size={"sm"}>
                                  General Details
                                </Text>
                                <Divider opacity={0.5} />
                                <Box p={5}>
                                  <Table fontSize={13}>
                                    <tbody>
                                      <tr>
                                        <td> HSN Code</td>
                                        <td> {product.data.data.hsn_code} </td>
                                      </tr>
                                      <tr>
                                        <td> Product Code</td>
                                        <td> {product.data.data.product_code} </td>
                                      </tr>
                                      <tr>
                                        <td> Category </td>
                                        <td> {product.data.data.category?.title} </td>
                                      </tr>
                                      <tr>
                                        <td>Sub Category </td>
                                        <td> {product.data.data.sub_category?.title} </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Grid
                                    sx={{
                                      borderBottom: "42x",
                                    }}
                                  >
                                    <Grid.Col sm={6}>
                                      <Text size={"13px"} fw={500}></Text>
                                    </Grid.Col>
                                    <Grid.Col sm={6}> </Grid.Col>
                                  </Grid>
                                </Box>
                              </Box>
                            </Col>
                            <Col span={isMobileScreen ? 12 : 4}>
                              <Box
                                sx={{
                                  border: "1px solid #eee",
                                }}
                              >
                                <Text px={12} py={10} fw={"600"} size={"sm"}>
                                  Pricing Details
                                </Text>
                                <Divider opacity={0.5} />
                                <Box p={5}>
                                  <Table fontSize={13}>
                                    <tbody>
                                      <tr>
                                        <td> MRP </td>
                                        <td> ₹ {product.data.data.mrp} </td>
                                      </tr>
                                      <tr>
                                        <td> Selling Price </td>
                                        <td> ₹ {product.data.data.selling_price} </td>
                                      </tr>
                                      <tr>
                                        <td> Tax </td>
                                        <td> {product.data.data.tax} % </td>
                                      </tr>
                                      <tr>
                                        <td>Delivery Charge </td>
                                        <td>
                                          {product.data.data.delivery_charge
                                            ? product.data.data.delivery_charge
                                            : "Free"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Grid
                                    sx={{
                                      borderBottom: "42x",
                                    }}
                                  >
                                    <Grid.Col sm={6}>
                                      <Text size={"13px"} fw={500}></Text>
                                    </Grid.Col>
                                    <Grid.Col sm={6}> </Grid.Col>
                                  </Grid>
                                </Box>
                              </Box>
                            </Col>
                            <Col span={isMobileScreen ? 12 : 4}>
                              <Box
                                sx={{
                                  border: "1px solid #eee",
                                }}
                              >
                                <Text px={12} py={10} fw={"600"} size={"sm"}>
                                  Tally Details
                                </Text>
                                <Divider opacity={0.5} />
                                <Box p={5}>
                                  <Table fontSize={13}>
                                    <tbody>
                                      <tr>
                                        <td> Status </td>
                                        <td> {product.data.data.tally_status} </td>
                                      </tr>
                                      <tr>
                                        <td> Master ID </td>
                                        <td> {product.data.data.tally_master_id} </td>
                                      </tr>
                                      <tr>
                                        <td> Item Group </td>
                                        <td> {product.data.data.item_group} </td>
                                      </tr>
                                      <tr>
                                        <td> Category</td>
                                        <td> {product.data.data.tally_category} </td>
                                      </tr>
                                      <tr>
                                        <td> Sub Category</td>
                                        <td> {product.data.data.tally_sub_category} </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Grid
                                    sx={{
                                      borderBottom: "42x",
                                    }}
                                  >
                                    <Grid.Col sm={6}>
                                      <Text size={"13px"} fw={500}></Text>
                                    </Grid.Col>
                                    <Grid.Col sm={6}> </Grid.Col>
                                  </Grid>
                                </Box>
                              </Box>
                            </Col>
                          </Grid>
                        </Col>

                        <Col sm={12} px={0}>
                          <hr color="#eee" />
                        </Col>

                        <Col sm={12} p={18}>
                          <Grid>
                            <Col sm={3}>
                              <Flex>
                                <Box mr={10}>
                                  <IconRuler2 size={40} stroke={1.2} color={theme.colors.gray[5]} />
                                </Box>

                                <Box>
                                  <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                                    Product Length
                                  </Text>
                                  <Text size={"14px"} fw={500} mb={5}>
                                    {product.data.data.length} cm
                                  </Text>
                                </Box>
                              </Flex>
                            </Col>
                            <Col sm={3}>
                              <Flex>
                                <Box mr={10}>
                                  <IconRulerMeasure
                                    size={40}
                                    stroke={1.2}
                                    color={theme.colors.gray[5]}
                                  />
                                </Box>

                                <Box>
                                  <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                                    Product Breadth
                                  </Text>
                                  <Text size={"14px"} fw={500} mb={5}>
                                    {product.data.data.breadth} cm
                                  </Text>
                                </Box>
                              </Flex>
                            </Col>
                            <Col sm={3}>
                              <Flex>
                                <Box mr={10}>
                                  <IconLineHeight
                                    size={40}
                                    stroke={1.2}
                                    color={theme.colors.gray[5]}
                                  />
                                </Box>
                                <Box>
                                  <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                                    Product Height
                                  </Text>
                                  <Text size={"14px"} fw={500} mb={5}>
                                    {product.data.data.height} cm
                                  </Text>
                                </Box>
                              </Flex>
                            </Col>
                            <Col sm={3}>
                              <Flex>
                                <Box mr={10}>
                                  <IconWeight size={40} stroke={1.2} color={theme.colors.gray[5]} />
                                </Box>

                                <Box>
                                  <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                                    Product Weight
                                  </Text>
                                  <Text size={"14px"} fw={500} mb={5}>
                                    {product.data.data.weight} kg
                                  </Text>
                                </Box>
                              </Flex>
                            </Col>
                          </Grid>
                        </Col>

                        <Col sm={12} p={18}>
                          <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                            Product Images
                          </Text>

                          <SimpleGrid mt={10} cols={9} breakpoints={[{ maxWidth: "sm", cols: 2 }]}>
                            {existingProductImages.length &&
                              existingProductImages.map((image, index) => {
                                return (
                                  <Box
                                    height={120}
                                    width={120}
                                    sx={{
                                      border: "2px solid #eee",
                                      overflow: "hidden",
                                      borderRadius: 2,
                                    }}
                                    pos={"relative"}
                                    bg={"transparent"}
                                    key={index}
                                  >
                                    <Image
                                      key={index}
                                      h={100}
                                      width={"100%"}
                                      fit="contain"
                                      src={image_url + image.image}
                                      imageProps={{
                                        onLoad: () => URL.revokeObjectURL(image.image),
                                      }}
                                    />
                                  </Box>
                                );
                              })}
                          </SimpleGrid>
                        </Col>

                        <Col sm={12} p={18}>
                          <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                            Product Short Description
                          </Text>
                          <Text size={"14px"}> {product.data.data.short_description} </Text>
                        </Col>

                        <Col sm={12} p={18}>
                          <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                            Product Long Description
                          </Text>
                          <Text size={"14px"}> {product.data.data.long_description} </Text>
                        </Col>

                        <Col sm={12} p={18}>
                          <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                            Product Specifications
                          </Text>
                          <Table withBorder withColumnBorders>
                            <tbody>
                              {specifications.map(e => (
                                <tr>
                                  <td
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "14px",
                                    }}
                                    width={300}
                                  >
                                    {e.title}
                                  </td>
                                  <td style={{ fontSize: "14px" }}> {e.description} </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>

                        <Col sm={12} p={18}>
                          <Text size={"12px"} color="dimmed" fw={500} mb={5}>
                            Product Labels
                          </Text>
                          <Flex mt={10}>
                            {product.data.data.verified && (
                              <Badge variant={"light"} mr={10}>
                                Verified
                              </Badge>
                            )}
                            {product.data.data.assured && (
                              <Badge variant={"light"} mr={10}>
                                Assured
                              </Badge>
                            )}
                            {product.data.data.assured && (
                              <Badge variant={"light"} mr={10}>
                                Deals
                              </Badge>
                            )}
                            {product.data.data.assured && (
                              <Badge variant={"light"} mr={10}>
                                Featured
                              </Badge>
                            )}
                          </Flex>
                        </Col>
                      </Grid>
                    </Box>
                  </Paper>

                  {/* Ends :: Form */}
                </Box>
              )}
            </Tabs.Panel>

            <Tabs.Panel value="reviews" pt="xs">
              <ReviewsList onlyTable product={{}} />
            </Tabs.Panel>
          </Tabs>
        </Box>
      </Container>

      {isMobileScreen ? (
        <FloatingMenu
          m={5}
          right
          size={50}
          onClick={() => {
            navigate(`/products/edit?id=${product.data.data.id}`);
          }}
        >
          <IconEdit color="white" />
        </FloatingMenu>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ViewProduct;
