import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  FileInput,
  Flex,
  Grid,
  Modal,
  NumberInput,
  Select,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconEdit, IconEye, IconPlus, IconTrash } from "@tabler/icons";
import FloatingMenu from "components/FloatingMenu";
import { image_url } from "config";
import { MantineReactTable } from "mantine-react-table";
import React, { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";

import { api_addCitiesGroup, api_editCitiesGroup, api_getCities } from "./cities.service";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { api_addUser } from "../../../services/master.service";
import { showSuccessToast } from "../../../utilities/Toast";

export default function CitiesGroup() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [isEditing, setIsEditing] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [viewReturnModalOpened, setViewReturnModalOpened] = useState(false);
  const [returnModalDetails, setReturnModalDetails] = useState({});
  const [citiesData, setCitiesData] = useState([]);
  const [editingCitiesGroupId, setEditingCitiesGroupId] = useState("");
  // END : States

  // STARTS : States
  const [userFormVisible, setUserFormVisible] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  // ENDS : States

  // Fetching cities
  const cities_group = useQuery("cities", api_getCities, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setCitiesData(data.data);
    },
  });

  const handleEdit = values => {
    setIsEditing(true);
    console.log(values);
    returnForm.setValues({
      name: values.name,
    });
    setEditingCitiesGroupId(values.id);
    setModalOpened(true);
  };

  const returnForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      name: "",
      image: "",
    },
    validate: {
      name: value => (value ? null : "Name is required"),
    },
  });

  const openDeleteConfirmation = id => {
    openConfirmModal({
      name: "Please confirm delete return",
      children: (
        <Text size="sm">
          Are you sure you want to delete this return data ? This action can't be undone.
        </Text>
      ),
      labels: { confirm: "Delete City Group", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { color: "red" },
    });
  };

  // const deleteCityGroup = async id => {
  //   await invoice_api.delete_invoice(id).then(res => {
  //     console.log(res.data);
  //   });
  //   invoices.refetch();
  // };

  const columns = useMemo(
    () => [
      {
        header: "City Group",
        accessorKey: "name",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => (
          <Flex align={"center"}>
            {console.log(row.original.image)}
            <img
              src={image_url + row.original.image}
              // src={image_url + row.original.thumbnail}
              width={30}
              height={30}
              style={{
                borderRadius: 100,
                border: "2px solid #eee",
                objectFit: "cover",
              }}
              alt=""
            />
            <Text ml={10}> {renderedCellValue} </Text>
          </Flex>
        ),
      },
    ],
    []
  );

  const addCitiesGroup = async values => {
    setLoading(true);
    console.log(values);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("image", values.image);

    await api_addCitiesGroup(formData).then(res => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.messge });
        setUserFormVisible(false);
        setLoginError(false);
        setModalOpened(false);
        cities_group.refetch();
      } else {
        setLoginErrorMessage(res.message);
        setLoginError(true);
      }
    });
  };

  const editCitiesGroup = async values => {
    setLoading(true);
    console.log(values);

    const formData = new FormData();
    formData.append("id", editingCitiesGroupId);
    formData.append("name", values.name);
    if (values.image) {
      formData.append("image", values.image);
    }

    await api_editCitiesGroup(formData).then(res => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.messge });
        setUserFormVisible(false);
        setLoginError(false);
        setModalOpened(false);
        cities_group.refetch();
      } else {
        setLoginErrorMessage(res.message);
        setLoginError(true);
      }
    });
  };

  return (
    <>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Cities Group
                </Text>
                <AppBreadcrumb
                  crumbs={[
                    {
                      text: "Transactions",
                    },
                    {
                      text: "Returns",
                    },
                  ]}
                />
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    setModalOpened(true);
                  }}
                >
                  <IconPlus style={{ width: "20px" }} />
                  <Text ml={10}> Add City Group </Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        {/* Add Product Form */}

        {/* Add Product Form */}

        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Cities Group List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => {
                return (
                  <Flex>
                    <Tooltip label="Edit City Group">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        ml={10}
                        onClick={() => {
                          handleEdit(row.original);
                        }}
                      >
                        <IconEdit style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>

                    <Tooltip label="Delete City Group">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.red[6] })}
                        ml={10}
                        onClick={() => {
                          openDeleteConfirmation(row.id);
                        }}
                      >
                        <IconTrash style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                );
              }}
              initialState={{ density: "xs" }}
              // state={{ isLoading: users_data.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={citiesData || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}

      <Modal
        opened={modalOpened}
        title="City Group"
        onClose={() => {
          setModalOpened(false);
          setIsEditing(false);
        }}
        name={
          <Box px={10}>
            <Text fw={600}>{isEditing ? "Edit city group" : "Add city group"}</Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Box>
            <form
              onSubmit={returnForm.onSubmit(values => {
                console.log(values);
                isEditing ? editCitiesGroup(values) : addCitiesGroup(values);
              })}
            >
              <Grid>
                <Col sm={6}>
                  <TextInput
                    label="City Group Name"
                    placeholder="Enter City Group Name"
                    {...returnForm.getInputProps("name")}
                  />
                </Col>
                <Col sm={6}>
                  <FileInput
                    label="City Group Image"
                    placeholder="Select Image"
                    {...returnForm.getInputProps("image")}
                  />
                </Col>
                <Col span={12} my={10}>
                  <Flex h={"100%"} direction={"column"} justify="end" align={"center"}>
                    <Button w={"100%"} type="submit">
                      {isEditing ? "Edit City Group" : "Add City Group"}
                    </Button>
                  </Flex>
                </Col>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>

      {/* View Returns Modal */}

      <Modal
        opened={viewReturnModalOpened}
        onClose={() => {
          setViewReturnModalOpened(false);
        }}
        name={
          <Box px={10}>
            <Text fw={600}>Return Details</Text>
          </Box>
        }
        size={"500px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Grid>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Product
              </Text>
              <Text> {returnModalDetails.product_name} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Batch Code
              </Text>
              <Text> {returnModalDetails.batch_code} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Qty
              </Text>
              <Text> {returnModalDetails.qty} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Amount
              </Text>
              <Text> ₹ {returnModalDetails.amount} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Reason
              </Text>
              <Text> {returnModalDetails.reason} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Status
              </Text>
              <Text> {returnModalDetails.status} </Text>
            </Col>
            <Col span={12}>
              <Text size={"xs"} opacity={0.4}>
                Description
              </Text>
              <Text> {returnModalDetails.description} </Text>
            </Col>
          </Grid>
        </Box>
      </Modal>

      {isMobileScreen ? (
        <FloatingMenu
          m={5}
          right
          size={50}
          onClick={() => {
            setModalOpened(true);
          }}
        >
          <IconPlus color="white" />
        </FloatingMenu>
      ) : (
        <></>
      )}
    </>
  );
}
