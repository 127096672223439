import { httpClient } from "../../../lib/axios";

export const api_getCities = async () => {
  return await httpClient
    .get("city/")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_addCitiesGroup = async payload => {
  return await httpClient
    .post("city/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_editCitiesGroup = async payload => {
  return await httpClient
    .put("city/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_deleteCityGroup = async id => {
  return await httpClient
    .delete("city/?")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_addCities = async payload => {
  return await httpClient
    .post("city/?city=true", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_editCities = async payload => {
  return await httpClient
    .put("city/?city=true", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
