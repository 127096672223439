/**
 * Modifying categories array as the need of select
 * @param initial_categories_array It takes categories array retrived from the api
 */
export const getAlteredCategories = (array) => {
  let modifiedList = array.map((e) => {
    return {
      label: e.title,
      value: e.id,
      id: e.id,
      sub_categories: e.sub_category.map((e) => {
        return {
          label: e.title,
          value: e.id,
          id: e.id,
        };
      }),
    };
  });
  return modifiedList;
};

/**
 * Modifying cities array as the need of select
 * @param initial_cities_array It takes categories array retrived from the api
 */
export const getAlteredCities = (array) => {
  let modifiedList = array.map((e) => {
    return {
      label: e.name,
      value: e.id,
      id: e.id,
      cities: e.city.map((e) => {
        return {
          label: e.city,
          value: e.id,
          id: e.id,
        };
      }),
    };
  });
  return modifiedList;
};
