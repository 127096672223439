import {
  ActionIcon,
  Box,
  Button,
  Container,
  Flex,
  Modal,
  Rating,
  Switch,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconCheck, IconEye, IconTrash, IconX } from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import produce from "immer";
import _ from "lodash";
import { MantineReactTable } from "mantine-react-table";
import React, { useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { showErrorToast } from "utilities/Toast";

import { review_api } from "./reviews.service";

/**
 *
 * @param {{ review : Object }} props
 */

const delete_confirm_props = (deleteFunction, id) => {
  return {
    title: "Please confirm delete review",
    children: <Text size="sm">Are you sure you want to delete selected review ?</Text>,
    labels: { confirm: "Delete Review", cancel: "Cancel" },
    onCancel: () => console.log("Cancel"),
    onConfirm: async () => await deleteFunction(id),
    confirmProps: { color: "red" },
  };
};

export default function ReviewsList(props) {
  const pageTitle = "Reviews List";
  const tableInstanceRef = useRef(null);
  const theme = useMantineTheme();

  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  const [reviews, setReviews] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [modalReview, setModalReview] = useState({});
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const reviews_data = useQuery("all_reviews", review_api.get, {
    onSuccess: data => setReviews(data.data),
  });

  const changeReviewStatusLocally = (status, ids_array) => {
    console.log("loggin locally", ids_array, status);
    switch (status) {
      case "approve":
        console.log("apprive bole chhe");
        for (const id of ids_array) {
          setReviews(
            produce(draft => {
              let el = draft.find(e => e.id === id);
              el.verified = true;
            })
          );
        }
        break;
      case "reject":
        for (const id of ids_array) {
          setReviews(
            produce(draft => {
              let el = draft.find(e => e.id === id);
              el.verified = false;
            })
          );
        }
        break;
      case "delete":
        for (const id of ids_array) {
          setReviews(
            produce(draft => {
              let el = draft.find(e => e.id === id);
              let index = draft.indexOf(el);
              draft.splice(index, 1);
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const changeReviewStatusServer = async (status, ids_array) => {
    console.log("loggin server", ids_array, status);
    await review_api
      .changeStatus({
        review_id: ids_array,
        status: status === "approve" ? "True" : "False",
      })
      .then(res => {
        console.log("Bulk status change response", res);
        if (res.success) {
          changeReviewStatusLocally(status, ids_array);
        } else {
          showErrorToast({
            title: "Error",
            message: "Error changing review status.",
          });
        }
      });
  };

  const changeReviewStatus = async (status, id) => {
    try {
      return await review_api
        .changeStatus({
          review_id: id,
          status: status === "approve" ? "True" : "False",
        })
        .then(res => {
          if (res.success) {
            return true;
          } else {
            return false;
          }
        });
    } catch (err) {
      return err;
    }
  };

  const handleBulkReviews = {
    get_ids_array: () => {
      let id_array = [];
      for (const key in rowSelection) {
        id_array.push(+key);
      }
      return id_array;
    },
    approve: async () => {
      setApproveLoading(true);
      await review_api
        .changeStatus({
          review_id: handleBulkReviews.get_ids_array(),
          status: "True",
        })
        .then(res => {
          setApproveLoading(false);
          console.log("Bulk status change response", res);
          if (res.success) {
            changeReviewStatusLocally("approve", handleBulkReviews.get_ids_array());
          } else {
            showErrorToast({
              title: "Error",
              message: "Error changing review status.",
            });
          }
        });
    },
    reject: async () => {
      setRejectLoading(true);
      await review_api
        .changeStatus({
          review_id: handleBulkReviews.get_ids_array(),
          status: "False",
        })
        .then(res => {
          setRejectLoading(false);
          console.log("Bulk status change response", res);
          if (res.success) {
            changeReviewStatusLocally("reject", handleBulkReviews.get_ids_array());
          } else {
            showErrorToast({
              title: "Error",
              message: "Error changing review status.",
            });
          }
        });
    },
    delete: async () => {
      setDeleteLoading(true);
      await review_api
        .bulkDelete({
          review_id: handleBulkReviews.get_ids_array(),
        })
        .then(res => {
          setDeleteLoading(false);
          console.log("Bulk delete response", res);
          if (res.success) {
            changeReviewStatusLocally("delete", handleBulkReviews.get_ids_array());
          } else {
            showErrorToast({
              title: "Error",
              message: "Error changing review status.",
            });
          }
        });
    },
  };

  const deleteReview = async id => {
    await review_api.delete(id).then(res => {
      if (res.success) {
        setReviews(
          produce(draft => {
            let review = draft.find(e => e.id === id);
            const index = draft.indexOf(review);
            draft.splice(index, 1);
          })
        );
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "User",
        accessorKey: "user",
        size: "auto",
        Cell: ({ cell }) => <Text tt={"capitalize"}>{cell.getValue()}</Text>,
      },
      {
        header: "Product",
        accessorKey: "product",
        size: "auto",
      },
      {
        header: "Review Stars",
        accessorKey: "star",
        size: "auto",
        Cell: ({ renderedCellValue }) => <Rating defaultValue={renderedCellValue} />,
      },
      {
        header: "Review",
        accessorKey: "title",
        size: "auto",
        Cell: ({ renderedCellValue, cell, row }) => (
          <Box>
            <Text> {renderedCellValue} </Text>
            <Text
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              maw={400}
              color="dimmed"
            >
              {row.original.description}
            </Text>
          </Box>
        ),
      },
      {
        header: "Approved",
        accessorKey: "verified",
        size: "auto",
        Cell: ({ cell, row }) => (
          <Switch
            onChange={e => {
              handleStatusChange(e.target.checked === true ? "approve" : "reject", row.original.id);
            }}
            checked={cell.getValue()}
            thumbIcon={
              cell.getValue() ? (
                <IconCheck
                  size="0.8rem"
                  color={theme.colors.teal[theme.fn.primaryShade()]}
                  stroke={3}
                />
              ) : (
                <IconX size="0.8rem" color={theme.colors.red[theme.fn.primaryShade()]} stroke={3} />
              )
            }
          />
        ),
      },
    ],
    []
  );

  const handleStatusChange = async (status, id) => {
    const isDataUpdated = await changeReviewStatus(status, [id]);

    if (isDataUpdated) {
      changeReviewStatusLocally(status, [id]);
    } else {
      showErrorToast({
        title: "Error",
        message: "Error changing review status.",
      });
    }
  };

  const productGivenColumns = useMemo(
    () => [
      {
        header: "User",
        accessorKey: "user_name",
        size: 50,
      },
      {
        header: "Review Stars",
        accessorKey: "review_stars",
        size: 50,
        Cell: ({ renderedCellValue }) => <Rating defaultValue={renderedCellValue} />,
      },
      {
        header: "Review",
        accessorKey: "review_title",
        size: 50,
        Cell: ({ renderedCellValue, cell, row }) => (
          <Box>
            <Text> {renderedCellValue} </Text>
            <Text
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              maw={400}
              color="dimmed"
            >
              {row.original.review_description}
            </Text>
          </Box>
        ),
      },
      {
        header: "Approved",
        accessorKey: "approved",
        size: 50,
        Cell: ({ cell, row }) => (
          <Switch
            onChange={e =>
              changeReviewStatusServer(e.target.checked ? "approve" : "reject", [row.original.id])
            }
            checked={cell.getValue()}
            thumbIcon={
              cell.getValue() ? (
                <IconCheck
                  size="0.8rem"
                  color={theme.colors.teal[theme.fn.primaryShade()]}
                  stroke={3}
                />
              ) : (
                <IconX size="0.8rem" color={theme.colors.red[theme.fn.primaryShade()]} stroke={3} />
              )
            }
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      {!props.onlyTable && !isMobileScreen && (
        <Container fluid>
          <Box px={isMobileScreen ? 0 : 25} py={isMobileScreen ? 15 : 25}>
            <Flex justify={"space-between"} align="end" wrap={"wrap"}>
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  {pageTitle}
                </Text>
                <AppBreadcrumb />
              </Box>
            </Flex>
          </Box>
        </Container>
      )}

      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : props.onlyTable ? 0 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              tableInstanceRef={tableInstanceRef}
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    {pageTitle}
                  </Text>
                  {!_.isEmpty(rowSelection) && (
                    <Flex>
                      <Button
                        mt={5}
                        color="green"
                        variant={"outline"}
                        onClick={() => {
                          handleBulkReviews.approve();
                        }}
                        loading={approveLoading}
                        leftIcon={<IconCheck />}
                      >
                        Approve Selected
                      </Button>
                      <Button
                        ml={10}
                        mt={5}
                        variant={"outline"}
                        color={"red"}
                        leftIcon={<IconX />}
                        loading={rejectLoading}
                        onClick={() => {
                          handleBulkReviews.reject();
                        }}
                      >
                        Reject Selected
                      </Button>
                      <Button
                        ml={10}
                        mt={5}
                        color={"red"}
                        loading={deleteLoading}
                        onClick={() => {
                          openConfirmModal(delete_confirm_props(handleBulkReviews.delete));
                        }}
                        leftIcon={<IconTrash />}
                      >
                        Delete Selected
                      </Button>
                    </Flex>
                  )}
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  <Tooltip label="View Review">
                    <ActionIcon
                      sx={theme => ({
                        color: theme.colors.brand[7],
                      })}
                      onClick={() => {
                        setReviewModalVisible(true);
                        setModalReview(row.original);
                      }}
                    >
                      <IconEye style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>

                  <Tooltip label="Delete Review">
                    <ActionIcon
                      sx={theme => ({
                        color: theme.colors.red[6],
                      })}
                      ml={10}
                      onClick={() => {
                        openConfirmModal(delete_confirm_props(deleteReview, row.original.id));
                      }}
                    >
                      <IconTrash style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              )}
              initialState={{ density: "xs" }}
              state={{
                isLoading: reviews_data.isLoading || reviews_data.isRefetching,
                rowSelection: rowSelection,
              }}
              onRowSelectionChange={setRowSelection}
              columns={props.product ? productGivenColumns : columns}
              enableRowSelection={true}
              enableSelectAll={true}
              getRowId={originalRow => originalRow.id}
              enableDensityToggle={false}
              enableFullScreenToggle={isMobileScreen}
              enableColumnActions={false}
              data={reviews || []}
              enableStickyHeader
              enableGlobalFilter={true} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      <Modal
        opened={reviewModalVisible}
        onClose={() => {
          setReviewModalVisible(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}> See Review </Text>
          </Box>
        }
        size={"960px"}
        centered
      >
        <Box p={10}>
          <Flex mb={15}>
            <Box>
              <Text> {modalReview.user} </Text>
              <Rating defaultValue={modalReview.star} mb={10} />
            </Box>
          </Flex>
          <Text size={"14px"} fw={500} mb={5}>
            {modalReview.title}
          </Text>
          <Text size={"14px"}>{modalReview.description}</Text>
        </Box>
      </Modal>
    </>
  );
}
