import { httpClient } from "lib/axios";

export const report_api = {
  get_cutoff: async date => {
    return await httpClient
      .get(`/cut_off/?date=${date}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  acknowledge_cutoff: async payload => {
    return await httpClient
      .post(`/cut_off/`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_delivery_report: async date => {
    return await httpClient
      .get(`/delivery_report/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
};
