/**
 * Modifying product array as the need of select
 * @param initial_product_array It takes product array retrived from the api
 */
export const getAlteredProduct = array => {
  let modifiedList = array.map(e => {
    return {
      label: e.title === "" ? " " : e.title,
      value: e.id,
      id: e.id,
    };
  });
  return modifiedList;
};
