import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  LoadingOverlay,
  Table,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconBox, IconPlus, IconPrinter, IconTruckDelivery, IconUser } from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import FloatingMenu from "components/FloatingMenu";
import SalesOrderPrintComponent from "components/SalesOrderPrintComponent";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import AssignDeliveryModal from "./AssignDeliveryModal";
import StatusChangeModal from "./StatusChangeModal";
import { order_api } from "./order.service";
import { image_url } from "../../config/index";

export default function ViewOrder() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState({});
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  const [deliveryAssignModalVisible, setDeliveryAssignModalVisible] = useState(false);
  const [statusChangeModalVisible, setStatusChangeModalVisible] = useState(false);

  const order_data = useQuery(
    ["order_data", searchParams.get("id")],
    () => order_api.get_order(searchParams.get("id")),
    {
      onSuccess: data => {
        setOrder(data.data);
        console.log(order);
      },
    }
  );

  const printSalesOrder = async id => {
    await order_api.get_order(id).then(res => {
      console.log(res.data);
      const data = res.data;
      const cartitem = data.cart.cartitem;
      const rackOrderItem = [...cartitem].sort((a, b) =>
        a.tally_category > b.tally_category ? 1 : -1
      );
      console.log(rackOrderItem);
      const item = rackOrderItem.map(e => {
        return `<div style="display: flex;padding-top:2px;">
            <p  style="width:41%;padding:0px;">${e.product}</p> 
            <p  style="width:20%;padding:0px;">${e.batch_no}</p> 
            <p  style="width:15%;text-align:right;padding:0px;">${e.mrp.toFixed(2)} &emsp; </p> 
            <p  style="width:12%;text-align:center;padding:0px;">${e.quantity}</p> 
            <p  style="width:12%;text-align:right;padding:0px;">${e.tally_category}</p> 
        </div>`;
      });
      const qty = cartitem.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.quantity;
      }, 0);
      const items = item.join("");
      const printWindow = window.open("", "", "width=600,height=600");
      printWindow.document.open();
      printWindow.document.write(SalesOrderPrintComponent(data, items, moment, qty));
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    });
  };

  return (
    <div>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Order #5
                </Text>
                <AppBreadcrumb />
              </Box>
              <Box>
                <Button
                  variant="outline"
                  onClick={() => {
                    printSalesOrder(searchParams.get("id"));
                  }}
                >
                  <Text ml={10}> Print Order Details </Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}

      <Container fluid pos={"relative"} mt={10} mb={30}>
        <LoadingOverlay visible={order_data.isLoading || order_data.isRefetching} overlayBlur={5} />
        <Box px={25}>
          <Box bg={"white"} p={30} mb={20}>
            <Grid gutter={30}>
              <Grid.Col sm={4}>
                <Flex align={"center"}>
                  <IconBox size={20} />
                  <Text fw={"bold"} ml={8}>
                    Order Details
                  </Text>
                </Flex>

                <Table withBorder striped mt={20}>
                  <tbody>
                    <tr>
                      <td width={160}>
                        <Text opacity={0.5}>ID</Text>
                      </td>
                      <td> {order?.order_id} </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Date </Text>
                      </td>
                      <td>{order && moment(order?.timestamp).format("DD-MM-YYYY -- hh:mm A")} </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Status </Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}>{order?.status}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Payment Type </Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}>{order?.order_type}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Payment ID </Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}> - </Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Order Total </Text>
                      </td>

                      <td> ₹ {order?.cart?.total.toLocaleString("en-IN")}</td>
                    </tr>
                  </tbody>
                </Table>
                <Button
                  variant="outline"
                  mt={20}
                  w={`100%`}
                  onClick={() => {
                    setStatusChangeModalVisible(true);
                  }}
                >
                  Change Order Status
                </Button>
              </Grid.Col>
              <Grid.Col sm={4}>
                <Flex align={"center"}>
                  <IconUser size={20} />
                  <Text fw={"bold"} ml={8}>
                    Customer Details
                  </Text>
                </Flex>

                <Table withBorder striped mt={20}>
                  <tbody>
                    <tr>
                      <td width={130}>
                        <Text opacity={0.5}>Name</Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}>{order?.user?.first_name}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> Phone No. </Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}>{order?.user?.username}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> Email address </Text>
                      </td>
                      <td>
                        <Text>{order?.user?.email}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Text size={"xs"} opacity={0.5}>
                          Address
                        </Text>
                        <Text>{order?.address?.address_line_1}</Text>
                        <Text>{order?.address?.address_line_2}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> City </Text>
                      </td>
                      <td>
                        <Text>{order?.address?.city}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> State </Text>
                      </td>
                      <td>
                        <Text>{order?.address?.state}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> Pincode </Text>
                      </td>
                      <td>
                        <Text>{order?.address?.pincode}</Text>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Grid.Col>
              <Grid.Col sm={4}>
                <Flex>
                  <Flex align={"center"}>
                    <IconTruckDelivery size={20} />
                    <Text fw={"bold"} ml={8}>
                      Delivery Details
                    </Text>
                  </Flex>
                </Flex>

                <Box mt={15}>
                  <Table withBorder striped mt={20}>
                    <tbody>
                      <tr>
                        <td width={130}>
                          <Text opacity={0.5}>Name</Text>
                        </td>
                        <td>
                          <Text>{order?.delivery?.first_name}</Text>
                        </td>
                      </tr>
                      <tr>
                        <td width={100}>
                          <Text opacity={0.5}> Phone No. </Text>
                        </td>
                        <td>
                          <Text>{order?.delivery?.username}</Text>
                        </td>
                      </tr>
                      <tr>
                        <td width={100}>
                          <Text opacity={0.5}> Pickup OTP </Text>
                        </td>
                        <td>
                          <Text>{order?.delivery?.vendor_otp}</Text>
                        </td>
                      </tr>
                      <tr>
                        <td width={100}>
                          <Text opacity={0.5}> Csutomer OTP </Text>
                        </td>
                        <td>
                          <Text>{order?.delivery?.user_otp}</Text>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Button
                    variant="outline"
                    mt={20}
                    w={`100%`}
                    onClick={() => {
                      setDeliveryAssignModalVisible(true);
                    }}
                  >
                    Change Delivery Partner
                  </Button>
                </Box>
              </Grid.Col>
            </Grid>
          </Box>

          <Grid>
            <Grid.Col span={12}>
              <Box bg={"white"} p={30}>
                <Flex align={"center"}>
                  <IconBox size={20} />
                  <Text fw={"bold"} ml={8}>
                    Order Items
                  </Text>
                </Flex>

                <Box style={{ overflow: "auto" }}>
                  <Table withBorder striped mt={20}>
                    <thead>
                      <tr>
                        <th> No. </th>
                        <th> Product </th>
                        <th> Batch </th>
                        <th> Price </th>
                        <th> Qty. </th>
                        <th> Rack </th>
                        {/* <th> Tax amount </th>
                      <th> Delivery charge </th> */}
                        <th> Total Amount </th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.cart?.cartitem?.map((e, index) => (
                        <tr>
                          <td width={50}> {index + 1} </td>
                          <td width={"300px"}>
                            <Flex align={"center"}>
                              <Image
                                p={4}
                                mr={20}
                                radius={"100px"}
                                width={30}
                                height={30}
                                src={image_url + e.image.image}
                                alt=""
                              />
                              {e.product}
                            </Flex>
                          </td>
                          <td>{e.batch_no} </td>
                          <td> ₹ {e.mrp} </td>
                          <td> {e.quantity} </td>
                          <td>
                            {" "}
                            {e.tally_category !== "\u0004 Not Applicable"
                              ? e.tally_category
                              : ""}{" "}
                          </td>
                          {/* <td>₹ {e.tax_total.toLocaleString("en-IN", {})}</td>
                        <td>₹ {e.delivery_total.toLocaleString("en-IN", {})}</td> */}
                          <td> ₹ {e.total.toLocaleString("en-IN", {})} </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th> </th>
                        <th> Total </th>
                        <th> </th>
                        <th>
                          {order?.cart?.cartitem.reduce((total, item) => item.quantity + total, 0)}
                        </th>
                        <th></th>
                        <th>
                          ₹ {order?.cart?.cartitem.reduce((total, item) => item.total + total, 0)}
                        </th>
                      </tr>
                    </tfoot>
                  </Table>
                </Box>
              </Box>
            </Grid.Col>
          </Grid>
        </Box>
      </Container>

      <AssignDeliveryModal
        orderId={searchParams.get("id")}
        opened={deliveryAssignModalVisible}
        onClose={() => {
          setDeliveryAssignModalVisible(false);
        }}
      />

      <StatusChangeModal
        orderId={searchParams.get("id")}
        currentStatus={order?.status}
        opened={statusChangeModalVisible}
        onClose={() => {
          setStatusChangeModalVisible(false);
        }}
      />

      {isMobileScreen ? (
        <FloatingMenu
          m={5}
          right
          size={50}
          onClick={() => {
            printSalesOrder(searchParams.get("id"));
          }}
        >
          <IconPrinter color="white" />
        </FloatingMenu>
      ) : (
        <></>
      )}
    </div>
  );
}
