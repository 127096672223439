import {
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  Flex,
  Grid,
  Modal,
  NumberInput,
  Select,
  Switch,
  Text,
  TextInput,
  Textarea,
  Tooltip,
  TransferList,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import {
  IconAlertCircle,
  IconCircleCheck,
  IconCurrencyRupee,
  IconEdit,
  IconEye,
  IconPlus,
  IconTrash,
} from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import FloatingMenu from "components/FloatingMenu";
import { MantineReactTable } from "mantine-react-table";
import React, { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import {
  api_addCustomer,
  api_deleteCustomer,
  api_editCustomer,
  api_getCustomers,
} from "./customers.service";

const confirm_delete_props = {
  title: "Please confirm delete customer",
  children: (
    <Text size="sm">
      Are you sure you want to delete this customer ? Everything related to this customer will be
      deleted.
    </Text>
  ),
  labels: { confirm: "Delete Customer", cancel: "Cancel" },
  onCancel: () => console.log("Cancel"),
  confirmProps: { color: "red" },
};

export default function Customers() {
  const queryClient = useQueryClient();
  const theme = useMantineTheme();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [customersData, setCustomersData] = useState([]);
  const [customerFormVisible, setCustomerFormVisible] = useState(false);
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [editingCustomerId, setEditingCustomerId] = useState(null);
  const [editData, setEditData] = useState(false);
  // END : States

  const openDeleteConfirmation = id => {
    openConfirmModal({
      ...confirm_delete_props,
      onConfirm: async () => await deleteCustomer(id),
    });
  };

  const deleteCustomer = async id => {
    await api_deleteCustomer(id)
      .then(res => {
        if (res.success) {
          showSuccessToast({ title: "Deleted Customer", message: res.message });
          customers.refetch();
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  // Fetching customers
  const customers = useQuery("customers", api_getCustomers, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setCustomersData(data.data);
    },
  });

  const addCustomerForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      username: "",
      first_name: "",
      email: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      gstin_no: "",
    },
    validate: {
      username: value => {
        if (value.toString().length === 0) {
          return "Username is required";
        }
        if (value.toString().length !== 10) {
          return "Username is not valid.";
        }
      },
      email: value => {
        let valid_email = /^\S+@\S+$/.test(value);
        if (value !== undefined && value !== "" && !valid_email) {
          return "Email address is not valid.";
        }
      },
      pincode: value => {
        if (value !== undefined && value !== "" && value.toString().length !== 6) {
          return "Pincode is not valid.";
        }
      },
      gstin_no: value => {
        let valid_gstin =
          /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/.test(
            value
          );
        if (value !== undefined && value !== "" && (!valid_gstin || value.length !== 15)) {
          return "GSTIN number is not valid.";
        }
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        header: "Username",
        accessorKey: "username", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Firstname",
        accessorKey: "first_name", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Email",
        accessorKey: "email", //simple accessorKey pointing to flat data
        size: "auto",
      },
      // {
      //   header: "Counter Name",
      //   accessorKey: "counter", //simple accessorKey pointing to flat data
      //   size: "auto",
      // },
    ],
    []
  );

  const addCustomer = async values => {
    setLoading(true);
    console.log(values);

    let payload = {
      username: values.username,
      first_name: values.first_name,
      email: values.email,
      address: values.address,
      city: values.city,
      state: values.state,
      pincode: values.pincode,
      gstin_no: values.gstin_no,
      counter: null,
    };

    await api_addCustomer(payload).then(res => {
      setLoading(false);
      console.log("Response", res);

      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "New customer succesfully added.",
        });
        setCustomerFormVisible(false);
        customers.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  const initEditingCustomer = row => {
    setIsEditing(true);
    setEditData(row.original);
    setEditingCustomerId(row.original.id);

    let values = row.original;
    console.log(values);

    addCustomerForm.setValues({
      username: values.username ? parseInt(values.username) : "",
      first_name: values.first_name ? values.first_name : "",
      email: values.email ? values.email : "",
      address: values.address ? values.address : "",
      city: values.city ? values.city : "",
      state: values.state ? values.state : "",
      pincode: values.pincode ? parseInt(values.pincode) : "",
      gstin_no: values.gstin_no ? values.gstin_no : "",
    });

    setCustomerFormVisible(true);
  };

  const editCustomer = async values => {
    setLoading(true);
    console.log(values);

    let payload = {
      id: editingCustomerId,
      username: values.username,
      first_name: values.first_name,
      email: values.email,
      address: values.address,
      city: values.city,
      state: values.state,
      pincode: values.pincode,
      gstin_no: values.gstin_no,
      counter: null,
    };

    await api_editCustomer(payload).then(res => {
      setLoading(false);
      console.log("Response", res);

      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Customer succesfully edited.",
        });
        setCustomerFormVisible(false);
        customers.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  return (
    <>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Customers Master
                </Text>
                <AppBreadcrumb />
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    setCustomerFormVisible(true);
                    addCustomerForm.reset();
                  }}
                >
                  <IconPlus style={{ width: "20px" }} />
                  <Text ml={10}> Add Customer </Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box>
            <MantineReactTable
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Customers List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  <Tooltip label="View Customer">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      onClick={() => {
                        setEditData(row.original);
                        setCustomerModalVisible(true);
                      }}
                    >
                      <IconEye style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Edit Customer">
                    <ActionIcon
                      ml={10}
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      onClick={() => {
                        initEditingCustomer(row);
                      }}
                    >
                      <IconEdit style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Delete Customer">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.red[6] })}
                      ml={10}
                      onClick={() => {
                        openDeleteConfirmation(row.original.id);
                      }}
                    >
                      <IconTrash style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              )}
              initialState={{ density: "xs" }}
              state={{ isLoading: customers.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={customersData || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      {/* STARTS :: Add Customer Modal */}
      <Modal
        opened={customerFormVisible}
        onClose={() => {
          setIsEditing(false);
          setCustomerFormVisible(false);
        }}
        overlayProps={{
          color: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        styles={{
          content: {
            border: "1px solid #ccc",
          },
        }}
        title={
          <Box px={10}>
            <Text fw={700}> {isEditing ? "Edit Customer" : "Add New Customer"} </Text>
          </Box>
        }
        size={"900px"}
        centered
      >
        <Box p={10} pt={0}>
          <Divider mb={20} />

          {loginError ? (
            <Alert my={20} icon={<IconAlertCircle size={16} />} title="Error!" color="red">
              {loginErrorMessage}
            </Alert>
          ) : (
            ""
          )}

          <form
            onSubmit={addCustomerForm.onSubmit(values => {
              isEditing ? editCustomer(values) : addCustomer(values);
            })}
          >
            <Grid>
              <Col span={isMobileScreen ? 12 : 6}>
                <NumberInput
                  label="Username ( Mobile No. )"
                  placeholder="Enter Username ( Mobile No. )"
                  {...addCustomerForm.getInputProps("username")}
                  hideControls
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="First Name"
                  placeholder="Enter First Name"
                  {...addCustomerForm.getInputProps("first_name")}
                />
              </Col>
              <Col span={12}>
                <TextInput
                  label="Email Address"
                  type="email"
                  placeholder="Enter Email address"
                  {...addCustomerForm.getInputProps("email")}
                />
              </Col>
              <Col span={12}>
                <Textarea
                  label="Address"
                  placeholder="Enter Address"
                  {...addCustomerForm.getInputProps("address")}
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="City"
                  placeholder="Enter city"
                  {...addCustomerForm.getInputProps("city")}
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="State"
                  placeholder="Enter state"
                  {...addCustomerForm.getInputProps("state")}
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <NumberInput
                  label="Pincode"
                  placeholder="Enter pincode"
                  {...addCustomerForm.getInputProps("pincode")}
                  hideControls
                />
              </Col>
              <Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  label="GSTIN Number"
                  placeholder="Enter GSTIN Number"
                  {...addCustomerForm.getInputProps("gstin_no")}
                />
              </Col>
              <Col span={12}>
                <Button mt={10} w={"100%"} type="submit" loading={loading}>
                  {isEditing ? "Edit Customer" : "Add New Customer"}
                </Button>
              </Col>
            </Grid>
          </form>
        </Box>
      </Modal>

      <Modal
        opened={customerModalVisible}
        onClose={() => {
          setEditData({});
          setCustomerModalVisible(false);
        }}
        overlayProps={{
          color: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        styles={{
          content: {
            border: "1px solid #ccc",
          },
        }}
        title={
          <Box px={10}>
            <Text fw={700}> {"View Customer"} </Text>
          </Box>
        }
        size={"900px"}
        centered
      >
        <Box p={10} pt={0}>
          <Divider mb={20} />
          <Grid>
            <Col span={isMobileScreen ? 12 : 6}>
              <Text size={"sm"} color="grey">
                {"Username ( Mobile No. )"}
              </Text>
              <Text size={"sm"} fw={"lighter"}>
                {editData.username}
              </Text>
            </Col>
            <Col span={isMobileScreen ? 12 : 6}>
              <Text size={"sm"} color="grey">
                {"First Name"}
              </Text>
              <Text size={"sm"} fw={"lighter"}>
                {editData.first_name}
              </Text>
            </Col>
            <Col span={12}>
              <Text size={"sm"} color="grey">
                {"Email Address"}
              </Text>
              <Text size={"sm"} fw={"lighter"}>
                {editData.email}
              </Text>
            </Col>
            <Col span={12}>
              <Text size={"sm"} color="grey">
                {"Address"}
              </Text>
              <Text size={"sm"} fw={"lighter"}>
                {editData.address}
              </Text>
            </Col>
            <Col span={isMobileScreen ? 12 : 6}>
              <Text size={"sm"} color="grey">
                {"City"}
              </Text>
              <Text size={"sm"} fw={"lighter"}>
                {editData.city}
              </Text>
            </Col>
            <Col span={isMobileScreen ? 12 : 6}>
              <Text size={"sm"} color="grey">
                {"State"}
              </Text>
              <Text size={"sm"} fw={"lighter"}>
                {editData.state}
              </Text>
            </Col>
            <Col span={isMobileScreen ? 12 : 6}>
              <Text size={"sm"} color="grey">
                {"Pincode"}
              </Text>
              <Text size={"sm"} fw={"lighter"}>
                {editData.pincode}
              </Text>
            </Col>
            <Col span={isMobileScreen ? 12 : 6}>
              <Text size={"sm"} color="grey">
                {"GSTIN Number"}
              </Text>
              <Text size={"sm"} fw={"lighter"}>
                {editData.gstin_no}
              </Text>
            </Col>
          </Grid>
        </Box>
      </Modal>

      {isMobileScreen ? (
        <FloatingMenu
          m={5}
          right
          size={50}
          onClick={() => {
            setCustomerFormVisible(true);
            addCustomerForm.reset();
          }}
        >
          <IconPlus color="white" />
        </FloatingMenu>
      ) : (
        <></>
      )}
    </>
  );
}
