import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  LoadingOverlay,
  Table,
  Text,
} from "@mantine/core";
import { IconBox, IconPlus, IconTruckDelivery, IconUser } from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import AssignDeliveryModal from "./AssignDeliveryModal";
import StatusChangeModal from "./StatusChangeModal";
import { order_api } from "./order.service";
import { image_url } from "../../config/index";

export default function ViewDelivery() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState({});
  const [deliveryAssignModalVisible, setDeliveryAssignModalVisible] = useState(false);
  const [statusChangeModalVisible, setStatusChangeModalVisible] = useState(false);

  const order_data = useQuery(
    ["order_data", searchParams.get("id")],
    () => order_api.get_delivery_order(searchParams.get("id")),
    {
      onSuccess: data => {
        setOrder(data.data);
        console.log(order);
      },
    }
  );

  return (
    <div>
      <Container fluid>
        <Box p={25}>
          <Flex justify={"space-between"} align="end">
            <Box>
              <Text size={"lg"} fw={600} mb={10}>
                Delivery #5
              </Text>
              <AppBreadcrumb />
            </Box>
            <Box>
              {/* <Button
                variant="outline"
                onClick={() => {
                  navigate(`/orders/print?id=${searchParams.get("id")}`);
                }}
              >
                <Text ml={10}> Print Order Details </Text>
              </Button> */}
            </Box>
          </Flex>
        </Box>
      </Container>

      <Container size={"xl"} pos={"relative"}>
        <LoadingOverlay visible={order_data.isLoading || order_data.isRefetching} overlayBlur={5} />
        <Box px={25}>
          <Box bg={"white"} p={30} mb={20}>
            <Grid gutter={30}>
              <Grid.Col sm={6}>
                <Flex align={"center"}>
                  <IconBox size={20} />
                  <Text fw={"bold"} ml={8}>
                    Order Details
                  </Text>
                </Flex>

                <Table withBorder striped mt={20}>
                  <tbody>
                    <tr>
                      <td width={160}>
                        <Text opacity={0.5}>ID</Text>
                      </td>
                      <td> {order?.order_id} </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Date </Text>
                      </td>
                      <td>{order && moment(order?.timestamp).format("DD-MM-YYYY -- hh:mm A")} </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Status </Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}>{order?.status}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Payment Type </Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}>{order?.order_type}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Payment ID </Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}> - </Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Text opacity={0.5}> Order Total </Text>
                      </td>

                      <td> ₹ {order?.cart?.total.toLocaleString("en-IN")}</td>
                    </tr>
                  </tbody>
                </Table>
                {/* <Button
                  variant="outline"
                  mt={20}
                  w={`100%`}
                  onClick={() => {
                    setStatusChangeModalVisible(true);
                  }}
                >
                  Change Order Status
                </Button> */}
              </Grid.Col>
              <Grid.Col sm={6}>
                <Flex align={"center"}>
                  <IconUser size={20} />
                  <Text fw={"bold"} ml={8}>
                    Customer Details
                  </Text>
                </Flex>

                <Table withBorder striped mt={20}>
                  <tbody>
                    <tr>
                      <td width={130}>
                        <Text opacity={0.5}>Name</Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}>{order?.user?.first_name}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> Phone No. </Text>
                      </td>
                      <td>
                        <Text tt={"capitalize"}>{order?.user?.username}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> Email address </Text>
                      </td>
                      <td>
                        <Text>{order?.user?.email}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Text size={"xs"} opacity={0.5}>
                          Address
                        </Text>
                        <Text>{order?.address?.address_line_1}</Text>
                        <Text>{order?.address?.address_line_2}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> City </Text>
                      </td>
                      <td>
                        <Text>{order?.address?.city}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> State </Text>
                      </td>
                      <td>
                        <Text>{order?.address?.state}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> Pincode </Text>
                      </td>
                      <td>
                        <Text>{order?.address?.pincode}</Text>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Grid.Col>
            </Grid>
          </Box>

          <Grid>
            <Grid.Col span={12}>
              <Box bg={"white"} p={30}>
                <Flex align={"center"}>
                  <IconBox size={20} />
                  <Text fw={"bold"} ml={8}>
                    Order Items
                  </Text>
                </Flex>

                <Table withBorder striped mt={20}>
                  <thead>
                    <tr>
                      <th> No. </th>
                      <th> Product </th>
                      <th> Price </th>
                      <th> Qty. </th>

                      {/* <th> Tax amount </th>
                      <th> Delivery charge </th> */}
                      <th> Total Amount </th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.cart?.cartitem?.map((e, index) => (
                      <tr>
                        <td width={50}> {index + 1} </td>
                        <td width={"300px"}>
                          <Flex align={"center"}>
                            <Image
                              p={4}
                              mr={20}
                              radius={"100px"}
                              width={30}
                              height={30}
                              src={image_url + e.image.image}
                              alt=""
                            />
                            {e.product}
                          </Flex>
                        </td>
                        <td> ₹ {e.selling_price} </td>
                        <td> {e.quantity} </td>

                        {/* <td>₹ {e.tax_total.toLocaleString("en-IN", {})}</td>
                        <td>₹ {e.delivery_total.toLocaleString("en-IN", {})}</td> */}
                        <td> ₹ {e.total.toLocaleString("en-IN", {})} </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th> </th>
                      <th> Total </th>
                      <th> </th>
                      <th>
                        {order?.cart?.cartitem.reduce((total, item) => item.quantity + total, 0)}
                      </th>

                      <th>
                        ₹ {order?.cart?.cartitem.reduce((total, item) => item.total + total, 0)}
                      </th>
                    </tr>
                  </tfoot>
                </Table>
              </Box>
            </Grid.Col>
          </Grid>
        </Box>
      </Container>

      <AssignDeliveryModal
        orderId={searchParams.get("id")}
        opened={deliveryAssignModalVisible}
        onClose={() => {
          setDeliveryAssignModalVisible(false);
        }}
      />

      <StatusChangeModal
        orderId={searchParams.get("id")}
        currentStatus={order?.status}
        opened={statusChangeModalVisible}
        onClose={() => {
          setStatusChangeModalVisible(false);
        }}
      />
    </div>
  );
}
