import { httpClient } from "lib/axios";
import { getCounterId, isCounterAdmin } from "pages/Auth/permissionGuard";

export const invoice_api = {
  get_stock_list: async (pageSize, pageNumber) => {
    return await httpClient
      .get(`stock_list/?page_size=${pageSize}&page_number=${pageNumber}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },

  change_transfer_status: async payload => {
    return await httpClient.post(`stock_transfer/`, payload);
  },
  get_items: async () => {
    return await httpClient
      .get(`counter_stock/?counter_id=${isCounterAdmin() ? getCounterId() : 'null'}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_invoices: async (pageIndex, pageSize, counter, mode) => {
    let url = `invoice/?page_number=${pageIndex}&page_size=${pageSize}`;
    if (counter) {
      url = `invoice/?page_number=${pageIndex}&page_size=${pageSize}&counter_id=${counter}`;
    }
    if (mode === "unlimited") {
      url = `invoice/?page_number=1&page_size=10000`;
    }
    return await httpClient
      .get(url)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  submit_invoice: async payload => {
    return await httpClient.post(`invoice/`, payload);
  },

  deliver_invoice: async invoiceId => {
    return await httpClient
      .patch(`invoice/?invoice_id=${invoiceId}`)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },

  edit_invoice: async payload => {
    return await httpClient.put(`invoice/`, payload);
  },

  get_invoice_by_id: async payload => {
    return await httpClient.get(`invoice/?invoice_id=${payload}`);
  },
  get_invoice_print_data_by_id: async payload => {
    return await httpClient.get(`print_invoice/?invoice_id=${payload}`)
  },
  delete_invoice: async payload => {
    return await httpClient.delete(`invoice/?invoice_id=${payload}`);
  },
  transfer_stock: async payload => {
    return await httpClient.post(`stock_list/`, payload);
  },
  edit_stock_transfer: async payload => {
    return await httpClient.put(`stock_list/`, payload);
  },
};
