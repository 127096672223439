import {
  Alert,
  Box,
  Button,
  Card,
  Col,
  Container,
  Flex,
  Grid,
  Group,
  Modal,
  NumberInput,
  SegmentedControl,
  Text,
  TextInput,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconCircleCheck } from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import moment from "moment";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import { order_api } from "./order.service";

export default function DeliveryList() {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const [otp, setOtp] = useState(null);
  const [order, setOrder] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);

  // const orders_data = useQuery("orders", order_api.get);
  const delivery_list = useQuery({
    queryKey: ["delivery-data"],
    queryFn: () => order_api.get_orders_for_delivery(),
  });

  // console.log(orders_data.data);

  const openDeliverConfirmation = id => {
    openConfirmModal({
      centered: true,
      title: "Are you sure you want to deliver this order?",
      children: <Text size="sm">This cannot be undone.</Text>,
      labels: { confirm: "Confirm Delivery", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => await deliverOrder(id),
      confirmProps: { color: "green" },
    });
  };

  const handleDeliverOrder = order => {
    setOpened(true);
    console.log(order);
    setOrder(order);
  };

  const deliverOrder = async () => {
    setLoading(true);
    console.log(order);
    console.log(otp);
    if (order.user_otp === otp.toString()) {
      await order_api
        .changeStatus({
          order_id: order.id,
          status: "delivered",
          payment_method: paymentMethod,
        })
        .then(res => {
          setLoading(false);
          console.log(res);
          if (res.data.success) {
            setOpened(false);
            showSuccessToast({
              title: "Success",
              message: res.data.message,
            });
            delivery_list.refetch();
          } else {
            showErrorToast({
              title: "Error",
              message: res.data.message,
            });
          }
        })
        .catch(err => {
          setLoading(false);
          showErrorToast({
            title: "Error",
            message: err.data?.message,
          });
        });
    } else {
      showErrorToast({
        title: "Error",
        message: "Please enter correct OTP",
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Order ID",
        accessorKey: "order_id",
        size: 20,
      },
      {
        header: "Date",
        accessorKey: "timestamp",
        size: 50,
        Cell: ({ cell }) => <span>{moment(cell.getValue()).format("DD MMMM, YYYY")}</span>,
      },
      {
        header: "Customer Name",
        accessorKey: "customer_name",
        Cell: ({ cell }) => <Text tt={"capitalize"}>{cell.getValue()}</Text>,
      },

      {
        header: "Amount",
        accessorKey: "total",
        Cell: ({ cell }) => <span>₹ {cell.getValue()?.toLocaleString("en-IN")}</span>,
      },
    ],
    []
  );

  return (
    <>
      <Container fluid>
        <Box p={25}>
          <Flex justify={"space-between"} align="end">
            <Box>
              <Text size={"lg"} fw={600} mb={10}>
                Deliveries
              </Text>
              <AppBreadcrumb />
            </Box>
          </Flex>
        </Box>
      </Container>

      <Container fluid>
        <Box px={25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <Grid>
              {delivery_list.data?.data.length < 1 && (
                <Alert color="yellow" w={"100%"} title="No Data Available" mt={10}>
                  No deliveries are available.
                </Alert>
              )}
              {delivery_list.data?.data.map((e, index) => (
                <Col sm={4} key={index}>
                  <Card shadow="sm" padding="lg" radius="md" withBorder>
                    <Group position="apart" mb="xs">
                      <Text weight={500}>{e.order_id}</Text>
                    </Group>
                    <Group position="apart" mb="xs">
                      <Text color="dimmed"> {e.customer_name} </Text>
                    </Group>
                    <Button
                      variant="outline"
                      color="blue"
                      fullWidth
                      mt="md"
                      radius="md"
                      onClick={() => {
                        navigate(`view/?id=${e.id}`);
                      }}
                    >
                      View Order details
                    </Button>
                    <Button
                      color="primary"
                      fullWidth
                      mt="sm"
                      radius="md"
                      rightIcon={<IconCircleCheck size={18} />}
                      onClick={() => {
                        handleDeliverOrder(e);
                        setAmount(0);
                      }}
                    >
                      Deliver the order
                    </Button>
                  </Card>
                </Col>
              ))}
            </Grid>
            {/*  */}
          </Box>
        </Box>
      </Container>

      <Modal
        onClose={() => {
          setOpened(false);
        }}
        opened={opened}
        styles={{ header: { borderBottom: "1px solid #eee" } }}
        title={
          <Box px={10}>
            <Text fw={600}> Deliver order </Text>
          </Box>
        }
        size={"460px"}
        centered
      >
        <Box p={10}>
          <SegmentedControl
            color="primary"
            styles={{
              root: {
                width: "100%",
              },
            }}
            mb={10}
            defaultValue={paymentMethod}
            onChange={setPaymentMethod}
            data={[
              { label: "Cash", value: "cash" },
              { label: "Card ( Online Payment )", value: "card" },
            ]}
          />
          {paymentMethod === "card" ? (
            <></>
          ) : (
            <Grid my={5}>
              <Col span={4}>
                <Text c="dimmed" fw={500} size={"xs"}>
                  Total Amount
                </Text>
                <Text> ₹ {order?.total} </Text>
              </Col>
              <Col span={4}>
                <Text c="dimmed" fw={500} size={"xs"}>
                  Balance Amount
                </Text>
                <Text>₹ {order?.total - amount < 0 ? 0 : order?.total - amount}</Text>
              </Col>

              <Col span={4}>
                <Flex justify={"end"}>
                  <Box>
                    <Text c="primary" fw={500} size={"xs"}>
                      Handover Amount
                    </Text>
                    <Text>₹ {amount - order?.total < 0 ? 0 : amount - order?.total}</Text>
                  </Box>
                </Flex>
              </Col>
              <Col span={12}>
                <NumberInput
                  label="Amount"
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={e => {
                    setAmount(e);
                  }}
                />
              </Col>
            </Grid>
          )}
          <TextInput
            type="number"
            label="OTP"
            description="4 Digits"
            placeholder="Enter OTP"
            onChange={e => {
              setOtp(e.target.value);
            }}
          />
          <Button onClick={deliverOrder} mt={15} w={"100%"} loading={loading}>
            Delivery the order
          </Button>
        </Box>
      </Modal>

      {/* ENDS :: View order modal */}
    </>
  );
}
