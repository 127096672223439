import { joiResolver } from "@hookform/resolvers/joi";
import {
  ActionIcon,
  Box,
  Button,
  Col,
  Container,
  Divider,
  FileInput,
  Flex,
  Grid,
  Modal,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconEdit, IconGitFork, IconPlus, IconTrash } from "@tabler/icons";
import FloatingMenu from "components/FloatingMenu";
import { image_url } from "config";
import produce from "immer";
import Joi from "joi";
import { MantineReactTable } from "mantine-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";

import { categories_api } from "./category.service";
import dummyData from "./dummyData.json";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { api_deleteProduct } from "../../../services/product.service";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";

export default function CategoriesList() {
  const navigate = useNavigate("/");
  const isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [loading, setLoading] = useState(false);
  // END : States

  // STARTS : States
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // ENDS : States

  const openDeleteConfirmation = id => {
    openConfirmModal({
      title: "Please confirm delete category",
      children: (
        <Text size="sm">
          Are you sure you want to delete this category ? Everything related to this category will
          be deleted.
        </Text>
      ),
      labels: { confirm: "Delete Category", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => await deleteCategory(id),
      confirmProps: { color: "red" },
    });
  };

  const categories = useQuery("product_categories", categories_api.get);

  // console.log(categories.data.data);

  const addCategory = async values => {
    setLoading(true);
    console.log(values);

    const formData = new FormData();
    if (values.image) {
      formData.append("image", values.image);
    }
    formData.append("title", values.title);

    await categories_api.add(formData).then(res => {
      setLoading(false);
      console.log("Response", res);
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "New category succesfully added.",
        });
        setCategoryModalVisible(false);
        categories.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  const editCategory = async values => {
    setLoading(true);
    console.log(values);

    const formData = new FormData();
    if (values.image) {
      formData.append("image", values.image);
    }
    formData.append("title", values.title);
    formData.append("id", values.id);

    await categories_api.edit(formData).then(res => {
      setLoading(false);
      console.log("Response", res);
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Category sucessfully edited.",
        });
        setCategoryModalVisible(false);
        categories.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  const deleteCategory = async cateogry_id => {
    setLoading(true);

    await categories_api.delete(cateogry_id).then(res => {
      setLoading(false);
      console.log("Response", res);
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Category sucessfully deleted.",
        });
        setCategoryModalVisible(false);
        categories.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Category",
        accessorKey: "title",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => (
          <Flex align={"center"}>
            <img
              src={image_url + row.original.image}
              width={30}
              height={30}
              style={{
                borderRadius: 100,
                border: "2px solid #eee",
                objectFit: "cover",
              }}
              alt=""
            />
            <Text ml={10}> {renderedCellValue} </Text>
          </Flex>
        ),
      },
    ],
    []
  );

  // const first = (second) => { third }

  const validationSchema = Joi.object({
    title: Joi.string().required().label("Category Name").messages({
      "string.empty": "Category Name is required",
    }),
    image: Joi.optional().allow(null),
    id: Joi.optional().allow(null),
  });

  //

  const {
    control,
    register: categoryForm,
    handleSubmit,
    reset,
    setValue,

    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      id: "",
      title: "",
      image: null,
    },
    resolver: joiResolver(validationSchema),
  });

  const initEdit = data => {
    setIsEditing(true);
    reset({
      id: data.id,
      title: data.title,
    });
    setCategoryModalVisible(true);
  };

  return (
    <>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box px={isMobileScreen ? 0 : 25} py={isMobileScreen ? 15 : 25}>
            <Flex justify={"space-between"} align="end" wrap={"wrap"}>
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Categories
                </Text>
                <AppBreadcrumb />
              </Box>
              <Box mt={isMobileScreen ? 10 : 0}>
                <Button
                  onClick={() => {
                    setCategoryModalVisible(true);
                  }}
                >
                  <IconPlus style={{ width: "20px" }} />
                  <Text ml={10}> Add Category </Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        {/* Add Product Form */}

        {/* Add Product Form */}

        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Categories List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  <Tooltip label="View Sub Categories">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      onClick={() => {
                        navigate(
                          `sub-categories?category=${row.original.title}&id=${row.original.id}`
                        );
                      }}
                    >
                      <IconGitFork style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Edit Category">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      ml={10}
                      onClick={() => {
                        initEdit(row.original);
                      }}
                    >
                      <IconEdit style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>

                  <Tooltip label="Delete Category">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.red[6] })}
                      ml={10}
                      onClick={() => {
                        openDeleteConfirmation(row.original.id);
                      }}
                    >
                      <IconTrash style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              )}
              initialState={{ density: "xs" }}
              state={{
                isLoading: categories.isLoading || categories.isRefetching,
              }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={isMobileScreen}
              enableColumnActions={false}
              data={categories.data?.data || []}
              enableStickyHeader
              enableGlobalFilter={true} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}

      {/* STARTS :: Add User Modal */}
      <Modal
        opened={categoryModalVisible}
        onClose={() => {
          setCategoryModalVisible(false);
        }}
        title={
          <Box px={10}>
            <Text fw={700}> Add New Category </Text>
          </Box>
        }
        size={"660px"}
        centered
      >
        <Divider mb={20} />
        <Box px={10}>
          <form
            onSubmit={handleSubmit(values => {
              isEditing ? editCategory(values) : addCategory(values);
            })}
          >
            <Grid>
              <Col sm={6}>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label="Category Name"
                      placeholder="Enter Category Name"
                      error={errors.title?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={6}>
                <Controller
                  name="image"
                  control={control}
                  loading={loading}
                  render={({ field }) => (
                    <FileInput
                      accept=".jpg, .png"
                      label="Category Image"
                      placeholder="Select Category Image"
                      error={errors.image?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={12} mb={20}>
                <Button w={"100%"} type="submit" loading={loading}>
                  {isEditing ? "Edit Category" : "Add Category"}
                </Button>
              </Col>
            </Grid>
          </form>
        </Box>
      </Modal>

      {isMobileScreen ? (
        <FloatingMenu
          m={5}
          right
          size={50}
          onClick={() => {
            setCategoryModalVisible(true);
          }}
        >
          <IconPlus color="white" />
        </FloatingMenu>
      ) : (
        <></>
      )}
      {/* ENDS :: Add User Modal */}
    </>
  );
}
