import { httpClient } from "lib/axios";
import { isCounterAdmin,getCounterId } from "pages/Auth/permissionGuard";

export const return_api = {
  get_returns: async counter => {
    let url = `return_stock/`;
    if (isCounterAdmin()) {
      url = `return_stock/?counter_id=${counter}`;
    }else{
      url = `return_stock/`;
    }
    return await httpClient
      .get(url)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_reasons: async () => {
    return await httpClient
      .get(`return_stock/?reason=true`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  change_transfer_status: async payload => {
    return await httpClient.post(`stock_transfer/`, payload);
  },
  get_products: async () => {
    return await httpClient
      .get(`stock_list/?product=true`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  transfer_stock: async payload => {
    return await httpClient.post(`stock_list/`, payload);
  },
  edit_stock_transfer: async payload => {
    return await httpClient.put(`stock_list/`, payload);
  },
  add_return_stock: async payload => {
    return await httpClient
      .post(`return_stock/`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  edit_return_stock_qty: async payload => {
    return await httpClient
      .put(`return_stock/`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  edit_return_stock_status: async payload => {
    return await httpClient
      .put(`return_stock/?status=accept`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
};
