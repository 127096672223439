import { Box, Button, Flex, LoadingOverlay, Modal, Text, TransferList } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import PropTypes from "prop-types";
import { useState } from "react";
import { useQuery } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import AddPermissionGroupModal from "./AddPermissionGroupModal";
import { getAlteredPermissions, getAlteredPermissionsGroups } from "./helperFunctions";
import {
  api_assignPermissions,
  api_assignPermissionsGroup,
  api_getPermissions,
  api_getPermissionsGroups,
  api_getPermissions_user,
} from "./users.service";

// import { order_api } from "./order.service";

function PermissionGroupModal({ userId, opened, onClose, orderId, currentStatus }) {
  const [permissions, setPermissions] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [existingPermissions, setExistingPermissions] = useState();
  const [permissionsFetched, setPermissionsFetched] = useState(false);
  const [loading, setLoading] = useState(false);

  const permission_group_list = useQuery("user_permissions_group", api_getPermissionsGroups, {
    enabled: opened,
    onSuccess: async data => {
      console.log("real data", data);
      await putExistingPermissions(data.data);
    },
  });

  const putExistingPermissions = async allpermissions => {
    setLoading(true);
    await api_getPermissions_user(userId, "group").then(res => {
      setLoading(false);

      const a = allpermissions;
      const b = res.data.groups;

      console.log({ a, b });

      const available_permissions = a.filter(e => {
        if (!b.some(x => x.id === e.id)) {
          return e;
        }
      });

      console.log("only available permissions", available_permissions);

      const allPermissions = getAlteredPermissionsGroups(available_permissions);

      console.log("existing permissions", res.data.groups);
      const existing = getAlteredPermissionsGroups(res.data.groups);
      console.log(allPermissions[0]);
      console.log(existing[0]);
      setPermissions([allPermissions[0], existing[0]]);
    });
  };

  const assignPermissions = async () => {
    setLoading(true);
    await api_assignPermissionsGroup({
      id: userId,
      groups: permissions[1].map(e => e.id),
    }).then(res => {
      setLoading(false);
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Successfully Assigned Permissions.",
        });
        onClose();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        zIndex={10}
        styles={{ header: { borderBottom: "1px solid #eee" } }}
        title={
          <Box px={10}>
            <Text fw={600}> Assign Permission Group </Text>
          </Box>
        }
        size={"860px"}
        centered
        pos={"relative"}
      >
        <Box px={10} my={20} pos={"relative"}>
          <LoadingOverlay visible={permission_group_list.isLoading || loading} />

          {!permission_group_list.isLoading && permissions.length > 0 && (
            <TransferList
              listHeight={200}
              value={permissions || [{}]}
              onChange={setPermissions}
              searchPlaceholder="Search..."
              nothingFound="Nothing here"
              titles={[
                <Flex justify={"space-between"} align="center">
                  <Text size={"14px"}>Available Groups</Text>
                  <Button
                    size={"xs"}
                    variant="light"
                    leftIcon={<IconPlus size={14} />}
                    onClick={() => {
                      setModalOpened(true);
                    }}
                  >
                    Add Permission Group
                  </Button>
                </Flex>,
                <Text size={"14px"}>Choosen Groups</Text>,
              ]}
              breakpoint="sm"
            />
          )}

          <Box mt={20}>
            <Button w={"100%"} onClick={assignPermissions}>
              Assign Selected Permissions
            </Button>
          </Box>
        </Box>
      </Modal>

      <AddPermissionGroupModal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
        }}
      />
    </>
  );
}

PermissionGroupModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PermissionGroupModal;
