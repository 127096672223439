import { httpClient } from '../../../lib/axios';

export const review_api = {
  get: async () => {
    return await httpClient
      .get('review/')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  changeStatus: async (payload) => {
    return await httpClient
      .put('review/?status=true', payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  delete: async (review_id) => {
    return await httpClient
      .delete(`review/?review_id=${review_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  bulkDelete: async (payload) => {
    return await httpClient
      .delete(`review/?delete=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};
