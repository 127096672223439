import { Box, Button, Modal, Text } from "@mantine/core";
import { IconCheck, IconMailFast, IconPackage, IconTruckDelivery } from "@tabler/icons";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { isCounterAdmin } from "pages/Auth/permissionGuard";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import { order_api } from "./order.service";

const statusIconProps = {
  size: 18,
  style: {
    opacity: "0.5",
  },
};

function StatusChangeModal({ opened, onClose, orderId, currentStatus }) {
  const queryClient = useQueryClient();

  const [error, setError] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    setStatuses([
      {
        label: "Accepted",
        value: "accepted",
        icon: <IconCheck {...statusIconProps} />,
        disabled: true,
        active: false,
      },
      {
        label: "Packed",
        value: "packed",
        icon: <IconPackage {...statusIconProps} />,
        active: false,
        disabled: currentStatus !== "packed" && currentStatus !== "accepted",
      },
      {
        label: "Dispatched",
        value: "dispatched",
        icon: <IconTruckDelivery {...statusIconProps} />,
        active: false,
        disabled: currentStatus !== "accepted" && currentStatus !== "packed",
        // currentStatus !== "dispatched",
      },
      {
        label: "Out For Delivery",
        value: "out_for_delivery",
        icon: <IconMailFast {...statusIconProps} />,
        active: false,
        disabled:
          currentStatus !== "accepted" &&
          currentStatus !== "packed" &&
          currentStatus !== "dispatched" &&
          currentStatus !== "out_for_delivery",
      },
      {
        label: "Delivered",
        value: "delivered",
        icon: <IconCircleCheckFilled {...statusIconProps} />,
        active: false,
        disabled:
          currentStatus !== "accepted" &&
          currentStatus !== "packed" &&
          currentStatus !== "dispatched" &&
          currentStatus !== "out_for_delivery" &&
          currentStatus !== "delivered",
      },
    ]);
  }, [currentStatus]);

  const changeStatus = useMutation({
    mutationFn: data => order_api.changeStatus(data),
    onSuccess: data => {
      console.log(data);
      if (data.data.success) {
        showSuccessToast({ title: "Success", message: "Changed order status" });
        queryClient.invalidateQueries("orders-data");
        queryClient.invalidateQueries(["order_data", orderId]);
        setSelectedStatus(null);
        onClose();
      } else {
        showErrorToast({ title: "Error", message: data.data.message });
      }
    },
    onError: () => {
      showErrorToast({ title: "Error", message: "Error changing order status." });
    },
  });

  const changeOrderStatus = () => {
    if (selectedStatus) {
      setError(null);
      changeStatus.mutate({
        order_id: orderId,
        status: selectedStatus,
      });
    } else {
      setError("Please select status.");
    }
  };

  const handleStatusClick = value => {
    setError(null);

    for (let i of statuses) {
      i.active = false;
    }

    const current_status = statuses.find(e => e.value === value);
    current_status.active = true;
    // console.log(current_status);
    setSelectedStatus(current_status.value);
  };

  return (
    <Modal
      opened={opened}
      onClose={!changeStatus.isLoading ? onClose : undefined}
      closeOnClickOutside={!changeStatus.isLoading}
      closeOnEscape={!changeStatus.isLoading}
      zIndex={10}
      styles={{ header: { borderBottom: "1px solid #eee" } }}
      title={
        <Box px={10}>
          <Text fw={600}> Order Status </Text>
        </Box>
      }
      size={"460px"}
      centered
    >
      <Box px={10} my={20}>
        <Button.Group orientation="vertical">
          {statuses.map(e => (
            <Button
              key={e.value}
              disabled={e.disabled || e.value === currentStatus}
              leftIcon={e.icon}
              fw={500}
              sx={theme => ({
                "&:active": {
                  transform: "none",
                },
              })}
              style={
                isCounterAdmin() && e.value !== "out_for_delivery"
                  ? {
                      pointerEvents: "none",
                    }
                  : {}
              }
              styles={{
                label: {
                  textAlign: "left",
                  width: "100%",
                },
              }}
              variant={e.active ? "filled" : "default"}
              onClick={() => {
                handleStatusClick(e.value);
              }}
            >
              {e.label}
            </Button>
          ))}
        </Button.Group>

        {error && (
          <Text color="red" size={"14px"} mt={10} fw={600}>
            {error}
          </Text>
        )}

        {isCounterAdmin() && selectedStatus === "out_for_delivery" ? (
          <Button
            loading={changeStatus.isLoading}
            mt={20}
            w={"100%"}
            onClick={() => {
              changeOrderStatus();
            }}
          >
            Update Order Status
          </Button>
        ) : (
          ""
        )}

        {!isCounterAdmin() && (
          <Button
            loading={changeStatus.isLoading}
            mt={20}
            w={"100%"}
            onClick={() => {
              changeOrderStatus();
            }}
          >
            Update Order Status
          </Button>
        )}
      </Box>
    </Modal>
  );
}

StatusChangeModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderId: PropTypes.number,
  currentStatus: PropTypes.string,
};

export default StatusChangeModal;
