/**
 * Modifying counters array as the need of select
 * @param initial_counters_array It takes counters array retrived from the api
 */
export const getAlteredCustomers = array => {
  let modifiedList = array.map(e => {
    return {
      label: e.username===''?' ':e.username+' - '+e.first_name,
      value: e.id,
      id: e.id,
    };
  });
  return modifiedList;
};
/**
 * Modifying counters array as the need of select
 * @param initial_counters_array It takes counters array retrived from the api
 */
export const getAlteredCounters = array => {
  let modifiedList = array.map(e => {
    return {
      label: e.name,
      value: e.id,
      id: e.id,
    };
  });
  return modifiedList;
};
