/**
 * Modifying permissions array as the need of select
 * @param initial_permissions_array It takes permissions array retrived from the api
 */
export const getAlteredPermissions = array => {
  let modifiedList = array.map(e => {
    return {
      label: (e.codename.charAt(0).toUpperCase() + e.codename.slice(1)).replace("_", " "),
      value: e.id.toString(),
      id: e.id,
    };
  });
  let alteredList = [modifiedList, []];
  return alteredList;
};

/**
 * Modifying permissions groups array as the need of select
 * @param initial_permissions_groups_array It takes permissions groups array retrived from the api
 */
export const getAlteredPermissionsGroups = array => {
  let modifiedList = array.map(e => {
    return {
      label: (e.name.charAt(0).toUpperCase() + e.name.slice(1)).replace("_", " "),
      value: e.id.toString(),
      id: e.id,
    };
  });
  let alteredList = [modifiedList, []];
  return alteredList;
};
