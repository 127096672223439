import { Box, Button, Flex, LoadingOverlay, Modal, Select, Text } from "@mantine/core";
import { IconTruckDelivery } from "@tabler/icons";
import { getCounterId } from "pages/Auth/permissionGuard";
import PropTypes from "prop-types";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import { order_api } from "./order.service";

function AssignDeliveryModal({ opened, onClose, orderId, delivery }) {
  const queryClient = useQueryClient();

  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [selectedDeliveryPartnerId, setSelectedDeliveryPartnerId] = useState(null);
  const [error, setError] = useState(null);

  const [counter, setCounter] = useState(getCounterId());

  const delivery_partners = useQuery(
    "delivery_partners",
    () => order_api.get_delivery_partner(counter),
    {
      enabled: opened,
      onSuccess: data => {
        console.log(data);
        setDeliveryPartners(
          data.data.map(e => {
            return {
              label: e.first_name,
              value: e.id,
            };
          })
        );
      },
    }
  );

  const assignDelivery = useMutation({
    mutationFn: data => order_api.assign_delivery_partner(data),
    onSuccess: data => {
      console.log(data);
      showSuccessToast({ title: "Success", message: "Assigned delivery partner" });
      queryClient.invalidateQueries(["order_data", orderId]);
      onClose();
    },
    onError: () => {
      showErrorToast({ title: "Error", message: "Error assigning delivery partner" });
    },
  });

  const assignDeliveryPartner = () => {
    if (selectedDeliveryPartnerId) {
      setError(null);
      assignDelivery.mutate({
        order_id: orderId,
        delivery_id: selectedDeliveryPartnerId,
      });
    } else {
      setError("Please select delivery partner");
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      zIndex={10}
      styles={{ header: { borderBottom: "1px solid #eee" } }}
      title={
        <Box px={10}>
          <Text fw={600}> Assign Delivery Partner </Text>
        </Box>
      }
      size={"460px"}
      centered
    >
      <Box px={10} pos={"relative"} my={20}>
        <LoadingOverlay visible={delivery_partners.isLoading} overlayBlur={2} />
        <Select
          withinPortal
          label="Select Delivery Partner"
          data={deliveryPartners || []}
          defaultValue={delivery}
          error={error}
          searchable
          onChange={e => {
            setSelectedDeliveryPartnerId(e);
          }}
          placeholder="Type to search.."
        ></Select>
        <Button
          loading={assignDelivery.isLoading}
          mt={20}
          w={"100%"}
          onClick={() => {
            assignDeliveryPartner();
          }}
        >
          Assign Delivery Partner
          <Flex ml={10}>
            <IconTruckDelivery style={{ width: 20 }} />
          </Flex>
        </Button>
      </Box>
    </Modal>
  );
}

AssignDeliveryModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderId: PropTypes.number,
};

export default AssignDeliveryModal;
