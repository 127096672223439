import { Box, Button, Divider, Modal, MultiSelect, Select, Text } from "@mantine/core";
import { getAlteredCities } from "pages/master/products/helperFunctions";
import { products_api } from "pages/master/products/products.service";
import PropTypes from "prop-types";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";
import { openDeleteConfirmation } from "utilities/utility";

import CounterUsersList from "./CounterUsersList";
import { counters_api } from "./counter.service";

function CounterUsersModal({ opened, onClose, isEditing, data }) {
  const queryClient = useQueryClient();

  const [counterUsers, setCounterUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const assignUserMutation = useMutation({
    mutationFn: data => counters_api.assign_users(data),
    onSuccess: data => {
      console.log(data);
      showSuccessToast({
        title: "Success",
        message: "Assigned users to counter.",
      });
      queryClient.invalidateQueries(["counters-data"], ["counters-users"]);

      onClose();
    },
    onError: () => {
      showErrorToast({
        title: "Error",
        message: isEditing ? "Error editing counter" : "Error adding Counter.",
      });
    },
  });

  const counter_users = useQuery("counter-users", counters_api.get_counter_users, {
    onSuccess: data => {
      console.log(data.data.data);
      setCounterUsers(
        data.data.data.map(e => {
          return {
            label: `${e.first_name} - ${e.username}`,
            value: e.id,
          };
        })
      );
    },
  });

  const handleAssignUsers = () => {
    if (selectedUsers.length) {
      assignUserMutation.mutate({
        counter_id: data.id,
        users: selectedUsers,
      });
    } else {
      showErrorToast({
        title: "Error",
        message: "Please select users.",
      });
    }
  };

  return (
    <div>
      <Modal
        opened={opened}
        onClose={onClose}
        title={
          <Box px={10}>
            <Text fw={600}> Assign users to counter </Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Box>
            <Box>
              <MultiSelect
                data={counterUsers || []}
                onChange={setSelectedUsers}
                placeholder="Search users.."
                searchable
              />
              <Button
                loading={assignUserMutation.isLoading}
                w={"100%"}
                mt={10}
                onClick={handleAssignUsers}
              >
                Assign user to counter
              </Button>
            </Box>
          </Box>
          <CounterUsersList data={data.counter_user} onUnassignUser={onClose} />
        </Box>
      </Modal>
    </div>
  );
}

CounterUsersModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  editData: PropTypes.object,
};

export default CounterUsersModal;
