import { httpClient } from '../../../lib/axios';

export const categories_api = {
  get: async () => {
    return await httpClient
      .get('category/')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  add: async (payload) => {
    return await httpClient
      .post('category/', payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  edit: async (payload) => {
    return await httpClient
      .put('category/', payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  delete: async (category_id) => {
    return await httpClient
      .delete(`category/?category_id=${category_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const sub_categories_api = {
  get: async (category_id) => {
    return await httpClient
      .get(`sub_category/?category_id=${category_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  add: async (payload) => {
    return await httpClient
      .post('sub_category/', payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  edit: async (payload) => {
    return await httpClient
      .put('sub_category/', payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  delete: async (category_id) => {
    return await httpClient
      .delete(`sub_category/?sub_category_id=${category_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};
