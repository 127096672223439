import { ActionIcon, Box, Button, Container, Flex, Switch, Text, Tooltip } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconEdit, IconPlus, IconTrash, IconUserPlus } from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import AppPageHeader from "components/AppPageHeader";
import FloatingMenu from "components/FloatingMenu";
import produce from "immer";
import { MantineReactTable } from "mantine-react-table";
import { hasPermission } from "pages/Auth/permissionGuard";
import { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";
import { openDeleteConfirmation } from "utilities/utility";

import AddCounterModal from "./AddCounterModal";
import CounterUsersModal from "./CounterUsersModal";
import { counters_api } from "./counter.service";

export default function CountersList() {
  const queryClient = useQueryClient();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  const [selectedCounter, setSelectedCounter] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const [addCounterModalVisible, setAddCounterModalVisible] = useState(false);
  const [assignUsersModalVisible, setAssignUsersModalVisible] = useState(false);

  const [countersList, setCountersList] = useState([]);

  const counter_data = useQuery({
    queryKey: ["counters-data"],
    queryFn: () => counters_api.get(),
    onSuccess: data => {
      setCountersList(data.data);
    },
  });

  const columns = useMemo(
    () => [
      {
        header: "Counter Name",
        accessorKey: "name",
        Cell: ({ cell }) => <Text tt={"capitalize"}>{cell.getValue()}</Text>,
        size: "auto",
      },
      {
        header: "Contact Person",
        accessorKey: "contact_person",
        Cell: ({ cell }) => <Text tt={"capitalize"}>{cell.getValue()}</Text>,
        size: "auto",
      },
      {
        header: "Contact Number",
        accessorKey: "contact_number",
        Cell: ({ cell }) => <Text tt={"capitalize"}>{cell.getValue()}</Text>,
        size: "auto",
      },
      {
        header: "Active",
        accessorKey: "active",
        size: "auto",
        Cell: ({ cell, row }) => (
          <Switch
            onChange={e => handleStatusChange(row.original.id, e.target.checked)}
            checked={cell.getValue()}
            size={"xs"}
          />
        ),
      },
    ],
    []
  );

  const handleRemoveCounter = id => {
    openDeleteConfirmation("Counter", () => {
      deleteCounter.mutate({
        users: [id],
      });
    });
  };

  const deleteCounter = useMutation({
    mutationFn: data => counters_api.unassign_users(data),
    onSuccess: data => {
      console.log(data);
      showSuccessToast({
        title: "Success",
        message: "Deleted Counter.",
      });
      queryClient.invalidateQueries(["counters-data"], ["counters-users"]);
    },
    onError: () => {
      showErrorToast({
        title: "Error",
        message: "Error deleting counter.",
      });
    },
  });

  const changeStatus = (id, status) => {
    setCountersList(
      produce(draft => {
        let item = draft.find(e => e.id === id);
        item.active = status;
      })
    );
  };

  const handleStatusChange = async (id, status) => {
    await counters_api
      .changeStatus({
        id: id,
        active: status,
      })
      .then(res => {
        console.log("res", res);
        if (res.data.success) {
          changeStatus(id, status);
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      });
  };

  const handleEdit = data => {
    setSelectedCounter(data);
    setIsEditing(true);
    setAddCounterModalVisible(true);
  };

  return (
    <>
      <Container fluid p={isMobileScreen ? 5 : 25}>
        {isMobileScreen ? (
          <></>
        ) : (
          <AppPageHeader
            title="Counters"
            button={
              hasPermission("add_counter") && (
                <Button
                  onClick={() => {
                    setAddCounterModalVisible(true);
                  }}
                >
                  <IconPlus style={{ width: "20px" }} />
                  <Text ml={10}> Add Counter </Text>
                </Button>
              )
            }
          />
        )}</Container>
      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Counters List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  <>
                    {hasPermission("change_counter") && (
                      <>
                        <Tooltip label="Edit Counter">
                          <ActionIcon
                            mr={10}
                            sx={theme => ({ color: theme.colors.brand[7] })}
                            onClick={() => {
                              handleEdit(row.original);
                            }}
                          >
                            <IconEdit style={{ width: 20 }} />
                          </ActionIcon>
                        </Tooltip>
                        <Tooltip label="Assign users">
                          <ActionIcon
                            mr={10}
                            sx={theme => ({ color: theme.colors.brand[7] })}
                            onClick={() => {
                              setSelectedCounter(row.original);
                              setAssignUsersModalVisible(true);
                            }}
                          >
                            <IconUserPlus style={{ width: 20 }} />
                          </ActionIcon>
                        </Tooltip>
                      </>
                    )}
                    {hasPermission("delete_counter") && (
                      <Tooltip label="Delete Counter">
                        <ActionIcon
                          sx={theme => ({ color: theme.colors.red[7] })}
                          onClick={() => {
                            setSelectedCounter(row.original);
                            setAssignUsersModalVisible(true);
                          }}
                        >
                          <IconTrash style={{ width: 20 }} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </>
                </Flex>
              )}
              initialState={{ density: "xs" }}
              state={{
                isLoading: counter_data.isLoading || counter_data.isRefetching,
              }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              rowCount={counter_data.data?.count}
              data={countersList || []}
              enableStickyHeader
              enableGlobalFilter={false}
            />
          </Box>
        </Box>
      </Container>

      <AddCounterModal
        opened={addCounterModalVisible}
        onClose={() => {
          setAddCounterModalVisible(false);
        }}
        isEditing={isEditing}
        editData={selectedCounter}
      />

      <CounterUsersModal
        opened={assignUsersModalVisible}
        data={selectedCounter}
        onClose={() => {
          setAssignUsersModalVisible(false);
        }}
      />
      {hasPermission("add_counter") && isMobileScreen ? (
        <FloatingMenu
          m={5}
          right
          size={50}
          onClick={() => {
            setAddCounterModalVisible(true);
          }}
        >
          <IconPlus color="white" />
        </FloatingMenu>
      ) : (
        <></>
      )}
      {/* ENDS :: View order modal */}
    </>
  );
}
