import {
  Box,
  Button,
  Col,
  Container,
  Flex,
  Grid,
  Image,
  LoadingOverlay,
  Table,
  Text,
} from "@mantine/core";
import { IconBox, IconPlus, IconPrinter, IconTruckDelivery, IconUser } from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import { image_url } from "config";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { order_api } from "./order.service";

export default function OrderPrint() {
  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState({});
  const [deliveryAssignModalVisible, setDeliveryAssignModalVisible] = useState(false);
  const [statusChangeModalVisible, setStatusChangeModalVisible] = useState(false);

  const order_data = useQuery(
    ["order_data", searchParams.get("id")],
    () => order_api.get_order(searchParams.get("id")),
    {
      onSuccess: data => {
        setOrder(data.data);
      },
    }
  );

  const Header = () => {
    return (
      <thead>
        <Box bg={"white"} pr={20} pl={20} pt={20}>
          <Grid gutter={30} grow>
            <Grid.Col sm={4}>
              <Flex align={"center"}>
                <IconBox size={20} />
                <Text fw={"bold"} ml={8}>
                  Order Details
                </Text>
              </Flex>

              <Table withBorder striped mt={15}>
                <tbody>
                  <tr>
                    <td width={160}>
                      <Text opacity={0.5}>ID</Text>
                    </td>
                    <td> {order?.order_id} </td>
                  </tr>
                  <tr>
                    <td>
                      <Text opacity={0.5}> Date </Text>
                    </td>
                    <td>{order && moment(order?.timestamp).format("DD-MM-YYYY -- hh:mm A")} </td>
                  </tr>

                  <tr>
                    <td>
                      <Text opacity={0.5}> Order Total </Text>
                    </td>

                    <td> ₹ {order?.cart?.total.toLocaleString("en-IN")}</td>
                  </tr>
                </tbody>
              </Table>
              {/* <Button
              variant="outline"
              mt={20}
              w={`100%`}
              onClick={() => {
                setStatusChangeModalVisible(true);
              }}
            >
              Change Order Status
            </Button> */}
            </Grid.Col>
            <Grid.Col sm={4}>
              <Flex align={"center"}>
                <IconUser size={20} />
                <Text fw={"bold"} ml={8}>
                  Customer Details
                </Text>
              </Flex>

              <Table withBorder striped mt={15}>
                <tbody>
                  <tr>
                    <td width={130}>
                      <Text opacity={0.5}>Name</Text>
                    </td>
                    <td>
                      <Text tt={"capitalize"}>{order?.user?.first_name}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td width={100}>
                      <Text opacity={0.5}> Phone No. </Text>
                    </td>
                    <td>
                      <Text tt={"capitalize"}>{order?.user?.username}</Text>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Grid.Col>
            {/* <Grid.Col sm={4}>
              <Flex>
                <Flex align={"center"}>
                  <IconTruckDelivery size={20} />
                  <Text fw={"bold"} ml={8}>
                    Delivery Details
                  </Text>
                </Flex>
              </Flex>

              <Box mt={15}>
                <Table withBorder striped mt={20}>
                  <tbody>
                    <tr>
                      <td width={130}>
                        <Text opacity={0.5}>Name</Text>
                      </td>
                      <td>
                        <Text>{order?.delivery?.first_name}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> Phone No. </Text>
                      </td>
                      <td>
                        <Text>{order?.delivery?.username}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> Pickup OTP </Text>
                      </td>
                      <td>
                        <Text>{order?.delivery?.vendor_otp}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td width={100}>
                        <Text opacity={0.5}> Csutomer OTP </Text>
                      </td>
                      <td>
                        <Text>{order?.delivery?.user_otp}</Text>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* <Button
                variant="outline"
                mt={20}
                w={`100%`}
                onClick={() => {
                  setDeliveryAssignModalVisible(true);
                }}
              >
                Change Delivery Partner
              </Button>
              </Box>
            </Grid.Col> */}
          </Grid>
        </Box>
      </thead>
    );
  };
  

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setTimeout(() => {
      handlePrint();
    }, 2000);
  }, []);

  const Body = props => {
    return (
      <>
        <Header />
        <tbody>
          <Grid mt={10}>
            <Grid.Col span={12}>
              <Box bg={"white"} pr={20} pl={20}>
                <Flex align={"center"}>
                  <IconBox size={20} />
                  <Text fw={"bold"} ml={8}>
                    Order Items
                  </Text>
                </Flex>

                <Table withBorder striped mt={15}>
                  <thead>
                    <tr>
                      <th> No. </th>
                      <th> Product </th>
                      <th> Batch </th>
                      <th> Price </th>
                      <th> Qty. </th>
                      <th> Rack </th>
                      <th> Total Amount </th>
                    </tr>
                  </thead>
                  {order?.cart?.cartitem?.map((e, index) => (
                    <>
                      {index >= props.itemStart && index < props.itemEnd ? (
                        <tbody>
                          <tr>
                            <td width={50}> {index + 1} </td>
                            <td width={"300px"}>{e.product}</td>
                            <td width={"300px"}>{e.batch_no}</td>
                            <td width={"60px"}>{e.mrp}</td>
                            <td width={20}>{e.quantity} </td>
                            <td width={20}>
                              {" "}
                              {e.tally_category !== "\u0004 Not Applicable"
                                ? e.tally_category
                                : ""}{" "}
                            </td>

                            <td style={{ whiteSpace: "nowrap" }}>
                              ₹ {e.total.toLocaleString("en-IN", {})}{" "}
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  <tfoot>
                    <tr>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                      <th>
                        {/* {" "}
                        {order?.cart?.cartitem.reduce(
                          (total, item) => item.quantity + total,
                          0
                        )}{" "} */}
                      </th>
                      <th> </th>

                      <th style={{ whiteSpace: "nowrap" }}>
                        ₹ {order?.cart?.cartitem.reduce((total, item) => item.total + total, 0)}
                      </th>
                    </tr>
                  </tfoot>
                </Table>
              </Box>
            </Grid.Col>
          </Grid>
        </tbody>
      </>
    );
  };

  const PrintOrderPage = (itemStart, itemEnd) => {
    return (
      <Box sx={{ borderRadius: 2 }} mb={25}>
        <Box bg={"white"}>
          <Container size={"xl"} pos={"relative"}>
            <LoadingOverlay
              visible={order_data.isLoading || order_data.isRefetching}
              overlayBlur={5}
            />
            <Box>
              <Table>
                <Body itemStart={itemStart} itemEnd={itemEnd} />
              </Table>
            </Box>
          </Container>
        </Box>
      </Box>
    );
  };

  const pages = [];
  const num_of_items_per_page = 10;
  const num_of_pages = parseInt(order?.cart?.cartitem?.length / num_of_items_per_page + 1);
  for (let i = 0; i < num_of_pages; i++) {
    let itemStart = i * num_of_items_per_page;
    let itemEnd = itemStart + num_of_items_per_page;
    pages.push(PrintOrderPage(itemStart, itemEnd));
  }

  return (
    <div>
      <Container fluid>
        <Box p={25}>
          <Flex justify={"space-between"} align="end">
            <Box>
              <Text size={"lg"} fw={600} mb={10}>
                Print Order
              </Text>
              <AppBreadcrumb />
            </Box>
            <Box>
              <Button
                leftIcon={<IconPrinter size={16} />}
                onClick={() => {
                  handlePrint();
                }}
              >
                Print Order
              </Button>
            </Box>
          </Flex>
        </Box>
      </Container>

      <Container fluid ref={componentRef}>
        {/* page1 */}
        <>{pages.map((e, index) => e)}</>
      </Container>
    </div>
  );
}
