import { Box, Button, Flex, LoadingOverlay, Modal, Text, TransferList } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import PropTypes from "prop-types";
import { useState } from "react";
import { useQuery } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import { getAlteredPermissions } from "./helperFunctions";
import {
  api_assignPermissions,
  api_getPermissions,
  api_getPermissions_user,
} from "./users.service";

// import { order_api } from "./order.service";

function PermissionsModal({ userId, opened, onClose, orderId, currentStatus }) {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const permissions_list = useQuery("user_permissions", api_getPermissions, {
    enabled: opened,
    onSuccess: async data => {
      console.log("real data", data);
      await putExistingPermissions(data.data);
    },
  });

  const putExistingPermissions = async allpermissions => {
    setLoading(true);
    await api_getPermissions_user(userId, "permissions").then(res => {
      setLoading(false);

      const a = allpermissions;
      const b = res.data.user_permissions;

      const available_permissions = a.filter(e => {
        if (!b.some(x => x.id === e.id)) {
          return e;
        }
      });

      console.log("only available permissions", available_permissions);

      const allPermissions = getAlteredPermissions(available_permissions);

      console.log("existing permissions", res.data.groups);
      const existing = getAlteredPermissions(res.data.user_permissions);
      console.log(allPermissions[0]);
      console.log(existing[0]);
      setPermissions([allPermissions[0], existing[0]]);
    });
  };

  const assignPermissions = async () => {
    await api_assignPermissions({
      id: userId,
      permissions: permissions[1].map(e => e.id),
    }).then(res => {
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Successfully Assigned Permissions.",
        });
        onClose();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      zIndex={10}
      styles={{ header: { borderBottom: "1px solid #eee" } }}
      title={
        <Box px={10}>
          <Text fw={600}> Assign Permissions </Text>
        </Box>
      }
      size={"860px"}
      centered
    >
      <Box px={10} my={20} pos={"relative"}>
        <LoadingOverlay visible={permissions_list.isLoading || loading} />
        {!permissions_list.isLoading && permissions.length > 0 && (
          <TransferList
            listHeight={200}
            value={permissions || [{}]}
            onChange={setPermissions}
            searchPlaceholder="Search..."
            nothingFound="Nothing here"
            titles={[
              <Flex justify={"space-between"} align="center">
                <Text size={"14px"}>Available Permission</Text>
              </Flex>,
              <Text size={"14px"}>Choosen Permissions</Text>,
            ]}
            breakpoint="sm"
          />
        )}

        <Box mt={20}>
          <Button w={"100%"} onClick={assignPermissions}>
            Assign Selected Permissions
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

PermissionsModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PermissionsModal;
