import { httpClient } from "../../../lib/axios";

export const api_getUsers = async () => {
  return await httpClient
    .post("user_modal/?get=True&page_number=1&page_size=1000")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_addUser = async payload => {
  return await httpClient
    .post("user_modal/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_editUser = async payload => {
  return await httpClient
    .put("user_modal/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_getRoles = async () => {
  return await httpClient
    .get("user_modal/?role=true")
    .then(res => {
      let modified_data = res.data?.data.map(e => {
        return {
          label: e[0],
          value: e[1],
        };
      });
      return modified_data;
    })
    .catch(err => {
      return err;
    });
};

export const api_getUserById = async id => {
  return await httpClient
    .get(`user_modal/?user_id=${id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_getRoute = async () => {
  return await httpClient
    .post("crud/", {
      function_type: "read_all",
      data: null,
      many: true,
      app_name: "com",
      model_name: "route",
    })
    .then(res => {
      let modified_data = res.data?.data.map(e => {
        return {
          label: e.name,
          value: e.id,
        };
      });
      return modified_data;
    })
    .catch(err => {
      return err;
    });
};

export const api_deleteUser = async user_id => {
  return await httpClient
    .delete(`user_modal/?user_id=${user_id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_getPermissions = async () => {
  return await httpClient
    .get("user_modal/?permissions=True")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_getPermissionsGroups = async () => {
  return await httpClient
    .get("permission_group/")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_getPermissions_user = async (id, type) => {
  return await httpClient
    .get(`permission_group/?user_id=${id}&type=${type}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_addPermissionsGroups = async payload => {
  return await httpClient
    .post("permission_group/?create=true", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_assignpermission_groups = async payload => {
  return await httpClient
    .post("permission_group/?assign_permissions=True", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_assignPermissionsGroup = async payload => {
  return await httpClient
    .put("permission_group/?assign_group=True", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_assignPermissions = async payload => {
  return await httpClient
    .put("user_modal/?assign_permissions=True", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_removePermissions = async payload => {
  return await httpClient
    .put("user_modal/?remove_permissions=True", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_clearPermissions = async payload => {
  return await httpClient
    .put("user_modal/?clear_all_permissions=True", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_assignUserStatus = async payload => {
  return await httpClient
    .put("user_modal/?change_user_status=True", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
