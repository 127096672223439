import { joiResolver } from "@hookform/resolvers/joi";
import {
  Alert,
  Box,
  Button,
  Col,
  Divider,
  Flex,
  Grid,
  LoadingOverlay,
  Modal,
  NumberInput,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput, DatePicker, DatePickerInput } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconCheck,
  IconCircleCheck,
  IconCoinRupee,
  IconCurrencyRupee,
  IconEdit,
  IconPlus,
} from "@tabler/icons";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import Joi from "joi";
import moment from "moment";
import { getCounterId } from "pages/Auth/permissionGuard";
import { invoice_api } from "pages/transactions/invoice/invoice.service";
import {
  api_addPaymentReceipt,
  api_editPaymentReceipt,
  api_getPaymentReceipt,
} from "pages/transactions/payment-receipt/paymentReceipt.service";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

function AddPaymentReceipt({ opened, onClose, invoiceId, defaultData, edit, fromInvoice }) {
  const amountRef = useRef(null);
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  const [counter, setCounter] = useState(getCounterId());
  const [balanceAmount, setBalanceAmount] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [invoiceData, setInvoiceData] = useState({});
  const [rawInvoices, setRawInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remianingAmount, setRemianingAmount] = useState(null);

  const emptyMessage = label => {
    return {
      "string.empty": `${label} is required.`,
      "number.base": `${label} is required.`,
    };
  };

  const validationSchema = Joi.object({
    invoice: Joi.number().required().messages(emptyMessage("Invoice")),
    date: Joi.date().required().messages(emptyMessage("Date")),
    payment_type: Joi.string().required().messages(emptyMessage("Payment Type")),
    amount: Joi.number().required().messages(emptyMessage("Amount")),
  });

  const {
    control,
    register: addPaymentReceiptForm,
    handleSubmit,
    reset,
    setValue,
    setValues,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      invoice: null,
      date: new Date(),
      payment_type: "cash",
      amount: "",
    },
    resolver: joiResolver(validationSchema),
  });

  // Getting the invoices
  const invoices_data = useQuery({
    queryKey: ["invoices_list", counter],
    queryFn: () => invoice_api.get_invoices(null, null, counter, "unlimited"),
    onSuccess: data => {
      setRawInvoices(data.data);
      const invoice = data.data.map(e => {
        return {
          id: e.id,
          label: e.invoice_id ? e.invoice_id : "##" + e.id,
          value: e.id,
        };
      });
      console.log(invoice);
      setInvoices(invoice);
    },
  });

  // Setting the data from selected invoice
  const handleInvoiceSelect = async id => {
    setValue("invoice", id);
    const invoice = rawInvoices.find(e => e.id === id);
    setInvoiceData(invoice);
    await calculateBalanceAmount(invoice.total, id);
  };

  // Calculate Balance amount
  const calculateBalanceAmount = async (totalAmount, id) => {
    setLoading(true);
    return await api_getPaymentReceipt(counter, id).then(res => {
      setLoading(false);
      // Setting balance amount
      const total_receipt_amount = res.data.reduce((sum, { amount }) => sum + amount, 0);
      console.log("total of receipts :", total_receipt_amount);
      setBalanceAmount(totalAmount - +total_receipt_amount);
    });
  };

  // Calculate Remaining amount
  const calculateRemianingAmount = amount => {
    setValue("amount", +amount);
    setRemianingAmount(amount - balanceAmount);
  };

  // Adding the payment receipt
  const addPaymentReceipt = async values => {
    setLoading(true);
    let payload = {
      amount: +values.amount > +balanceAmount ? +balanceAmount : +values.amount,
      date: moment(values.date).format("YYYY-MM-DD"),
      invoice_id: values.invoice,
      mode_of_payment: values.payment_type,
    };
    await api_addPaymentReceipt(payload).then(res => {
      setLoading(false);
      console.log("Response", res);

      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Payment Receipt succesfully added.",
        });
        deliverInvoice(values.invoice);
        onClose();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  // Editing the payment receipt
  const editPaymentReceipt = async values => {
    setLoading(true);

    let payload = {
      payment_receipt_id: defaultData.id,
      amount: values.amount,
      date: moment(values.date).format("YYYY-MM-DD"),
      mode_of_payment: values.payment_type,
    };

    console.log(payload);

    await api_editPaymentReceipt(payload).then(res => {
      setLoading(false);

      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Payment receipt succesfully edited.",
        });
        onClose();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  const deliverInvoice = async id => {
    await invoice_api.deliver_invoice(id).then(res => {
      console.log(res.data);
      if (res.data.success) {
        showSuccessToast({
          title: "Success",
          message: res.data.message,
        });
        invoices.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: res.data.message,
        });
      }
    });
  };

  useEffect(() => {
    if (invoiceId) {
      console.log({ defaultData });
      const setData = async () => {
        await calculateBalanceAmount(defaultData.total, invoiceId);
        setInvoiceData(defaultData);
      };
      setData();
      setValue("invoice", invoiceId);
      setTimeout(() => {
        console.log(amountRef);
        amountRef.current?.focus();
      }, 100);
      console.log(defaultData);

      // if (fromInvoice) {
      // setValue("amount", defaultData.total);
      // }
    }
  }, [invoiceId, defaultData]);

  useEffect(() => {
    if (edit) {
      console.log({ defaultData });
      setValue("invoice", defaultData.invoice);
      setValue("amount", defaultData.amount);
      setValue("date", new Date(defaultData.date));
      setValue("payment_type", defaultData.payment_type);

      const setData = async () => {
        await calculateBalanceAmount(defaultData.total, invoiceId);
        await handleInvoiceSelect(defaultData.invoice);
      };

      setData();
    }
  }, [edit]);

  return (
    <div>
      <Modal
        opened={opened}
        onClose={() => {
          onClose();
          reset();
          setInvoiceData({});
          setBalanceAmount(null);
          setRemianingAmount(null);
        }}
        styles={{ header: { borderBottom: "1px solid #eee" }, body: { padding: 0 } }}
        title={
          <Box px={10}>
            <Text> Add New Payment Receipt </Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <LoadingOverlay visible={loading} />
        <Box>
          <Box my={20} px={30}>
            <Grid>
              <Col span={isMobileScreen ? 6 : 2}>
                <Text c="dimmed" fw={500} size={"xs"}>
                  Invoice No.
                </Text>
                <Text>{invoiceData?.invoice_id}</Text>
              </Col>
              <Col span={isMobileScreen ? 6 : 2}>
                <Text c="dimmed" fw={500} size={"xs"}>
                  Total Amount
                </Text>
                <Text> ₹ {invoiceData?.total}</Text>
              </Col>
              <Col span={isMobileScreen ? 6 : 2}>
                <Text c="dimmed" fw={500} size={"xs"}>
                  Balance Amount
                </Text>
                <Text>₹ {balanceAmount?.toFixed(2).toLocaleString(2) || ""}</Text>
              </Col>

              <Col span={isMobileScreen ? 6 : 4}>
                <Flex justify={"end"}>
                  <Box>
                    <Text c="primary" fw={500} size={"xs"}>
                      Handover Amount
                    </Text>
                    <Text fw={500} size={"lg"}>
                      ₹&nbsp;
                      {+getValues("amount") - +balanceAmount > 0
                        ? (+getValues("amount") - +balanceAmount).toFixed(2)
                        : 0}
                    </Text>
                  </Box>
                </Flex>
              </Col>

              {/* <Col span={2}>
                <Flex justify={"end"}>
                  <Box>
                    <Text c="dimmed" fw={500} size={"xs"}>
                      Remaining Amount
                    </Text>
                    <Text>₹ {+remianingAmount?.toFixed(2) || 0}</Text>
                  </Box>
                </Flex>
              </Col> */}
            </Grid>
          </Box>

          <Divider opacity={0.5} />

          {balanceAmount === 0 ? (
            <Box mt={20} mb={40} px={30}>
              <Alert
                color="green"
                title={<Text> Payment Settled </Text>}
                icon={<IconCircleCheckFilled />}
              >
                <Text size={"sm"}> Payment has been settled completely.</Text>
              </Alert>
            </Box>
          ) : (
            <Box mt={20} mb={40} px={30}>
              <Text c="dimmed" fw={500} size={"sm"} mb={10}>
                {edit ? "Edit Payment Receipt" : "Add Payment Receipt"}
              </Text>

              <form
                onError={() => {
                  console.log(errors);
                }}
                onSubmit={handleSubmit(values => {
                  console.log(values);
                  edit ? editPaymentReceipt(values) : addPaymentReceipt(values);
                })}
              >
                <Grid>
                  <Col sm={6}>
                    <Controller
                      name="invoice"
                      control={control}
                      render={({ field }) => (
                        <Select
                          disabled={edit || fromInvoice}
                          withinPortal
                          data={invoices || []}
                          searchable
                          label="Invoice"
                          placeholder="Select Invoice"
                          error={errors.invoice?.message}
                          {...field}
                          onChange={async e => {
                            await handleInvoiceSelect(e);
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Controller
                      name="date"
                      control={control}
                      render={({ field }) => (
                        <DatePickerInput
                          popoverProps={{ withinPortal: true }}
                          label="Date"
                          valueFormat="YYYY-MM-DD"
                          placeholder="Select Date"
                          error={errors.date?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Controller
                      name="payment_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          data={[
                            { label: "Cash", value: "cash" },
                            { label: "Card", value: "card" },
                            { label: "Gift", value: "gift" },
                          ]}
                          searchable
                          label="Payment Type"
                          placeholder="Select Payment Type"
                          error={errors.payment_type?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Controller
                      name="amount"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          icon={<IconCurrencyRupee size={16} />}
                          type="number"
                          hideControls
                          precision={2}
                          // max={+balanceAmount}
                          // withinPortal
                          label="Amount"
                          placeholder="Enter Amount"
                          error={errors.amount?.message}
                          {...field}
                          ref={amountRef}
                          onChange={e => {
                            calculateRemianingAmount(e);
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={12}>
                    <Button
                      w={"100%"}
                      leftIcon={edit ? <IconEdit size={16} /> : <IconPlus size={16} />}
                      mt={10}
                      type="submit"
                    >
                      {edit ? "Edit Payment Receipt" : "Add Payment Receipt"}
                    </Button>
                  </Col>
                </Grid>
              </form>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default AddPaymentReceipt;
