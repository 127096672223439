import { Box, Button, Flex, Modal, Text, TextInput, TransferList } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import PropTypes from "prop-types";
import { useState } from "react";
import { useQuery } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import { getAlteredPermissions } from "./helperFunctions";
import {
  api_addPermissionsGroups,
  api_assignPermissions,
  api_assignPermissionsGroup,
  api_getPermissions,
} from "./users.service";

// import { order_api } from "./order.service";

function AddPermissionGroupModal({ userId, opened, onClose, orderId, currentStatus }) {
  const [permissions, setPermissions] = useState();
  const [permissionGroupName, setPermissionGroupName] = useState();

  const permissions_list = useQuery("user_permissions_list", api_getPermissions, {
    // enabled: opened,
    onSuccess: data => {
      setPermissions(getAlteredPermissions(data.data));
      console.log(getAlteredPermissions(data.data));
    },
  });

  const createGroup = async () => {
    if (permissionGroupName) {
      await api_addPermissionsGroups({
        name: permissionGroupName,
        permissions: permissions[1].map(e => e.id),
      }).then(res => {
        if (res.success) {
          console.log(res.data);
          showSuccessToast({
            title: "Success",
            message: "Permission group has been created.",
          });
          onClose();
        } else {
          showErrorToast({
            title: "Error",
            message: res.message,
          });
        }
      });
    } else {
      showErrorToast({
        title: "Error",
        message: "Please enter name.",
      });
    }
  };

  const assignPermissions = async () => {
    await api_assignPermissions({
      name: "",
      permissions: permissions[1].map(e => e.id),
    }).then(res => {
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Successfully Assigned Permissions.",
        });
        onClose();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      zIndex={10}
      styles={{ header: { borderBottom: "1px solid #eee" } }}
      title={
        <Box px={10}>
          <Text fw={600}> Add Permission Group </Text>
        </Box>
      }
      size={"860px"}
      centered
    >
      <Box px={10} my={20}>
        <TextInput
          mb={15}
          label="Group Name"
          placeholder="Enter Group Name"
          onChange={e => {
            setPermissionGroupName(e.target.value);
          }}
        />
        <TransferList
          listHeight={200}
          value={permissions || [{}]}
          onChange={setPermissions}
          searchPlaceholder="Search..."
          nothingFound="Nothing here"
          titles={[
            <Flex justify={"space-between"} align="center">
              <Text size={"14px"}>Available Permission</Text>
              {/* <Button
                size={"xs"}
                variant="light"
                leftIcon={<IconPlus size={14} />}
                onClick={() => {
                //   setPermissionGroupModalVisible(true);
                }}
              >
                Add Permission Group
              </Button> */}
            </Flex>,
            <Text size={"14px"}>Choosen Permissions</Text>,
          ]}
          breakpoint="sm"
        />
        <Box mt={30}>
          <Button w={"100%"} onClick={createGroup}>
            Create Permission Group
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

AddPermissionGroupModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddPermissionGroupModal;
