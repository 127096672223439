import {
  Box,
  Button,
  Col,
  Divider,
  Flex,
  Grid,
  Modal,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { api_addCustomer, api_getCustomers } from "pages/master/customers/customers.service";
import { getAlteredCustomers } from "pages/master/customers/helperFunctions";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

function CustomerSelection(props) {
  // START : States
  const [customersData, setCustomersData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [searchValue, onSearchChange] = useState("");
  // END : States

  // Fetching customers
  const customers = useQuery("customers", api_getCustomers, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      props.setCustomers(data.data);
      setCustomersData(getAlteredCustomers(data.data));
    },
  });
  //console.log(customersData);

  const addCustomerMutation = useMutation({
    mutationFn: palyoad => {
      return api_addCustomer(palyoad);
    },
    onSuccess: data => {
      showSuccessToast({ title: "Success", message: data.data?.message });
      customers.refetch();
    },
    onError: () => {
      console.log("Error occured while creating customer");
      showErrorToast({ title: "Error", message: "Error occured while creating customer." });
    },
  });

  //console.log(props.id);

  const addCustomerForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      username: "",
      first_name: "",
    },
    validate: {
      username: value => {
        if (value.toString().length === 0) {
          return "Username is required";
        }
        if (value.toString().length !== 10) {
          return "Username is not valid.";
        }
      },
      first_name: value => (value ? null : "Firstname is required"),
    },
  });

  return (
    <>
      <Select
        label="Select Customer"
        mb={10}
        data={customersData}
        autoFocus={props.autoFocus}
        ref={props.reference}
        value={props.id}
        placeholder="Select Customer"
        nothingFound="Customers not found"
        disabled={addCustomerMutation.isLoading || props.disabled}
        onChange={props.onChange}
        error={props.error}
        searchable
        onSearchChange={onSearchChange}
        searchValue={searchValue}
        creatable
        getCreateLabel={query => `+ Create ${query}`}
        onCreate={query => {
          if (/^\d{10}$/.test(query)) {
            props.setError("");
            setModalOpened(true);
            addCustomerForm.reset();
            addCustomerForm.setFieldValue("username", Number(query));
          } else {
            props.setError("Mobile number is not valid");
          }
        }}
      />

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setIsEditing(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>{isEditing ? "Edit customer" : "Add customer"}</Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Box>
            <form
              onSubmit={addCustomerForm.onSubmit(payload => {
                addCustomerMutation.mutate(payload);
                setModalOpened(false);
              })}
            >
              <Grid>
                <Col sm={6}>
                  <NumberInput
                    label="Customer"
                    placeholder="Enter Customer"
                    {...addCustomerForm.getInputProps("username")}
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    label="First Name"
                    placeholder="Enter First Name"
                    {...addCustomerForm.getInputProps("first_name")}
                  />
                </Col>
                <Col span={12} my={10}>
                  <Flex h={"100%"} direction={"column"} justify="end" align={"center"}>
                    <Button w={"100%"} type="submit" disabled={addCustomerMutation.isLoading}>
                      {isEditing ? "Edit Customer" : "Add Customer"}
                    </Button>
                  </Flex>
                </Col>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default CustomerSelection;
