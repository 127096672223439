import { Anchor, Box, Breadcrumbs, Container, Divider, Flex, Table, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import "./print.css";

export default function InvoicePrint() {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});

  const pageStyle = `
  html,body{
    padding:0;
    margin:0;
  }
  @media print {  
    html,body{
      padding:0;
      margin:0;
    }
    @page{
      margin:0;
      padding:0;
    }
  }`;

  console.log(location.state);

  useEffect(() => {
    // setData(location.state);
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentRef.current,
  });

  const testDump = () => {
    const els = [];
    for (let i = 0; i < 10; i++) {
      els.push(
        <tr key={i}>
          <td> Amul Butter - 500 Grams </td>
          <td> FDDD22WEDD </td>
          <td> ₹ 550 </td>
          <td> 5 % </td>
          <td> - </td>
          <td> 5 </td>
          <td> - </td>
          <td> - </td>
          <td>₹ 550</td>
        </tr>
      );
    }

    return els;
  };

  useEffect(() => {
    handlePrint();
  }, []);

  return (
    <>
      <Container fluid>
        <Box p={25}>
          <Flex justify={"space-between"} align="end">
            <Box>
              <Text size={"lg"} fw={600} mb={10}>
                Print Invoice
              </Text>
              <Breadcrumbs separator="/" sx={{ fontSize: 14 }}>
                <Anchor href="/">Home</Anchor>
                <Anchor href="/">Transactions</Anchor>
                <Anchor>Invoice Print</Anchor>
              </Breadcrumbs>
            </Box>
          </Flex>
        </Box>
      </Container>
      {/* STARTS :: Main content */}

      <Container fluid>
        <Box px={25} sx={{ borderRadius: 2 }} style={{}}>
          <Box
            bg={"white"}
            ref={componentRef}
            style={{
              border: "1px solid #ccc",
            }}
          >
            <Box px={"2mm"}>
              <Text fw={600} size={"10"} align="center" mt={3}>
                Quzone
              </Text>
              <Text size={"9px"} align="center">
                36H, Thendral Nagar, Oddanchhantram
              </Text>
              <Text size={"9px"} align="center">
                +91 9629174525
              </Text>
              <Divider style={{ borderWidth: 0.4, borderStyle: "dashed" }} my={5} />
              <Flex justify={"space-between"}>
                <Text size={"10"}>Bill No : QZ-1223</Text>
                <Text size={"10"}> Date : 25/08/2023 </Text>
              </Flex>
              <Divider style={{ borderWidth: 0.4, borderStyle: "dashed" }} my={5} />
              <Text size={"10"} align="center" mb={4}>
                <strong> Customer Details </strong>
              </Text>
              <Flex justify={"space-between"}>
                <Text size={"10"}>Name</Text>
                <Text size={"10"}> Subramani Prakash Jha </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text size={"10"}>Mobile</Text>
                <Text size={"10"}> +91 7733211223 </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text size={"10"}>Place</Text>
                <Text size={"10"}> Oddanchhantram </Text>
              </Flex>
              <Divider style={{ borderWidth: 0.4, borderStyle: "dashed" }} my={5} />
              <Table
                fontsize={"10"}
                cellPadding={0}
                cellSpacing={0}
                horizontalSpacing={0}
                verticalSpacing={"2px"}
              >
                <thead>
                  <th style={{ textAlign: "left", fontWeight: 500 }}> Item </th>
                  <th style={{ textAlign: "right", fontWeight: 500 }} width={60}>
                    Qty
                  </th>
                  <th style={{ textAlign: "right", fontWeight: 500 }} width={120}>
                    Amount
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: 0, textAlign: "left" }}> Vanilla Scoop Pro Max </td>
                    <td style={{ border: 0, textAlign: "right" }}> 1 </td>
                    <td style={{ border: 0, textAlign: "right", whiteSpace: "nowrap" }}> ₹ 500 </td>
                  </tr>
                  <tr>
                    <td style={{ border: 0, textAlign: "left" }}>
                      Vanilla Scoop Pro Max - Takeaway Family Combo Biggest ever{" "}
                    </td>
                    <td style={{ border: 0, textAlign: "right" }}> 1 </td>
                    <td style={{ border: 0, textAlign: "right", whiteSpace: "nowrap" }}>
                      {" "}
                      ₹ 19,500{" "}
                    </td>
                  </tr>
                </tbody>
                <tfoot style={{ marginTop: "5px" }}>
                  <th
                    style={{
                      paddingTop: "3px",

                      textAlign: "left",
                      fontWeight: 500,
                    }}
                  >
                    Total
                  </th>
                  <th
                    style={{
                      paddingTop: "3px",

                      textAlign: "right",
                      fontWeight: 500,
                    }}
                  >
                    17
                  </th>
                  <th
                    style={{
                      paddingTop: "3px",

                      textAlign: "right",
                      fontWeight: 500,
                      whiteSpace: "nowrap",
                    }}
                  >
                    ₹ 38,500
                  </th>
                </tfoot>
              </Table>
              <Divider style={{ borderWidth: 0.4, borderStyle: "dashed" }} my={5} />

              <Flex justify={"space-between"}>
                <Text size={"10"}>Subtotal Amount</Text>
                <Text size={"10"}> ₹ 38,500 </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text size={"10"}>Tax Amount</Text>
                <Text size={"10"}> ₹ 500 </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text size={"10"}>Total Amount</Text>
                <Text size={"10"}> ₹ 50,500 </Text>
              </Flex>
              <Divider style={{ borderWidth: 0.4, borderStyle: "dashed" }} my={5} />
              <Flex justify={"center"} mb={10}>
                <Text size={"10"}>Thankyou!</Text>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}
    </>
  );
}
