import { ActionIcon, Text, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconX } from "@tabler/icons";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import { order_api } from "./order.service";

function CancleOrder({ orderId }) {
  const queryClient = useQueryClient();

  const cancleOrder = useMutation({
    mutationFn: data => order_api.changeStatus(data),
    onSuccess: data => {
      console.log(data);
      if (data.data.success) {
        showSuccessToast({ title: "Success", message: "Order has been cancelled." });
        queryClient.invalidateQueries("orders");
      } else {
        showErrorToast({ title: "Error", message: data.data.message });
      }
    },
    onError: () => {
      showErrorToast({ title: "Error", message: "Error cancelling the order." });
    },
  });

  const openDeleteConfirmation = () => {
    openConfirmModal({
      title: "Please confirm cancle order",
      children: <Text size="sm">Are you sure you want to cancle this order ?</Text>,
      labels: { confirm: "Cancle Order", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        cancleOrder.mutate({
          order_id: orderId,
          status: "cancelled",
        });
      },
      confirmProps: { color: "red" },
    });
  };

  return (
    <Tooltip label="Cancle Order">
      <ActionIcon
        mr={10}
        sx={theme => ({ color: theme.colors.red[7] })}
        onClick={openDeleteConfirmation}
      >
        <IconX style={{ width: 20 }} />
      </ActionIcon>
    </Tooltip>
  );
}

CancleOrder.propTypes = {
  orderId: PropTypes.number,
};

export default CancleOrder;
