import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconBoxSeam,
  IconDownload,
  IconEye,
  IconFile,
  IconFile3d,
  IconFileAnalytics,
  IconFileBarcode,
  IconPaperclip,
  IconPlus,
  IconPrinter,
  IconStatusChange,
  IconTruckDelivery,
} from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import FloatingMenu from "components/FloatingMenu";
import PackingSlip from "components/PackingSlip";
import { PrintModal } from "components/PrintModal";
import SalesOrderPrintComponent from "components/SalesOrderPrintComponent";
import { MantineReactTable } from "mantine-react-table";
import moment from "moment";
import { getCounterId, hasPermission, isCounterAdmin } from "pages/Auth/permissionGuard";
import { counters_api } from "pages/master/counter/counter.service";
import { getAlteredCounters } from "pages/master/customers/helperFunctions";
import { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  getBodyContent,
  getCsvContent,
  getCustomId,
  getFields,
  getFilterPayload,
  getHeaders,
  getTotal,
} from "services/filterHelperFunctions";
import { api_filter } from "services/master.service";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import AssignDeliveryModal from "./AssignDeliveryModal";
import CancleOrder from "./CancleOrder";
import OrderStatusModal from "./StatusChangeModal";
import { order_api } from "./order.service";

export default function OrdersList() {
  const navigate = useNavigate();
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  const [selectedProduct, setSelectedProduct] = useState({});
  const [deliveryAssignModalVisible, setDeliveryAssignModalVisible] = useState(false);
  const [statusChangeModalVisible, setStatusChangeModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [modalOpened, setModalOpened] = useState(false);
  const [raiseId, setRaiseId] = useState(null);

  const [countersList, setCountersList] = useState([]);
  const [counter, setCounter] = useState(isCounterAdmin() ? getCounterId() : "");

  const [columnFilters, setColumnFilters] = useState([]);
  const [filename, setFilename] = useState("file");

  const counter_data = useQuery({
    queryKey: ["counters-data-list"],
    queryFn: () => counters_api.get(),
    onSuccess: data => {
      setCountersList(getAlteredCounters(data.data));
    },
  });

  // const orders_data = useQuery("orders", order_api.get);
  const orders_data = useQuery({
    queryKey: ["orders-data", pagination.pageIndex, pagination.pageSize, counter],
    queryFn: () => order_api.get(pagination.pageSize, pagination.pageIndex + 1, counter),
  });

  console.log(orders_data.data);

  const printSalesOrder = async id => {
    await order_api.get_order(id).then(res => {
      console.log(res.data);
      const data = res.data;
      const cartitem = data.cart.cartitem;
      const rackOrderItem = [...cartitem].sort((a, b) =>
        a.tally_category > b.tally_category ? 1 : -1
      );
      console.log(rackOrderItem);
      const item = rackOrderItem.map(e => {
        return `<div style="display: flex;padding-top:2px;">
            <p  style="width:46%;padding:0px;">${e.product}/<br/>${e.batch_no}</p>
																 
            <p  style="width:20%;text-align:right;padding:0px;">${e.mrp.toFixed(2)} &emsp; </p> 
            <p  style="width:17%;text-align:center;padding:0px;">${e.quantity}</p> 
            <p  style="width:17%;text-align:right;padding:0px;">${e.tally_category}</p> 
        </div>`;
      });
      const qty = cartitem.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.quantity;
      }, 0);
      const items = item.join("");
      const printWindow = window.open("", "", "width=600,height=600");
      printWindow.document.open();
      printWindow.document.write(SalesOrderPrintComponent(data, items, moment, qty));
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Order ID",
        accessorKey: "order_id",
        size: 20,
      },
      {
        accessorFn: originalRow => new Date(originalRow.timestamp),
        id: "timestamp",
        header: "Date",
        accessorKey: "timestamp",
        size: 50,
        Cell: ({ cell }) => cell.getValue()?.toLocaleString("en-IN"),
        filterVariant: "date-range",
      },
      {
        header: "Customer Name",
        accessorKey: "customer_name",
        Cell: ({ cell }) => <Text tt={"capitalize"}>{cell.getValue()}</Text>,
      },

      {
        header: "Amount",
        accessorKey: "total",
        filterVariant: "range",

        Cell: ({ cell }) => <span>₹ {cell.getValue()?.toLocaleString("en-IN")}</span>,
      },
      {
        header: "Delivery Partner",
        accessorKey: "delivery",
        filterVariant: "range",
        Cell: ({ cell }) => (
          <Badge variant="outline" fw={400}>
            {" "}
            {cell.getValue() ? "Assigned" : "Not Assigned"}
          </Badge>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: ["accepted", "packed", "dispatched", "out_for_delivery", "delivered", "cancelled"],
        },
        filterVariant: "select",
        Cell: ({ cell }) => {
          let color;
          if (cell.getValue() === "delivered") {
            color = "green";
          } else if (cell.getValue() === "accepted") {
            color = "red";
          } else if (cell.getValue() === "dispatched") {
            color = "grape";
          } else if (cell.getValue() === "out_for_delivery") {
            color = "blue";
          } else if (cell.getValue() === "cancelled") {
            color = "gray";
          } else if (cell.getValue() === "packed") {
            color = "yellow";
          } else {
            color = "light";
          }
          return (
            <Badge color={color} variant="filled">
              {cell.getValue()?.replace(/_/g, " ")}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  //   //return parseFloat(text.replace(/[^0-9.]/g, ""));
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   return total;
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length !== i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   const cols = [3];
  //   let sum = [];
  //   get_sum(removeAction).map((e, i) => {
  //     if (isNaN(e) || !cols?.includes(i, 0)) {
  //       sum.push("");
  //     } else {
  //       sum.push(e);
  //     }
  //   });
  //   csv_data.push(sum);
  //   setCsv(csv_data);
  // };

  const printFilter = () => {
    const model = "Order";

    const appendData = [
      { id: "order_id", append: "" },
      { id: "timestamp", append: "" },
      { id: "first_name", append: "user__", replace_id: "customer_name" },
      { id: "total", append: "" },
      { id: "delivery", append: "" },
      { id: "status", append: "" },
    ];
    const custom = getCustomId(columns, columnFilters, appendData);
    const filter = getFilterPayload(custom?.columns, custom?.columnFilters);
    const fields = getFields(custom?.columns);

    api_filter(model, filter, fields)
      .then(res => {
        if (res.success) {
          console.log(res.data);
          const head_content = getHeaders(columns);
          const body_content = getBodyContent(res.data, fields);
          const csv_content = getCsvContent(head_content, body_content, ["ORDERS"]);
          const columnTotalIndex = [3];
          const foot_content = getTotal(body_content, columnTotalIndex);
          setFilename(
            "ORDERS" +
              "_" +
              countersList?.find(e => e.id === counter)?.label +
              "_" +
              new Date().toLocaleDateString() +
              ".csv"
          );
          setCsv(csv_content);
          setHead(head_content);
          setBody(body_content);
          setFoot(foot_content);
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      {isMobileScreen ? (
        <>
          <Select
            p={5}
            label="Counter"
            defaultValue={counter || null}
            placeholder="Select Counter"
            data={countersList || []}
            onChange={setCounter}
            clearable
            disabled={isCounterAdmin()}
          />
        </>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Orders
                </Text>
                <AppBreadcrumb />
              </Box>
              <Box>
                <Flex align={"end"} gap={10}>
                  <Button
                    onClick={() => {
                      setIsCsv(true);
                      printFilter();
                    }}
                    leftIcon={<IconDownload />}
                  >
                    CSV
                  </Button>
                  {/* <Button
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      print(true);
                    }}
                  >
                    Print
                  </Button> */}

                  <Button
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      printFilter();
                    }}
                  >
                    Print
                  </Button>
                  <Select
                    label="Counter"
                    defaultValue={counter || null}
                    placeholder="Select Counter"
                    data={countersList || []}
                    onChange={setCounter}
                    clearable
                    disabled={isCounterAdmin()}
                  />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}

      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : 24} py={10}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={columnFilters} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Orders List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  <Tooltip label="Raise Invoice">
                    <ActionIcon
                      mr={10}
                      sx={theme => ({ color: theme.colors.brand[6] })}
                      onClick={() => {
                        setModalOpened(true);
                        setRaiseId(row.original.id);
                      }}
                    >
                      <IconFileAnalytics style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="View Invoice">
                    <ActionIcon
                      disabled={!row.original.invoice_order}
                      mr={10}
                      sx={theme => ({ color: theme.colors.brand[6] })}
                      onClick={() => {
                        navigate(`/invoices/view?id=${row.original.invoice_order}`);
                      }}
                    >
                      <IconEye style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="View Order Details">
                    <ActionIcon
                      mr={10}
                      sx={theme => ({ color: theme.colors.brand[6] })}
                      onClick={() => {
                        navigate(`view?id=${row.original.id}`);
                      }}
                    >
                      <IconBoxSeam style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Print Order Details">
                    <ActionIcon
                      mr={10}
                      sx={theme => ({ color: theme.colors.brand[6] })}
                      onClick={() => {
                        printSalesOrder(row.original.id);
                      }}
                    >
                      <IconPrinter style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  {hasPermission("change_order") && (
                    <>
                      {row.original.status !== "delivered" &&
                      row.original.status !== "cancelled" ? (
                        <>
                          <Tooltip label=" Status">
                            <ActionIcon
                              mr={10}
                              sx={theme => ({ color: theme.colors.brand[7] })}
                              onClick={() => {
                                setSelectedProduct(row.original);
                                setStatusChangeModalVisible(true);
                              }}
                            >
                              <IconStatusChange style={{ width: 20 }} />
                            </ActionIcon>
                          </Tooltip>
                          {isCounterAdmin() && (
                            <Tooltip label="Assign Delivery">
                              <ActionIcon
                                mr={10}
                                sx={theme => ({ color: theme.colors.brand[7] })}
                                onClick={() => {
                                  setSelectedProduct(row.original);
                                  setDeliveryAssignModalVisible(true);
                                }}
                              >
                                <IconTruckDelivery style={{ width: 20 }} />
                              </ActionIcon>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {row.original.status !== "cancelled" && (
                        <>{!isCounterAdmin && <CancleOrder orderId={row.original.id} />}</>
                      )}
                    </>
                  )}
                </Flex>
              )}
              initialState={{ density: "xs" }}
              //state={{
              //  isLoading: orders_data.isLoading || orders_data.isRefetching,
              //  pagination,
              //}}
              onPaginationChange={setPagination}
              manualPagination
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              rowCount={orders_data.data?.count}
              data={orders_data.data?.data || []}
              enableStickyHeader
              enableGlobalFilter={false}
            />
          </Box>
        </Box>
      </Container>

      <AssignDeliveryModal
        orderId={selectedProduct.id}
        delivery={selectedProduct.delivery}
        opened={deliveryAssignModalVisible}
        onClose={() => {
          setDeliveryAssignModalVisible(false);
        }}
      />

      <PackingSlip
        id={raiseId}
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        orders_data={orders_data}
      />

      <OrderStatusModal
        orderId={selectedProduct.id}
        currentStatus={selectedProduct.status}
        opened={statusChangeModalVisible}
        onClose={() => {
          setStatusChangeModalVisible(false);
        }}
      />

      {/* ENDS :: View order modal */}
      <div style={{ display: "none" }}>
        <PrintModal
          cols={[3]}
          title="Orders"
          head={head}
          body={body}
          foots={foot}
          ref={componentRef}
        />
      </div>

      {isMobileScreen ? (
        <>
          <FloatingMenu
            m={5}
            left
            size={50}
            onClick={() => {
              setIsCsv(true);
              printFilter();
            }}
          >
            <IconDownload color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
