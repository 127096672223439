import { joiResolver } from "@hookform/resolvers/joi";
import {
  ActionIcon,
  Box,
  Button,
  Col,
  Divider,
  Flex,
  Grid,
  LoadingOverlay,
  NumberInput,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons";
import { AnimatePresence, motion } from "framer-motion";
import Joi, { number } from "joi";
import { MantineReactTable } from "mantine-react-table";
import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";

import { image_url } from "../../../config";
import {
  api_addBatch,
  api_deleteBatch,
  api_editBatch,
  api_getBatch,
  api_getUnits,
} from "../../../services/product.service";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";
import AddBatch from "./AddBatch";

/**
 *
 * @param {{ product : Object }} props
 */


const confirm_delete_props = {
  title: "Please confirm delete batch",
  children: (
    <Text size="sm">
      Are you sure you want to delete this batch ? Everything related to this batch will be
      deleted.
    </Text>
  ),
  labels: { confirm: "Delete Batch", cancel: "Cancel" },
  onCancel: () => console.log("Cancel"),
  confirmProps: { color: "red" },
};

export default function BatchMaster(props) {
  const tableInstanceRef = useRef(null);
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [formVisible, setFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [batch, setBatch] = useState(false);
  const [editingBatch, setEditingBatch] = useState(null);
  const [editUnitId, setEditUnitId] = useState(null);
  // const [productId, setProductId] = useState(null);
  // END : States

  const requiredValidationMessage = fieldName => {
    return {
      "string.empty": fieldName + " is Required",
    };
  };

  const units_data = useQuery("units", api_getUnits, {
    staleTime: Infinity,
  });
  const batch_data = useQuery(["batches", props.product.id], () => api_getBatch(props.product.id), {
    staleTime: Infinity,
  });

  const columns = useMemo(
    () => [
      {
        header: "Batch Code",
        accessorKey: "batch_no",
        size: "auto",
      },
      {
        header: "Mfd. Date",
        accessorKey: "mfd",
        size: "auto",
      },
      {
        header: "Exp. Date",
        accessorKey: "exp_date",
        size: "auto",
      },
      {
        header: "Applicable Date",
        accessorKey: "applicable_date",
        size: "auto",
      },
      {
        header: "MRP",
        accessorKey: "mrp",
        size: "auto",
      },
      {
        header: "Selling Price",
        accessorKey: "selling_price",
        size: "auto",
      },
      {
        header: "Tally Status",
        accessorKey: "tally_status",
        size: "auto",
        Cell: ({ cell }) => <span>{cell.getValue() ? "Yes" : "No"}</span>,
      },

      {
        header: "Stock",
        accessorKey: "stock",
        size: "auto",
      },
    ],
    []
  );

  const deleteBatch = (id) => {
    console.log(id);
  }

  const openDeleteConfirmation = id => {
    openConfirmModal({
      ...confirm_delete_props,
      onConfirm: async () => await deleteBatch(id),
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay visible={batch_data.isLoading} />
      <Box bg={"white"}>
        <Grid py={10} mb={10}>
          <Col span={isMobileScreen ? 3 : 1}>
            <Flex h={"100%"} w={"100%"} justify={"center"} align={"center"}>
              <Box
                sx={{
                  overflow: "hidden",
                  border: "3px solid #eee",
                  borderRadius: 100,
                  width: 40,
                  height: 40,
                }}
              >
                <img src={image_url + props.product.thumbnail} width={"100%"} alt="" />
              </Box>
            </Flex>
          </Col>
          <Col span={isMobileScreen ? 6 : 4}>
            <Flex h={"100%"} direction={"column"} justify="center">
              <Text size={"xs"} fw={600}>
                Product Name
              </Text>
              <Text> {props.product.title}</Text>
            </Flex>
          </Col>
          <Col span={3}>
            <Flex h={"100%"} direction={"column"} justify="center">
              <Text size={"xs"} fw={600}>
                Category
              </Text>
              <Text> {props.product.category}</Text>
            </Flex>
          </Col>
          <Col span={isMobileScreen ? 6 : 3}>
            <Flex h={"100%"} direction={"column"} justify="center">
              <Button variant={batch ? "outline" : "filled"} onClick={() => { setBatch(e => !e); setIsEditing(false); }}>{batch ? "Cancel" : "Add Batch"}</Button>
            </Flex>
          </Col>
          <Col span={12}>
            <AddBatch product={props.product} editingBatch={editingBatch} batch={batch} setBatch={setBatch} isEditing={isEditing} setIsEditing={setIsEditing} />
          </Col>
        </Grid>

        {!batch ? <Box>
          <MantineReactTable
            mantineTableProps={{ className: "datatable", striped: true }}
            enableRowActions
            positionActionsColumn="last"
            mantineTableBodyCellProps={{ style: { border: "1px solid #eee" } }}
            tableInstanceRef={tableInstanceRef}
            enableHiding={false}
            mantineTableBodyRowProps={{ className: "datatable-row" }}
            renderTopToolbarCustomActions={({ row }) => (
              <Box px={24} py={10} w={"100%"}>
                <Text
                  fw={600}
                  tt="uppercase"
                  size={"sm"}
                  sx={theme => ({
                    letterSpacing: 1,
                    color: theme.colors.brand[7],
                  })}
                >
                  Batches List
                </Text>
              </Box>
            )}
            renderRowActions={({ row }) => (
              <Flex>
                <Tooltip label="Edit Batch">
                  <ActionIcon
                    ml={10}
                    sx={theme => ({ color: theme.colors.brand[7] })}
                    onClick={() => {
                      setIsEditing(true)
                      setEditingBatch(row.original);
                      setBatch(true);
                    }}
                  >
                    <IconEdit style={{ width: 20 }} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete Batch">
                  <ActionIcon
                    sx={theme => ({ color: theme.colors.red[6] })}
                    ml={10}
                    onClick={() => {
                      openDeleteConfirmation(row.original.id);
                    }}
                  >
                    <IconTrash style={{ width: 20 }} />
                  </ActionIcon>
                </Tooltip>
              </Flex>
            )}
            initialState={{ density: "xs" }}
            state={{ isLoading: loading }}
            enableBottomToolbar={false}
            columns={columns}
            enableColumnFilters={false}
            enableFilters={false}
            enableRowNumbers={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableColumnActions={false}
            data={batch_data.data?.data || []}
            enableStickyHeader
            enableGlobalFilter={false} //turn off a feature
          />
        </Box> : <></>}
      </Box>
    </div>
  );
}
