import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Container,
  Flex,
  Group,
  Paper,
  PasswordInput,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAlertCircle } from "@tabler/icons";
import { useState } from "react";
import { useNavigate } from "react-router";

import { api_login } from "../../services/auth.service";

export function Login() {
  const navigate = useNavigate();

  // Starts : States
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const [role, setRole] = useState("counter_admin");
  // ENDS : States

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },

    validate: {
      username: value => (value ? null : "Username is required"),
      password: value => (value ? null : "Password is required"),
    },
  });

  const doLogin = async () => {
    setLoading(true);
    console.log(form.values);
    await api_login({ ...form.values, role: role })
      .then(res => {
        setLoading(false);
        console.log(res);
        if (res.success) {
          localStorage.setItem("dms_access_token", res.token);
          let permissions = res.user.user_permissions.map(e => {
            return e.codename;
          });
          let group_permissions = res.user.groups.flatMap(e => e.permissions);
          let g2 = group_permissions.map(e => e.codename);

          console.log("raw permissions", permissions);
          console.log("flat groups", group_permissions);
          console.log("g2", g2);

          permissions.push(...g2);

          console.log("final", permissions);

          localStorage.setItem("user", JSON.stringify(res));
          localStorage.setItem("role", role);
          localStorage.setItem("permissions", JSON.stringify(permissions));
          console.log({ permissions: JSON.parse(localStorage.getItem("permissions")) });
          navigate("/");
          window.location.reload();
        } else {
          setLoginError(true);
          setLoginErrorMessage(res.message);
        }
      })
      .catch(err => {
        setLoginError(true);
        setLoginErrorMessage(err.message);
      });
    setLoading(false);
  };

  return (
    <>
      <Flex mih={"680px"} align="center" justify={"center"}>
        <Container w={500}>
          <form onSubmit={form.onSubmit(doLogin)}>
            <Text size={"xs"}> Welcome, </Text>
            <Text fw={600} size="xl">
              Login to DMS.
            </Text>

            <Tabs variant="pills" value={role} onTabChange={setRole} mt={20}>
              <Tabs.List>
                <Tabs.Tab value="counter_admin">Counter Admin</Tabs.Tab>
                <Tabs.Tab value="manager">Manager</Tabs.Tab>
                <Tabs.Tab value="delivery">Delivery</Tabs.Tab>
              </Tabs.List>
            </Tabs>

            {loginError ? (
              <Alert mt={20} icon={<IconAlertCircle size={16} />} title="Error!" color="red">
                {loginErrorMessage}
              </Alert>
            ) : (
              ""
            )}

            <Paper withBorder shadow="md" p={30} mt={20} radius="md">
              <TextInput
                label="Username"
                placeholder="Enter Your Username"
                {...form.getInputProps("username")}
              />
              <PasswordInput
                label="Password"
                placeholder="Enter Your password"
                mt="md"
                {...form.getInputProps("password")}
              />
              <Group position="apart" mt="lg">
                <Anchor onClick={event => event.preventDefault()} href="#" size="sm">
                  Forgot password?
                </Anchor>
              </Group>
              <Button loading={loading} type="submit" fullWidth mt="xl">
                Sign in
              </Button>
            </Paper>
          </form>
        </Container>
      </Flex>
    </>
  );
}
