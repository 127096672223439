import {
  Box,
  Button,
  Col,
  Container,
  Flex,
  Grid,
  Image,
  LoadingOverlay,
  Table,
  Text,
} from "@mantine/core";
import { IconBox, IconPlus, IconPrinter, IconTruckDelivery, IconUser } from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import { image_url } from "config";
import moment from "moment";
import { counters_api } from "pages/master/counter/counter.service";
import { getAlteredCounters } from "pages/master/customers/helperFunctions";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { stock_apis } from "./stock-transfer.service";

export default function StockTransferPrint() {
  const [searchParams] = useSearchParams();
  const [stock, setStock] = useState({});
  const [countersList, setCountersList] = useState([]);

  const counter_data = useQuery({
    queryKey: ["counters-data-list"],
    queryFn: () => counters_api.get(),
    onSuccess: data => {
      setCountersList(getAlteredCounters(data.data));
    },
  });

  const stock_data = useQuery(
    ["stock_data", searchParams.get("id")],
    () => stock_apis.get_stock_transfer_details(searchParams.get("id")),
    {
      onSuccess: data => {
        setStock(data.data);
      },
    }
  );

  const Header = () => {
    return (
      <>
        {/* <thead> */}
        <Box bg={"white"} pr={20} pl={20} pt={20}>
          <Grid mb={20}>
            <Col span={6}>
              <Flex align={"center"}>
                <IconBox size={20} />
                <Text fw={"bold"} ml={8}>
                  Stock From
                </Text>
              </Flex>

              <Table withBorder striped mt={15}>
                <tbody>
                  <tr>
                    <td width={160}>
                      <Text opacity={0.5}>Counter Id</Text>
                    </td>
                    <td> {stock?.from_counter} </td>
                  </tr>
                  <tr>
                    <td>
                      <Text opacity={0.5}> Counter Name </Text>
                    </td>
                    <td>{stock?.from_counter_name} </td>
                  </tr>
                  <tr>
                    <td>
                      <Text opacity={0.5}> Counter Address </Text>
                    </td>
                    <td>
                      {
                        counter_data?.data?.data?.find((e, i) => e.id === stock?.from_counter)
                          ?.address
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text opacity={0.5}> Counter GST </Text>
                    </td>
                    <td>
                      {counter_data?.data?.data?.find((e, i) => e.id === stock?.from_counter)?.gst}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col span={6}>
              <Flex align={"center"}>
                <IconUser size={20} />
                <Text fw={"bold"} ml={8}>
                  Stock To
                </Text>
              </Flex>

              <Table withBorder striped mt={15}>
                <tbody>
                  <tr>
                    <td width={160}>
                      <Text opacity={0.5}>Counter Id</Text>
                    </td>
                    <td> {stock?.to_counter} </td>
                  </tr>
                  <tr>
                    <td>
                      <Text opacity={0.5}> Counter Name </Text>
                    </td>
                    <td>{stock?.to_counter_name} </td>
                  </tr>
                  <tr>
                    <td>
                      <Text opacity={0.5}> Counter Address </Text>
                    </td>
                    <td>
                      {
                        counter_data?.data?.data?.find((e, i) => e.id === stock?.to_counter)
                          ?.address
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text opacity={0.5}> Counter GST </Text>
                    </td>
                    <td>
                      {counter_data?.data?.data?.find((e, i) => e.id === stock?.to_counter)?.gst}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Grid>
        </Box>
        {/* </thead> */}
      </>
    );
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @page {
      @bottom-left {
        content: counter(page) ' of ' counter(pages);
      }
    }
    `,
  });

  useEffect(() => {
    setTimeout(() => {
      handlePrint();
    }, 2000);
  }, []);

  const Body = () => {
    return (
      <>
        <Header />
        <Box px={10} pos={"relative"}>
          <Grid mb={20}>
            <Col span={12}>
              <Text size={"sm"} mb={15}>
                Products Details
              </Text>
              <Table withBorder withColumnBorders striped>
                <thead>
                  <tr>
                    <th> No. </th>
                    <th> Product </th>
                    <th> Batch </th>
                    <th> MRP </th>
                    <th> Price </th>
                    <th> Qty. </th>
                    <th> Total Amount </th>
                  </tr>
                </thead>
                <tbody>
                  {stock.stock_list?.map((e, index) => (
                    <>
                      <tr>
                        <td width={50}> {index + 1} </td>
                        <td>{e.product}</td>
                        <td>{e.batch_no}</td>
                        <td> ₹ {e.mrp} </td>
                        <td> ₹ {e.selling_price} </td>
                        <td> {e.stock} </td>
                        <td> ₹ {(e.stock * e.selling_price).toFixed(2)} </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col span={6}>
              <Text size={"sm"} mb={15}>
                Authorized Signature
              </Text>
              <Table withBorder>
                <tbody>
                  <tr style={{ height: "60px" }}>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Grid>
        </Box>
      </>
    );
  };

  const PrintOrderPage = () => {
    return (
      <Box sx={{ borderRadius: 2 }} mb={25}>
        <Box bg={"white"}>
          <Container size={"xl"} pos={"relative"}>
            <LoadingOverlay
              visible={stock_data.isLoading || stock_data.isRefetching}
              overlayBlur={5}
            />
            <Box>
              <Table>
                <Body />
              </Table>
            </Box>
          </Container>
        </Box>
      </Box>
    );
  };

  // const pages = [];
  // const num_of_items_per_page = 10;
  // const num_of_pages = parseInt(stock?.stock_list?.length / num_of_items_per_page + 1);
  // for (let i = 0; i < num_of_pages; i++) {
  //   let itemStart = i * num_of_items_per_page;
  //   let itemEnd = itemStart + num_of_items_per_page;
  //   pages.push(PrintOrderPage(itemStart, itemEnd));
  // }

  return (
    <div>
      <Container fluid>
        <Box p={25}>
          <Flex justify={"space-between"} align="end">
            <Box>
              <Text size={"lg"} fw={600} mb={10}>
                Print Stock
              </Text>
              <AppBreadcrumb />
            </Box>
            <Box>
              <Button
                leftIcon={<IconPrinter size={16} />}
                onClick={() => {
                  handlePrint();
                }}
              >
                Print Stock
              </Button>
            </Box>
          </Flex>
        </Box>
      </Container>

      <Container fluid ref={componentRef}>
        {/* page1 */}
        <>
          <Box m={20}>
            <Text fw={600} color="black">
              Stock ID: {searchParams.get("id")}
            </Text>
          </Box>
          <PrintOrderPage />
        </>
      </Container>
    </div>
  );
}
