import { httpClient } from "../../../lib/axios";

export const coupons_api = {
  get: async () => {
    return await httpClient
      .get("coupon/")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  add: async payload => {
    return await httpClient
      .post("coupon/", payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  edit: async (id, payload) => {
    return await httpClient
      .put(`coupon/?coupon_id=${id}`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  changeStatus: async payload => {
    return await httpClient
      .put("coupon/?status=true", payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  delete: async id => {
    return await httpClient
      .delete(`coupon/?coupon_id=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  bulkDelete: async payload => {
    return await httpClient
      .delete(`review/?delete=true`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
};
