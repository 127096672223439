import { httpClient } from '../../../lib/axios';

export const feedback_api = {
  get: async (pageSize,pageIndex) => {
    return await httpClient
      .get(`feedback/?page_size=${pageSize}&page_number=${pageIndex}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  changeStatus: async (payload) => {
    return await httpClient
      .put('feedback/?status=true', payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  delete: async (feedback_id) => {
    return await httpClient
      .delete(`feedback/?feedback_id=${feedback_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};
