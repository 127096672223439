import {
  Alert,
  Anchor,
  Avatar,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  Table,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useForceUpdate, useMediaQuery } from "@mantine/hooks";
import { IconAlertCircle } from "@tabler/icons";
import AddPaymentReceipt from "components/AddPaymentReceipt";
import CouponsModal from "components/CouponsModal";
import CustomerSelection from "components/CustomerSelection";
import produce, { current } from "immer";
import { coupons_api } from "pages/master/coupons/coupons.service";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { constructInvoiceItem, getModifiedList } from "./InvoiceHelperFunctions";
import { invoice_api } from "./invoice.service";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";

export default function ViewInvoice() {
  const batchTable = React.useRef("");
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // STARTS : States
  const [editFormVisible, setEditFormVisible] = useState(false);
  const [productSelectionModal, setProductSelectionModal] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [deletedItems, setDeletedItems] = useState([]);

  // const [selectedBatchProductCode, setSelectedBatchProductCode] = useState(null);
  const [modalBatches, setModalBatches] = useState([]);
  const [currentBatch, setCurrentBatch] = useState({});
  // ENDS : States

  // Invoice states
  const [invoiceItemsList, setInvoiceItemsList] = useState([]);
  const [batches, setBatches] = useState([]);
  const [netDiscount, setNetDiscount] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [invoiceTotal, setInvoiceTotal] = useState(null);
  // Ends : Invoice states

  const [focusSearch, setfocusSearch] = useState(false);

  // STARTS : States

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [paymentReceiptFormVisible, setPaymentReceiptFormVisible] = useState(false);
  const [invoicesData, setInvoicesData] = useState([]);
  const [invoicesId, setInvoicesId] = useState(null);
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState("");

  const [couponsModalOpened, setCouponsModalOpened] = useState(false);
  const [couponCode, setCouponCode] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [couponApplied, setCouponApplied] = useState({});
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState([]);
  // ENDS : States

  const [invoiceItems, setInvoiceItems] = useState([]);

  //
  const [paymentReceiptData, setPaymentReceiptData] = useState({});

  const coupons_data = useQuery("coupons", coupons_api.get, {
    onSuccess: data => setCoupons(data.data),
  });

  const form = useForm({
    initialValues: {
      route: "",
      agents: "",
      start_date: "",
      end_date: "",
      date: new Date(),
    },
    validate: {
      agent: value => (value ? null : "Agent is required"),
      date: value => (value ? null : "Date is required"),
    },
  });

  const updateQty = (batchCode, data) => {
    const current_item = invoiceItemsList.find(item => item.batch_code === batchCode);
    console.log("Current modifying item :", current_item);

    const is_qty_overflow = data > current_item.selected_batch.total_stock;
    let qty_to_fill;

    setInvoiceItemsList(
      produce(draft => {
        const item = draft.find(item => item.batch_code === batchCode);
        const batchIndex = item.batches.indexOf(item.batches.find(e => e.batch_code === batchCode));

        item.qty = data;

        const is_qty_overflow = data > item.selected_batch.total_stock;

        // 2. If qty is overflowed, pass remaining to handlebatches function and empty the batch stock
        if (is_qty_overflow) {
          console.info(
            `Qty is overflowed, Entered qty : ${data} & available qty : ${item.selected_batch.stock}`
          );

          item.entered_qty = item.selected_batch.total_stock;
          item.qty = item.selected_batch.total_stock;

          // item.batches[batchIndex].stock = 0;
          updateBatch(item.product_code, batchIndex, 0);
          item.selected_batch.stock = data > item.selected_batch.total_stock ? 0 : data;

          qty_to_fill = data - item.selected_batch.total_stock;
        } else {
          item.qty = +data;
          item.entered_qty = +data;
          // item.basic_amount;
          updateBatch(item.product_code, batchIndex, +item.selected_batch.total_stock - data);
          // console.log(item.product_code, batchIndex, +item.selected_batch.total_stock - data);
          item.selected_batch.stock = item.selected_batch.total_stock - data;
        }
      })
    );

    if (is_qty_overflow) {
      handleBatches(current_item.product_code, batchCode, qty_to_fill);
    }
  };

  const updateModalBatches = product_code => {
    setModalBatches(batches.find(e => e.product_code === product_code).batches);
  };

  const updateBatchInList = newBatch => {
    setInvoiceItemsList(
      produce(draft => {
        const selectedInvoiceItem = draft.find(e => e.batch_code === currentBatch.batch_code);

        console.log(selectedInvoiceItem);

        selectedInvoiceItem.selected_batch = newBatch;
        selectedInvoiceItem.batch_code = newBatch.batch_code;
        // selectedInvoiceItem.price = newBatch.price;
        selectedInvoiceItem.qty = 0;
        selectedInvoiceItem.total = 0;
        selectedInvoiceItem.total_tax = 0;
        selectedInvoiceItem.box = 0;
        selectedInvoiceItem.pieces = 0;
        selectedInvoiceItem.entered_qty = 0;

        updateTotal();
      })
    );
  };

  const checkIfBatchAlreadyExists = batch_code => {
    const found = invoiceItemsList.find(e => e.batch_code === batch_code);
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  const updateBatch = useCallback((product_code, index, data) => {
    console.log({
      batches,
    });
    setBatches(
      produce(draft => {
        let batch = draft.find(i => i.product_code === product_code);
        console.log("bbb", batch);
        batch.batches[index].stock = data;
      })
    );
  }, []);

  const updateTotal = useCallback((itemId, data) => {
    setInvoiceItemsList(
      produce(draft => {
        for (let item of draft) {
          item.basic_amount = item.price * item.qty;

          const tax = +((+item.basic_amount - +item.discount) * (+item.tax / 100)).toFixed(2);
          console.log("tax", tax);

          console.table({
            basic_amount: item.basic_amount,
            discount: item.discount,
            tax_percentage: item.tax,
            tax: tax,
          });

          item.tax_total = tax;
          item.total = item.price * item.qty - item.discount;
        }
      })

      // Ref : https://tallysolutions.com/gst/tax-value-calculation-when-price-is-inclusive-of-gst/
    );
  }, []);

  const resetInvoiceItem = useCallback(itemId => {
    setInvoiceItemsList(draft => {
      const item = draft.find(item => item.id === itemId);
      item.entered_qty = 0;
      item.qty = 0;
      item.total = 0;
      item.tax_total = 0;
    });
  }, []);

  const updateInvoiceItem = useCallback((type, itemId, data) => {
    // Function for updating static values in invoice items which doesn't require any calculations
    setInvoiceItemsList(draft => {
      const item = draft.find(item => item.id === itemId);
      console.log(item);
      switch (type) {
        case "discount":
          item.discount = data;
          break;
        case "free_samples":
          item.free_samples = data;
          break;
        default:
          break;
      }
    });
  }, []);

  const getTotal = type => {
    let total_amount = 0;
    let tax_total = 0;
    let boxes = 0;
    let pieces = 0;
    let free_samples = 0;
    let discount = 0;
    let subtotal = 0;
    let coupon = 0;

    for (let item of invoiceItemsList) {
      total_amount += item.total;
      tax_total += item.tax_total;
      boxes += item.box;
      pieces += item.pieces;
      free_samples += item.free_samples;
      discount += item.discount;
      subtotal += item.basic_amount;
    }
    if (couponApplied !== undefined)
      coupon =
        couponApplied.coupon_type === "Flat"
          ? Number(total_amount * (couponApplied.value / 100)).toFixed(2)
          : Number(couponApplied.value).toFixed(2);
    coupon = isNaN(coupon) ? 0 : coupon;
    total_amount -= coupon;

    switch (type) {
      case "amount":
        return total_amount;
      case "subtotal":
        return subtotal;
      case "discount":
        return discount;
      case "coupon":
        return coupon;
      case "tax":
        return tax_total;
      case "boxes":
        return boxes;
      case "pieces":
        return pieces;
      case "free_samples":
        return free_samples;
      default:
        break;
    }
  };

  const updateInvoiceItemData = (batchCode, qty, stock) => {
    setInvoiceItemsList(
      produce(draft => {
        let product = draft.find(e => e.batch_code === batchCode);
        console.log("Product changing");
        product.entered_qty += +qty;
        product.qty += +qty;
        product.selected_batch.stock = +stock;
      })
    );
  };

  const updateDiscount = (batchCode, discountPercentage) => {
    console.log({
      batchCode,
      discountPercentage,
    });
    setInvoiceItemsList(
      produce(draft => {
        let product = draft.find(e => e.batch_code === batchCode);
        product.discountPercentage = +discountPercentage;

        const discount = product.basic_amount * (+discountPercentage / 100);
        product.discount = +discount.toFixed(2);

        console.log(discount);

        // product.selected_batch.stock = +stock;
      })
    );
  };

  const applyNetDiscount = () => {
    for (let item of invoiceItemsList) {
      updateDiscount(item.batch_code, netDiscount);
    }
    updateTotal();
  };

  const applyCoupon = set => {
    console.log(couponCode.coupon_code);
    if (set) {
      let coupon = coupons.find(value => value.coupon_code === couponCode.coupon_code);
      console.log(coupon);
      setCouponApplied(coupon);
    } else {
      setCouponApplied({});
    }
    console.log(couponApplied);
  };

  const handleBatches = (productCode, batchCode, total_stock_needed) => {
    console.log(batchCode);

    setBatches(
      produce(draft => {
        const product = invoiceItemsList.find(e => e.product_code === productCode);
        const batches = draft.find(e => e.product_code === productCode).batches;

        const is_batch_already_present = code => {
          const found = invoiceItemsList.find(e => e.batch_code === code);
          if (found) {
            return true;
          } else {
            return false;
          }
        };

        console.log(current(batches));
        console.log({ product });

        let fulfilled_stock = 0;
        const selected_batches = [];

        for (let batch of batches) {
          let needed_stock = total_stock_needed - fulfilled_stock;

          // Do nothing if fulfilled
          if (fulfilled_stock >= total_stock_needed) {
          } else {
            if (batch.stock > 0) {
              if (batch.stock > needed_stock) {
                console.table({
                  condition: "Batch has more stock than needed",
                  "Batch Code": batch.batch_code,
                  "Batch Stock": batch.stock,
                  "Needed stock": needed_stock,
                  "Stock will remain": batch.stock - needed_stock,
                });

                // Fulfilling needed stock only & setting updated stock in batch
                fulfilled_stock += needed_stock;
                batch.stock = batch.stock - needed_stock;

                if (is_batch_already_present(batch.batch_code)) {
                  // Modifying same item in list
                  updateInvoiceItemData(
                    batch.batch_code,
                    needed_stock,
                    batch.total_stock - needed_stock
                  );
                } else {
                  // Pushing product to add to list
                  selected_batches.push({
                    ...product,
                    deleted: false,
                    id: null,
                    batch_code: batch.batch_code,
                    selected_batch: {
                      ...batch,
                      stock: batch.total_stock - needed_stock,
                    },
                    qty: needed_stock,
                    entered_qty: needed_stock,
                  });
                }
              } else {
                console.table({
                  condition: "Batch has less stock than needed",
                  "Batch Code": batch.batch_code,
                  "Batch Total Stock": batch.total_stock,
                  "Batch Stock": batch.stock,
                  "Needed stock": needed_stock,
                  "Stock will remain": 0,
                });

                const current_stock = batch.stock;

                // Fulfilling full batch's stock only & setting updated stock in batch
                fulfilled_stock += current_stock;
                batch.stock = 0;

                if (is_batch_already_present(batch.batch_code)) {
                  // Modifying same item in list
                  updateInvoiceItemData(batch.batch_code, current_stock, 0);
                } else {
                  // Pushing product to add to list
                  selected_batches.push({
                    ...product,
                    deleted: false,
                    id: null,
                    batch_code: batch.batch_code,
                    selected_batch: {
                      ...batch,
                      stock: 0,
                    },
                    qty: current_stock,
                    entered_qty: current_stock,
                  });
                }
              }
            }
          }
        }

        if (selected_batches) {
          for (let i of selected_batches) {
            addInvoiceItem(i);
            updateTotal();
          }
        }
      })
    );
  };

  const check_item_duplication = batchCode => {
    let already_exists = invoiceItemsList.find(e => e.batch_code === batchCode);
    if (already_exists) {
      return true;
    } else {
      return false;
    }
  };

  const addInvoiceItem = useCallback(invoiceItem => {
    setInvoiceItemsList(
      produce(draft => {
        //invoiceItem.deleted = false;
        draft.push(invoiceItem);
      })
    );
  }, []);

  const handleAddInvoiceItem = batchCode => {
    const itemToAdd = productsList.find(e => e.batch_code === batchCode);
    console.log("Invoice item to be added :", itemToAdd);
    itemToAdd.deleted = false;
    if (check_item_duplication(itemToAdd.batch_code)) {
      showErrorToast({
        title: "Error",
        message: "Same invoice item already present in the list.",
      });
    } else {
      addInvoiceItem(constructInvoiceItem(itemToAdd));
    }
  };

  // Deleting Invoice item
  const deleteInvoiceItem = useCallback(id => {
    console.log("i am invoice items list", invoiceItemsList);
    setInvoiceItemsList(
      produce(draft => {
        const item = draft.find(item => item.batch_code === id);
        const copy = current(item);
        console.log("copy -->", copy);
        const index = draft.findIndex(item => item.batch_code === id);
        console.log("Delete function :: Copy product", copy);

        // Resetting the stock
        setBatches(
          produce(draft => {
            const product = draft.find(e => e.product_code === copy.product_code);

            console.log("product is -->", current(product));

            let current_batch = product.batches.find(
              x => x.batch_code === copy.selected_batch.batch_code
            );
            const batchIndex = product.batches.indexOf(current_batch);

            console.log("Delete Function :: batch", current(current_batch));
            product.batches[batchIndex].stock = product.batches[batchIndex].total_stock;
          })
        );

        // Deleting item
        if (index !== -1) {
          draft.splice(index, 1);

          if (item.id) {
            console.log("delete invoice item id -->", item.id);

            setDeletedItems(
              produce(draft => {
                draft.push(item.id);
              })
            );
          }
        }
      })
    );
  }, []);

  const submitInvoice = useMutation({
    mutationFn: data => invoice_api.submit_invoice(data),
    onSuccess: data => {
      console.log(data.data.data);
      if (data.data.success) {
        setInvoicesId(data.data.data);
        console.log(data.data);
        setPaymentReceiptData({
          invoice: data.data.data.id,
          invoice_id: data.data.data.invoice_id,
          total: +getTotal("amount").toFixed(2),
        });
        showSuccessToast({ title: "Success", message: data.data?.message });
        setPaymentReceiptFormVisible(true);
      } else {
        showErrorToast({ title: "Warning", message: data.data?.message });
      }
    },
    onError: () => {
      showErrorToast({ title: "Error", message: "Error submitting invoice." });
    },
  });

  const editInvoice = useMutation({
    mutationFn: data => invoice_api.edit_invoice(data),
    onSuccess: data => {
      console.log(data);
      if (data.data.success) {
        showSuccessToast({ title: "Success", message: data.data?.message });
        navigate("/invoices");
      } else {
        showErrorToast({ title: "Warning", message: data.data?.message });
      }
    },
    onError: () => {
      showErrorToast({ title: "Error", message: "Error editting invoice." });
    },
  });

  const saveInvoice = () => {
    let coupon =
      couponApplied.coupon_type === "Flat"
        ? Number(getTotal("amount") * (couponApplied.value / 100)).toFixed(2)
        : Number(couponApplied.value).toFixed(2);
    coupon = isNaN(coupon) ? 0 : parseFloat(coupon).toFixed(2);

    const invoice_items = invoiceItemsList.map(e => {
      return {
        id: e.id,
        counter_stock_id: e.counter_stock_id,
        counter_stock_batch_id: e.selected_batch.id,
        qty: e.qty,
        rate: e.price,
        amount: e.total,
        discount: e.discountPercentage,
        discount_amount: e.discount + Number(coupon),
        tax: e.tax,
        tax_amount: e.tax_total,
      };
    });

    console.log({ invoice_items });

    let payload = {};

    if (couponApplied.id) {
      payload = {
        invoice: {
          sub_total: +getTotal("subtotal"),
          tax_total: +getTotal("tax").toFixed(2),
          discount_total: +getTotal("discount"),
          total: +getTotal("amount"),
        },
        customer_id: customerId,
        invoice_item: invoice_items,
        deleted_array: deletedItems,
        coupon_id: couponApplied.id ? couponApplied.id : null,
        coupon_amount: Number(coupon),
        discount_total: Number(getTotal("discount")),
      };
    } else {
      payload = {
        invoice: {
          sub_total: +getTotal("subtotal"),
          tax_total: +getTotal("tax").toFixed(2),
          discount_total: +getTotal("discount"),
          total: +getTotal("amount"),
        },
        customer_id: customerId,
        invoice_item: invoice_items,
        deleted_array: deletedItems,
        coupon_amount: Number(coupon),
        discount_total: Number(getTotal("discount")),
      };
    }

    if (invoiceItemsList.length > 0 && customerId) {
      if (isEditing) {
        let p = payload;
        // p.id = searchParams.get("id");
        p.invoice.id = searchParams.get("id");
        editInvoice.mutate(payload);
      } else {
        setInvoiceTotal(payload.invoice.total);

        submitInvoice.mutate(payload);
      }
    } else {
      showErrorToast({ title: "Error", message: "Select customer / Please add invoice items." });
    }
  };

  // Fetching & Setting the data
  const invoice_items = useQuery("invoice-items-list", invoice_api.get_items, {
    onSuccess: data => {
      console.log("API Response ->", data.data);
      const modifiedData = data.data.map(e => {
        return {
          counter_stock_id: e.id,
          product_code: e.product,
          product_name: e.title,
          tax: e.tax || 0,
          price: e.selling_price,
          batches: e.counter_batch.map(x => {
            return {
              id: x.id,
              batch_code: x.batch_no,
              expiry_date: x.exp_date,
              stock: x.stock,
              total_stock: x.stock,
            };
          }),
        };
      });
      setProductsList(getModifiedList(modifiedData));
      setBatches(
        modifiedData.map(e => {
          return {
            product_code: e.product_code,
            batches: e.batches,
          };
        })
      );

      // Edit functionalities

      if (window.location.pathname.includes("/view")) {
        setIsEditing(true);
        invoice_api.get_invoice_by_id(searchParams.get("id")).then(res => {
          const data = res.data.data;

          const products_list = getModifiedList(modifiedData);

          setInvoiceItems(data.invoice_item);

          setCustomerId(data.user);

          for (let item of data.invoice_item) {
            console.log("I am an item here ->", item);

            const product = products_list.find(e => e.product_code === item.product);
          }
        });
      }
    },
  });

  // console.log("invoice_items", invoice_items);

  const SelectItem = forwardRef(({ image, label, description, ...others }, ref) => (
    <div {...others}>
      <Group noWrap>
        <Avatar src={image} />
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  ));

  // Customer selection component validation
  const customer_ref = useRef(null);

  // // // // // // // // // // // // // // //
  const handleCustomerSelection = value => {
    // setMobileNumberErrorMessage(null);
    // console.log(value);
    // if (value) {
    //   setCustomerId(value);
    //   const customer = customers.filter(cus => cus.id === value);
    //   console.log(customer);
    //   setCustomerSelected(customer);
    //   setTimeout(() => {
    //     customer_ref.current?.blur();
    //   }, 100);
    //   setTimeout(() => {
    //     handleQtyOnFocus();
    //   }, 150);
    // }
  };
  // // // // // // // // // // // // // // //

  // Printing
  const navigateToPrint = () => {
    const invoice_items = invoiceItemsList.map(e => {
      return {
        id: e.id,
        counter_stock_id: e.selected_batch.id,
        counter_stock_batch_id: e.selected_batch.id,
        qty: e.qty,
        rate: e.price,
        amount: e.total,
        discount_amount: e.discount,
        tax: e.tax,
        tax_amount: e.tax_total,
      };
    });

    const payload = {
      invoice: {
        sub_total: +getTotal("subtotal"),
        tax_total: +getTotal("tax").toFixed(2),
        discount_total: +getTotal("discount"),
        total: +getTotal("amount"),
      },
      customer_id: customerId,
      invoice_item: invoice_items,
    };
    navigate("/invoices/print", {
      state: payload,
    });
  };

  const qty_ref = useRef(null);
  const handleQtyOnFocus = () => {
    qty_ref.current?.focus();
  };

  const lastRef = useRef(null);

  useEffect(() => {
    if (lastRef.current) lastRef.current.focus();
  }, [invoiceItemsList.length]);

  return (
    <>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={25} pb={15}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  View Invoice
                </Text>
                <Breadcrumbs separator="/" sx={{ fontSize: 14 }}>
                  <Anchor href="/">Home</Anchor>
                  <Anchor href="/">Transactions</Anchor>
                  <Anchor>View Invoice</Anchor>
                </Breadcrumbs>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid>
        <Box className="content-container" p={isMobileScreen ? 5 : 20} sx={{ borderRadius: 2 }}>
          {/* Prepare Invoice Form */}
          <Grid>
            <Col span={12}>
              <form onSubmit={form.onSubmit(values => console.log(values))}>
                <Box
                  sx={{
                    borderRadius: 2,
                    background: "white",
                    padding: 20,
                  }}
                >
                  <Grid>
                    <Col span={isMobileScreen ? 12 : 6}>
                      <CustomerSelection
                        disabled={true}
                        reference={customer_ref}
                        id={customerId}
                        onChange={handleCustomerSelection}
                        error={mobileNumberErrorMessage}
                        setError={setMobileNumberErrorMessage}
                        setCustomers={setCustomers}
                      />
                    </Col>
                    <Col span={isMobileScreen ? 12 : 6}>
                      <DatePickerInput
                        disabled
                        placeholder="Select Invoice date"
                        label="Invoice Date"
                        data={[]}
                        {...form.getInputProps("date")}
                      ></DatePickerInput>
                    </Col>

                    <Col span={12}>
                      {customerSelected[0] ? (
                        <Grid>
                          <Col span={3}>
                            <Text fw={500} size={"xs"}>
                              Username
                            </Text>
                            <Text c="dimmed">{customerSelected[0]?.username}</Text>
                          </Col>
                          <Col span={3}>
                            <Text fw={500} size={"xs"}>
                              First Name
                            </Text>
                            <Text c="dimmed">{customerSelected[0]?.first_name}</Text>
                          </Col>
                          <Col span={3}>
                            <Text fw={500} size={"xs"}>
                              Email
                            </Text>
                            <Text c="dimmed">{customerSelected[0]?.email}</Text>
                          </Col>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Grid>
                </Box>
              </form>
            </Col>
          </Grid>

          {/* Invoice Table */}
          <Grid mt={25} columns={30}>
            <Col span={30} sx={{ overflow: "auto" }}>
              <Table
                withColumnBorders
                withBorder
                mt={15}
                className="normal-table"
                verticalSpacing={"xs"}
                horizontalSpacing={"sm"}
                sx={theme => ({
                  background: "white",
                  borderRadius: 2,
                  thead: { tr: { background: theme.colors.brand[1] } },
                })}
              >
                <thead>
                  <tr>
                    <th>Product Item</th>
                    <th>Batch</th>
                    <th>Rate</th>
                    <th>Qty</th>

                    <th>Tax</th>
                    <th>Tax Amount</th>

                    <th>Total </th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceItems.map((e, index) => {
                    if (!e.deleted) {
                      return (
                        <tr key={e.batch_code}>
                          <td style={{ width: "200px" }}>{e.title}</td>
                          <td style={{ maxWidth: 140 }}>
                            <Badge
                              sx={{
                                color: "gray",
                                borderColor: "lightgray",
                                cursor: "pointer",
                              }}
                              variant={"outline"}
                              mb={5}
                              onClick={() => {
                                // setCurrentBatch(e.selected_batch);
                                // updateModalBatches(e.product_code, e.entered_qty);
                                // setProductSelectionModal(true);
                              }}
                            >
                              {e.batch_no}
                            </Badge>
                          </td>
                          <td> ₹ {e.rate}</td>
                          <td> {e.qty}</td>

                          {/* <td>
                            <NumberInput
                              type="number"
                              max={e.selected_batch.total_stock}
                              onBlur={val => {
                                updateQty(e.batch_code, val.target.value);
                                updateTotal();
                              }}
                              value={e.entered_qty}
                              defaultValue={e.entered_qty}
                              min={0}
                              size="xs"
                              w={80}
                              ref={index === invoiceItemsList.length - 1 ? lastRef : undefined}
                              onKeyDown={getHotkeyHandler([["Enter", handleQtyOnFocus]])}
                            />
                          </td> */}
                          {/* <td> ₹ {+e.basic_amount?.toFixed(2)}</td> */}

                          <td width={70}>{e.tax} %</td>
                          <td width={120}>
                            <Text size="sm" display="block">
                              ₹ {e.tax_amount?.toFixed(2)}
                            </Text>
                          </td>
                          <td> ₹ {e.amount.toFixed(2)}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
              {invoiceItems.length === 0 && (
                <Alert
                  mt={20}
                  icon={<IconAlertCircle size="1rem" />}
                  title="Invoice list is empty."
                >
                  Click on search box to search invoice item and select it to add to the invoice.
                </Alert>
              )}
            </Col>
          </Grid>
        </Box>

        <Box px={25} sx={{ borderRadius: 2 }}></Box>
      </Container>

      {/* ENDS :: Main content */}

      {/* STARTS : Product Selection Modal */}
      <Modal
        opened={productSelectionModal}
        onClose={() => {
          setProductSelectionModal(false);
        }}
        title={
          <Box px={10}>
            <Text fw={"600"}>Select Batch</Text>
          </Box>
        }
        size={"850px"}
        centered
      >
        <Box pl={10}>
          <Divider />
        </Box>
        <Box px={10} mb={10}>
          <Box mt={20}>
            <Table
              withColumnBorders
              highlightOnHover
              withBorder
              verticalSpacing={"xs"}
              horizontalSpacing={"xs"}
              className="normal-table"
              ref={batchTable}
            >
              <thead>
                <tr>
                  <th> Batch No. </th>
                  <th> Exp. Date </th>
                  <th> Selling Price </th>
                  <th> Discount Price </th>
                  <th> Available Stock</th>
                  <th> Total Stock</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {modalBatches.map(e => {
                  return (
                    <tr
                      key={e.batch_code}
                      tabIndex={0}
                      style={
                        checkIfBatchAlreadyExists(e.batch_code) || e.stock === 0
                          ? {
                              background:
                                "repeating-linear-gradient( 45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.2) 10px, rgba(0, 0, 0, 0.2) 20px)",
                              opacity: ".4",
                            }
                          : {}
                      }
                    >
                      <td>{e.batch_code}</td>
                      <td width={120}> 20/12/2022 </td>
                      <td> ₹ 10,000 </td>
                      <td> ₹ 8,000 </td>
                      <td> {e.stock} </td>
                      <td> {e.total_stock} </td>
                      <td>
                        <Button
                          onClick={() => {
                            updateBatchInList(e);
                            setProductSelectionModal(false);
                          }}
                          size="xs"
                          disabled={e.stock === 0 || checkIfBatchAlreadyExists(e.batch_code)}
                        >
                          Select This Batch
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>
        </Box>
      </Modal>
      {/* ENDS : Product Selection Modal */}

      {paymentReceiptFormVisible && (
        <AddPaymentReceipt
          opened={paymentReceiptFormVisible}
          fromInvoice={true}
          onClose={() => {
            setPaymentReceiptFormVisible(false);
            navigate("/invoices");
          }}
          defaultData={paymentReceiptData}
          invoiceId={paymentReceiptData?.invoice}
        />
      )}

      <CouponsModal
        modalOpened={couponsModalOpened}
        setModalOpened={setCouponsModalOpened}
        setCouponCode={setCouponCode}
        setCouponApplied={setCouponApplied}
      />
    </>
  );
}
