import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  Flex,
  Grid,
  Modal,
  NumberInput,
  Select,
  Switch,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import {
  IconDownload,
  IconEdit,
  IconEye,
  IconPlus,
  IconPrinter,
  IconStatusChange,
  IconTrash,
} from "@tabler/icons";
import FloatingMenu from "components/FloatingMenu";
import { PrintModal } from "components/PrintModal";
import { MantineReactTable } from "mantine-react-table";
import moment from "moment/moment";
import { getCounterId, hasPermission, isCounterAdmin } from "pages/Auth/permissionGuard";
import { stock_apis } from "pages/Inventory/stock-transfer/stock-transfer.service";
import { counters_api } from "pages/master/counter/counter.service";
import { products_api } from "pages/master/products/products.service";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  getBodyContent,
  getCsvContent,
  getCustomId,
  getFields,
  getFilterPayload,
  getHeaders,
  getTotal,
} from "services/filterHelperFunctions";

import { return_api } from "./return.service";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { api_addUser, api_filter } from "../../../services/master.service";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";

export default function ReturnsMaster() {
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingStatus, setIsEditingStatus] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [viewReturnModalOpened, setViewReturnModalOpened] = useState(false);
  const [returnModalDetails, setReturnModalDetails] = useState({});
  const [returnData, setReturnData] = useState([]);
  // END : States

  // STARTS : States
  const [userFormVisible, setUserFormVisible] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counters, setCounters] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const [returnsData, setReturnsData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [counterStocksData, setCounterStocksData] = useState([]);
  const [productSelected, setProductSelected] = useState(null);
  const [productAmount, setProductAmount] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [filename, setFilename] = useState("file");
  const [isDamage, setIsDamage] = useState(false);

  const counter_data = useQuery({
    queryKey: ["counters-data-list"],
    queryFn: () => counters_api.get(),
    onSuccess: data => {
      setIsDamage(
        data.data.filter(c => c.id === JSON.parse(localStorage.getItem("user"))?.user?.counter)[0]
          ?.damage
      );
    },
  });

  // ENDS : States
  const handleEdit = values => {
    setIsEditing(true);
    console.log(values);
    setEditingId(values.id);
    returnForm.reset();
    console.log(values.batch);
    returnForm.setFieldValue("product", values.product);
    returnForm.setFieldValue("batch", values.batch);
    returnForm.setFieldValue("qty", values.stock);
    returnForm.setFieldValue("reason", values.reason);
    returnForm.setFieldValue("from_counter", values.from_counter);
    returnForm.setFieldValue("toCounter", values.to_counter);
    returnForm.setFieldValue("description", values.description);
  };

  const reasons = useQuery("reasons_list", return_api.get_reasons, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      let reasons = data.data.map(e => {
        return {
          label: e[1],
          value: e[0],
        };
      });
      setReasonsData(reasons);
    },
  });

  const products = useQuery("products_list", () => stock_apis.get_products(), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      console.log("data", data);
      let products = data.data.map(e => {
        return {
          label: e.title,
          value: e.id,
        };
      });
      setProductsData(products);
    },
  });

  // const products = useQuery({
  //   queryKey: ["stock-product-data"],
  //   onSuccess: data => {
  //     // console.log("this is `let`a", data.data);
  //     setData(
  //       data.data.map(e => {
  //         return { value: e.id, label: e.title };
  //       })
  //     );
  //   },
  //   queryFn: () => stock_apis.get_products(),
  // });

  const returns = useQuery("return_data", () => return_api.get_returns(getCounterId()), {
    onSuccess: data => {
      setReturnsData(data.data);
    },
  });

  // const getCounters = useQuery("counters-list", counters_api.get, {
  //   onSuccess: data => {
  //     //console.log("counters list", data.data);
  //     let counters = data.data.map(e => {
  //       return {
  //         label: e.name,
  //         value: e.id,
  //       };
  //     });
  //     setCounters(counters);
  //     returns.refetch();
  //   },
  // });

  const returnForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      product: "",
      batch: "",
      qty: "",
      reason: "",
      description: "",
      from_counter: "",
      toCounter: "",
      status: false,
    },
    validate: {
      product: value =>
        isEditing || isEditingStatus ? null : value ? null : "Product is required",
      batch: value => (isEditing || isEditingStatus ? null : value ? null : "Batch is required"),
      qty: value => (isEditingStatus ? null : value ? null : "Qty is required"),
      reason: value => (isEditingStatus ? null : value ? null : "Reason is required"),
      from_counter: value =>
        isEditing || isEditingStatus ? null : value ? null : "Transferred from is required",
      toCounter: value =>
        isEditing || isEditingStatus ? null : value ? null : "Received to is required",
    },
  });

  const getCounters = useQuery("counters_list", counters_api.get, {
    enabled: modalOpened,
    onSuccess: data => {
      console.log("counters list", data.data);
      let counters_list = data.data.map(e => {
        return {
          label: e.name,
          value: e.id,
        };
      });
      setCounters(counters_list);
      const a = data.data.filter(e => e.warehouse);
      // console.log(a);
      returnForm.setValues({
        toCounter: a[0]?.id,
        from_counter: getCounterId() || null,
      });
    },
  });

  const counter_stocks = useQuery("counter_stocks", counters_api.get_counter_stocks, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setCounterStocksData(data.data);
    },
  });

  const handleProductSelect = () => {
    if (returnForm.values.product !== productSelected) {
      returnForm.setFieldValue("batch", null);
      setProductSelected(returnForm.values.product);
      const product = products.data?.data.find(value => value.id === returnForm.values.product);
      console.log(products.data?.data);
      if (product !== undefined) {
        console.log({ product });
        //console.log(product);
        setProductAmount(product.selling_price);
        const batch_data = product.batch.map(value => {
          return { value: value.id, label: value.batch_no, id: value.id };
        });
        console.log(batch_data);
        setBatchData(batch_data);
      } else {
        setBatchData([]);
        setProductAmount(null);
      }
    }
  };

  const openDeleteConfirmation = id => {
    openConfirmModal({
      title: "Please confirm delete return",
      children: (
        <Text size="sm">
          Are you sure you want to delete this return data ? This action can't be undone.
        </Text>
      ),
      labels: { confirm: "Delete Return", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { color: "red" },
    });
  };

  // const users_data = useQuery('users', api_getUsers);

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        size: "auto",
      },
      {
        header: "Product",
        accessorKey: "product_title",
        size: "auto",
      },
      {
        header: "Transferred From",
        accessorKey: "from_counter",
        size: "auto",
      },
      {
        header: "Received To",
        accessorKey: "to_counter",
        size: "auto",
      },
      {
        header: "Batch",
        accessorKey: "batch_no",
        size: "auto",
        Cell: ({ cell }) => <Badge variant="light"> {cell.getValue()}</Badge>,
      },

      {
        header: "Qty",
        accessorKey: "stock",
        size: "auto",
        filterVariant: "range",
      },
      {
        header: "Amount",
        accessorKey: "amount",
        size: "auto",
        filterVariant: "range",

        Cell: ({ cell }) => (
          <span className="ls-1">₹ {cell.getValue()} </span> //{cell.getValue().toLocaleString("en-IN")}
        ),
      },
      {
        accessorFn: originalRow => new Date(originalRow.created_at),
        id: "created_at",
        header: "Created",
        accessorKey: "created_at",
        size: "auto",
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
        filterVariant: "date-range",
      },
      {
        accessorFn: originalRow => new Date(originalRow.updated_at),
        id: "updated_at",
        header: "Updated",
        accessorKey: "updated_at",
        size: "auto",
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
        filterVariant: "date-range",
      },
      {
        header: "Reason",
        accessorKey: "reason",
        size: "auto",
        Cell: ({ cell }) =>
          cell.getValue().replace(/_/gi, " ").charAt(0).toUpperCase() +
          cell.getValue().replace(/_/gi, " ").slice(1),
      },
      {
        header: "Status",
        accessorKey: "status",
        size: "auto",
        Cell: ({ cell }) =>
          cell.getValue() === "accepted" ? (
            <Badge color={"green"} variant="filled">
              {cell.getValue()}
            </Badge>
          ) : (
            <Badge variant="light"> {cell.getValue()}</Badge>
          ),
      },
    ],
    []
  );

  const addReturn = async values => {
    setLoading(true);
    console.log(values);

    let payload = {
      from_counter: values.from_counter,
      to_counter: values.toCounter,
      amount: productAmount,
      product: values.product,
      batch: values.batch,
      stock: values.qty,
      reason: values.reason,
      description: values.description,
    };

    console.log(payload);

    await return_api.add_return_stock(payload).then(res => {
      setLoading(false);
      console.log("Response", res);

      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "New return succesfully added.",
        });
        setModalOpened(false);
        returns.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  const editReturn = async values => {
    setLoading(true);
    console.log(values);

    let payload = {
      id: editingId,
      stock: values.qty,
      reason: values.reason,
      description: values.description,
    };

    console.log(payload);

    await return_api.edit_return_stock_qty(payload).then(res => {
      setLoading(false);
      console.log("Response", res);

      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Return stock succesfully updated.",
        });
        setIsEditing(false);
        setModalOpened(false);
        returns.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  const editReturnStatus = async values => {
    setLoading(true);
    console.log(values);

    if (values.status) {
      let payload = {
        id: editingId,
      };

      console.log(payload);

      await return_api.edit_return_stock_status(payload).then(res => {
        setLoading(false);
        console.log("Response", res);

        if (res.success) {
          showSuccessToast({
            title: "Success",
            message: "Return stock accepted.",
          });
          setIsEditingStatus(false);
          setModalOpened(false);
          returns.refetch();
        } else {
          showErrorToast({
            title: "Error",
            message: res.message,
          });
        }
      });
    } else {
      setIsEditingStatus(false);
      setModalOpened(false);
      returns.refetch();
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  //   //return parseFloat(text.replace(/[^0-9.]/g, ""));
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   return total;
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length !== i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   const cols = [5, 6];
  //   let sum = [];
  //   get_sum(removeAction).map((e, i) => {
  //     if (isNaN(e) || !cols?.includes(i, 0)) {
  //       sum.push("");
  //     } else {
  //       sum.push(e);
  //     }
  //   });
  //   csv_data.push(sum);
  //   setCsv(csv_data);
  // };

  const printFilter = () => {
    const model = "ReturnStock";

    const appendData = [
      { id: "id", append: "" },
      { id: "title", append: "product__", replace_id: "product_title" },
      { id: "name", append: "from_counter__", replace_id: "from_counter" },
      { id: "name", append: "to_counter__", replace_id: "to_counter" },
      { id: "batch_no", append: "" },
      { id: "stock", append: "" },
      { id: "amount", append: "" },
      { id: "created_at", append: "" },
      { id: "updated_at", append: "" },
      { id: "reason", append: "" },
      { id: "status", append: "" },
    ];
    const custom = getCustomId(columns, columnFilters, appendData);
    const filter = getFilterPayload(custom?.columns, custom?.columnFilters);
    const fields = getFields(custom?.columns);

    api_filter(model, filter, fields)
      .then(res => {
        if (res.success) {
          console.log(res.data);
          const head_content = getHeaders(columns);
          const body_content = getBodyContent(res.data, fields);
          const csv_content = getCsvContent(head_content, body_content, ["RETURNS"]);
          const columnTotalIndex = [5, 6];
          const foot_content = getTotal(body_content, columnTotalIndex);
          setFilename(
            "RETURNS" + "_" + "WareHouse" + "_" + new Date().toLocaleDateString() + ".csv"
          );
          setCsv(csv_content);
          setHead(head_content);
          setBody(body_content);
          setFoot(foot_content);
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Returns
                </Text>
                <AppBreadcrumb
                  crumbs={[
                    {
                      text: "Transactions",
                    },
                    {
                      text: "Returns",
                    },
                  ]}
                />
              </Box>
              {hasPermission("add_returnstock") && (
                <Box>
                  <Button
                    onClick={() => {
                      setIsCsv(true);
                      printFilter();
                    }}
                    leftIcon={<IconDownload />}
                  >
                    CSV
                  </Button>
                  {/* <Button
                    ml={10}
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      print(true);
                    }}
                  >
                    Print
                  </Button> */}
                  <Button
                    ml={10}
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      printFilter();
                    }}
                  >
                    Print
                  </Button>
                  {isCounterAdmin() && (
                    <Button
                      onClick={() => {
                        returnForm.reset();
                        setModalOpened(true);
                      }}
                    >
                      <IconPlus style={{ width: "20px" }} />
                      <Text ml={10}> Add New Return </Text>
                    </Button>
                  )}
                </Box>
              )}
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        {/* Add Product Form */}

        {/* Add Product Form */}

        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={columnFilters} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Returns List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => {
                //console.log(row);

                let condition =
                  row.original.status !== "accepted" && row.original.status !== "rejected";

                return (
                  <Flex>
                    <Tooltip label="View Return">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        ml={10}
                        onClick={() => {
                          setReturnModalDetails(row.original);
                          setViewReturnModalOpened(true);
                        }}
                      >
                        <IconEye style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                    {hasPermission("change_returnstock") && (
                      <>
                        {condition && (
                          <>
                            {(!isCounterAdmin() || isDamage) && (
                              <Tooltip label="Edit Return Status">
                                <ActionIcon
                                  sx={theme => ({ color: theme.colors.brand[7] })}
                                  ml={10}
                                  onClick={() => {
                                    handleEdit(row.original);
                                    setIsEditingStatus(true);
                                    setModalOpened(true);
                                  }}
                                >
                                  <IconStatusChange style={{ width: 20 }} />
                                </ActionIcon>
                              </Tooltip>
                            )}
                            {isCounterAdmin() && !isDamage && (
                              <>
                                <Tooltip label="Edit Return">
                                  <ActionIcon
                                    sx={theme => ({ color: theme.colors.brand[7] })}
                                    ml={10}
                                    onClick={() => {
                                      handleEdit(row.original);
                                      setIsEditing(true);
                                      setModalOpened(true);
                                    }}
                                  >
                                    <IconEdit style={{ width: 20 }} />
                                  </ActionIcon>
                                </Tooltip>
                                <Tooltip label="Delete Return">
                                  <ActionIcon
                                    sx={theme => ({ color: theme.colors.red[6] })}
                                    ml={10}
                                    onClick={() => {
                                      openDeleteConfirmation(row.id);
                                    }}
                                  >
                                    <IconTrash style={{ width: 20 }} />
                                  </ActionIcon>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Flex>
                );
              }}
              initialState={{ density: "xs" }}
              //state={{ isLoading: counter_stocks.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={returnsData || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setIsEditing(false);
          setIsEditingStatus(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>
              {isEditingStatus ? "Edit return status" : isEditing ? "Edit return" : "Add Return"}
            </Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Box>
            <form
              onSubmit={returnForm.onSubmit(values => {
                console.log(values);
                isEditingStatus
                  ? editReturnStatus(values)
                  : isEditing
                  ? editReturn(values)
                  : addReturn(values);
              })}
            >
              <Grid>
                {isEditingStatus ? (
                  <Switch
                    m={10}
                    labelPosition="left"
                    label="Accept return"
                    {...returnForm.getInputProps("status")}
                  />
                ) : (
                  <>
                    {isEditing ? (
                      <></>
                    ) : (
                      <>
                        <Col span={4}>
                          <Select
                            data={productsData}
                            searchable
                            key={"id"}
                            label="Select Product"
                            onSelect={() => {
                              handleProductSelect();
                            }}
                            placeholder="Search Product"
                            {...returnForm.getInputProps("product")}
                          />
                        </Col>
                        <Col span={4}>
                          <Select
                            data={batchData}
                            searchable
                            key={"id"}
                            label="Select Batch"
                            placeholder="Search Batch"
                            {...returnForm.getInputProps("batch")}
                          />
                        </Col>
                      </>
                    )}
                    <Col span={isEditing ? 12 : 4}>
                      <NumberInput
                        type="number"
                        label="Qty"
                        placeholder="Enter Qty"
                        {...returnForm.getInputProps("qty")}
                      />
                    </Col>
                    {isEditing ? (
                      <></>
                    ) : (
                      <>
                        <Col span={6}>
                          <Select
                            data={counters}
                            searchable
                            label="Transferred From"
                            placeholder="Search Transferred From"
                            {...returnForm.getInputProps("from_counter")}
                          />
                        </Col>
                        <Col span={6}>
                          <Select
                            data={counters}
                            searchable
                            label="Received To"
                            placeholder="Search Received To"
                            {...returnForm.getInputProps("toCounter")}
                          />
                        </Col>
                      </>
                    )}
                    <Col span={12}>
                      <Select
                        data={reasonsData}
                        label="Select Reason"
                        placeholder="Search Reason"
                        {...returnForm.getInputProps("reason")}
                      />
                    </Col>
                    <Col span={12}>
                      <Textarea
                        label="Enter Description ( optional )"
                        placeholder="Enter Description"
                        {...returnForm.getInputProps("description")}
                      />
                    </Col>
                  </>
                )}

                <Col span={12} my={10}>
                  <Flex h={"100%"} direction={"column"} justify="end" align={"center"}>
                    <Button w={"100%"} type="submit">
                      {isEditingStatus
                        ? "Edit return status"
                        : isEditing
                        ? "Edit return"
                        : "Add Return"}
                    </Button>
                  </Flex>
                </Col>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>

      {/* View Returns Modal */}

      <Modal
        opened={viewReturnModalOpened}
        onClose={() => {
          setViewReturnModalOpened(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>Return Details</Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Grid>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Product
              </Text>
              <Text> {returnModalDetails.product_title} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Batch Code
              </Text>
              <Text> {returnModalDetails.batch_no} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Qty
              </Text>
              <Text> {returnModalDetails.stock} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Amount
              </Text>
              <Text> ₹ {returnModalDetails.amount} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Reason
              </Text>
              <Text>
                {" "}
                {returnModalDetails.reason?.replace(/_/gi, " ").charAt(0).toUpperCase() +
                  returnModalDetails.reason?.replace(/_/gi, " ").slice(1)}{" "}
              </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Status
              </Text>
              <Text tt="capitalize"> {returnModalDetails.status} </Text>
            </Col>
            <Col span={12}>
              <Text size={"xs"} opacity={0.4}>
                Description
              </Text>
              <Text> {returnModalDetails.description} </Text>
            </Col>
          </Grid>
        </Box>
      </Modal>
      <div style={{ display: "none" }}>
        <PrintModal
          cols={[5, 6]}
          title="Returns"
          head={head}
          body={body}
          foots={foot}
          ref={componentRef}
        />
      </div>

      {hasPermission("add_returnstock") && isMobileScreen ? (
        <>
          <FloatingMenu m={5} left size={50}>
            <CSVLink
              data={csv}
              filename="returns.csv"
              onClick={() => {
                setIsCsv(true);
                printFilter();
              }}
            >
              <IconDownload color="white" />
            </CSVLink>
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
