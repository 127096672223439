import { joiResolver } from "@hookform/resolvers/joi";
import {
  ActionIcon,
  Box,
  Button,
  Col,
  Container,
  Flex,
  Grid,
  Modal,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconEdit, IconFilter } from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import Joi from "joi";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { showSuccessToast } from "utilities/Toast";

import { api_editAddress, api_getAddress } from "./address.service";

export default function Address() {
  let isMobileScreen = useMediaQuery("(max-width: 768px)");
  // STARTS : States
  const [addressData, setAddressData] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedAddress, setSelectedAddresss] = useState(null);
  const [loading, setLoading] = useState(false);
  // ENDS : States

  const address_data = useQuery("address_list", api_getAddress, {
    onSuccess: data => {
      setAddressData(data.data);
    },
  });

  const emptyMessage = label => {
    return {
      "string.empty": `${label} is required.`,
      "number.base": `${label} is required.`,
    };
  };

  const validationSchema = Joi.object({
    first_name: Joi.required().messages(emptyMessage("First Name")),
    address_title: Joi.required().messages(emptyMessage("Address Title")),
    address_line_1: Joi.required().messages(emptyMessage("Address Line 1")),
    address_line_2: Joi.required().messages(emptyMessage("Address Line 2")),
    city: Joi.required().messages(emptyMessage("City")),
    state: Joi.required().messages(emptyMessage("State")),
    phone_no: Joi.required().messages(emptyMessage("Phone No.")),
    pincode: Joi.required().messages(emptyMessage("Pincode")),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setValues,
    getValues,
    register: form,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      first_name: "",
      address_title: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      phone_no: "",
      pincode: "",
    },
    resolver: joiResolver(validationSchema),
  });
  const handleEditAddress = values => {
    reset({
      first_name: values.first_name,
      address_title: values.address_title,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      city: values.city,
      state: values.state,
      phone_no: values.phone_no,
      pincode: values.pincode,
    });
    setSelectedAddresss(values);
    setModalOpened(true);
  };

  const editAddress = async payload => {
    setLoading(true);
    await api_editAddress({
      ...payload,
      country: selectedAddress.country,
      id: selectedAddress.id,
    }).then(res => {
      setLoading(false);

      console.log(res);
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Address Edited Successfully.",
        });
        address_data.refetch();
        setModalOpened(false);
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "First Name",
        accessorKey: "first_name", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Address Title",
        accessorKey: "address_title", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Address Line 1",
        accessorKey: "address_line_1", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Address Line 2",
        accessorKey: "address_line_2", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "City",
        accessorKey: "city", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "State",
        accessorKey: "state", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Pincode",
        accessorKey: "pincode", //simple accessorKey pointing to flat data
        size: "auto",
      },
    ],
    []
  );

  return (
    <>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Address Master
                </Text>
                <AppBreadcrumb />
              </Box>
            </Flex>
          </Box>
        </Container>
      )}

      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box>
            <MantineReactTable
              mantineTableProps={{ className: "datatable" }}
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Address List
                  </Text>
                </Box>
              )}
              enableRowActions
              positionActionsColumn="last"
              renderRowActions={({ row }) => (
                <Flex>
                  <Tooltip label="Edit Address">
                    <ActionIcon
                      mr={10}
                      sx={theme => ({ color: theme.colors.brand[6] })}
                      onClick={() => {
                        // setSelectedAddresss(row.original);
                        handleEditAddress(row.original);
                        // setModalOpened(true);
                        // setSelectedStockTransferId(row.original.id);
                        // setOrderDetailsModalVisible(true);
                      }}
                    >
                      <IconEdit style={{ width: 22 }} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              )}
              initialState={{ density: "xs" }}
              state={{ isLoading: address_data.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={addressData || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
        }}
        zIndex={10}
        styles={{ header: { borderBottom: "1px solid #eee" } }}
        title={
          <Box px={10}>
            <Text fw={600}> Edit Address </Text>
          </Box>
        }
        size={"660px"}
        centered
      >
        <form
          onError={() => {
            console.log(errors);
          }}
          onSubmit={handleSubmit(values => {
            console.log(values);
            editAddress(values);
          })}
        >
          <Box p={20}>
            <Grid>
              <Col sm={6}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label="First Name"
                      placeholder="Enter First Name"
                      error={errors.first_name?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={6}>
                <Controller
                  name="phone_no"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label="Phone Number"
                      placeholder="Enter Phone No."
                      error={errors.phone_no?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={12}>
                <Controller
                  name="address_title"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label="Address Title"
                      placeholder="Enter Address Title"
                      error={errors.address_title?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={12}>
                <Controller
                  name="address_line_1"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label="Address Line 1"
                      placeholder="Enter Address Line 1"
                      error={errors.address_line_1?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={12}>
                <Controller
                  name="address_line_2"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label="Address Line 2"
                      placeholder="Enter Address Line 2"
                      error={errors.address_line_2?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={4}>
                <Controller
                  name="pincode"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label="Pincode"
                      placeholder="Enter Pincode"
                      error={errors.pincode?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={4}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label="City"
                      placeholder="Enter City"
                      error={errors.city?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={4}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label="State"
                      placeholder="Enter State"
                      error={errors.state?.message}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={12}>
                <Button
                  w={"100%"}
                  mt={10}
                  type="submit"
                  onClick={() => {
                    console.log(errors);
                  }}
                  loading={loading}
                >
                  Edit Address
                </Button>
              </Col>
            </Grid>
          </Box>
        </form>
      </Modal>
    </>
  );
}
