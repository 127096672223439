import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Flex,
  Modal,
  Switch,
  Text,
  Tooltip,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import {
  IconDownload,
  IconEdit,
  IconEye,
  IconPackage,
  IconPlus,
  IconPrinter,
  IconRefreshDot,
  IconTrash,
} from "@tabler/icons";
import FloatingMenu from "components/FloatingMenu";
import { PrintModal } from "components/PrintModal";
import produce from "immer";
import { MantineReactTable } from "mantine-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  getBodyContent,
  getCsvContent,
  getCustomId,
  getFields,
  getFilterPayload,
  getHeaders,
  getTotal,
} from "services/filterHelperFunctions";
import { api_filter } from "services/master.service";

import BatchMaster from "./BatchMaster";
import dummyData from "./dummyData.json";
import { products_api } from "./products.service";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { image_url } from "../../../config";
import { api_deleteProduct, api_getProducts } from "../../../services/product.service";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";

const confirm_delete_props = {
  title: "Please confirm delete product",
  children: (
    <Text size="sm">
      Are you sure you want to delete this product ? Everything related to this product will be
      deleted.
    </Text>
  ),
  labels: { confirm: "Delete Product", cancel: "Cancel" },
  onCancel: () => console.log("Cancel"),
  confirmProps: { color: "red" },
};

export default function ProductsList() {
  const navigate = useNavigate("/");
  let isMobileScreen = useMediaQuery("(max-width: 768px)");
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);

  // START : States
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productsData, setProductsData] = useState([]);
  const [filename, setFilename] = useState("file");
  // END : States

  // STARTS : States
  const [userFormVisible, setUserFormVisible] = useState(false);

  // ENDS : States
  const [columnFilters, setColumnFilters] = useState([]);

  const openDeleteConfirmation = id => {
    openConfirmModal({
      ...confirm_delete_props,
      onConfirm: async () => await deleteProduct(id),
    });
  };

  const deleteProduct = async id => {
    await api_deleteProduct(id)
      .then(res => {
        if (res.success) {
          showSuccessToast({ title: "Deleted Product", message: res.message });
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  const syncBatches = async () => {
    await products_api.syncBatches().then(res => {
      console.log(res);
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Batches synced successfully.",
        });
      } else {
        showSuccessToast({
          title: "Error",
          message: "Error in syncing the batches.",
        });
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Product Code",
        accessorKey: "product_code",
        size: "auto",
        filterVariant: "range",
      },
      {
        header: "Product",
        accessorKey: "title",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => (
          <Flex align={"center"}>
            <img
              src={
                row.original.thumbnail
                  ? image_url + row.original.thumbnail
                  : "https://universalele.websites.co.in/obaju-turquoise/img/product-placeholder.png"
              }
              width={30}
              height={30}
              style={{
                borderRadius: 100,
                border: "2px solid #eee",
                objectFit: "cover",
              }}
              alt=""
            />
            <Text ml={10}> {renderedCellValue} </Text>
          </Flex>
        ),
      },
      {
        header: "Category",
        accessorKey: "category",
        size: "auto",
      },
      {
        header: "Sub Category",
        accessorKey: "sub_category",
        size: "auto",
      },
      {
        header: "Pushed At",
        accessorKey: "updated_at",
        size: "auto",
        filterVariant: "date-range",
      },

      {
        header: "Stock",
        accessorKey: "stock_qty",
        size: "auto",
        filterVariant: "range",
        Cell: ({ cell }) => {
          let val = +cell.getValue();
          let color;
          if (val <= 50 && val >= 10) {
            color = "yellow";
          } else if (val <= 10) {
            color = "red";
          } else {
            color = "light";
          }

          return (
            <Badge fz={"md"} fw={"bold"} variant={"light"} color={color}>
              {val > 0 ? cell.getValue() : "Out of Stock"}
            </Badge>
          );
        },
      },
      {
        header: "Active",
        accessorKey: "active",
        size: "auto",
        filterVariant: "select",
        mantineFilterSelectProps: {
          data: ["true", "false"],
        },
        Cell: ({ cell, row }) => (
          <Switch
            onChange={e => handleStatusChange(row.original.id, e.target.checked)}
            checked={cell.getValue()}
            size={"xs"}
          />
        ),
      },
    ],
    []
  );

  // const first = (second) => { third }

  const changeStatus = (id, status) => {
    setProductsData(
      produce(draft => {
        let product = draft.find(e => e.id === id);
        product.active = status;
      })
    );
  };

  const handleStatusChange = async (productId, status) => {
    console.log(productId);

    await products_api
      .change_active_status({
        product_id: productId,
      })
      .then(res => {
        if (res.success) {
          changeStatus(productId, status);
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      });
  };

  const products = useQuery("products_list", products_api.get, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setProductsData(data.data);
    },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  //   //return parseFloat(text.replace(/[^0-9.]/g, ""));
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   console.log(total);
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length - 1 > i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length - 1 > i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   setCsv(csv_data);
  //   get_sum(removeAction);
  //   //console.log(body_content);
  // };

  const printFilter = () => {
    const model = "Products";
    //["product_code","title","category","sub_category","updated_at","stock_qty","active"]
    const appendData = [
      { id: "product_code", append: "" },
      { id: "title", append: "" },
      { id: "title", append: "category__", replace_id: "category" },
      { id: "title", append: "sub_category__", replace_id: "sub_category" },
      { id: "updated_at", append: "" },
      { id: "stock_qty", append: "" },
      { id: "active", append: "" },
    ];
    const custom = getCustomId(columns, columnFilters, appendData);
    const filter = getFilterPayload(custom?.columns, custom?.columnFilters);
    const fields = getFields(custom?.columns);

    api_filter(model, filter, fields)
      .then(res => {
        if (res.success) {
          console.log(res.data);
          const head_content = getHeaders(columns);
          const body_content = getBodyContent(res.data, fields);
          const csv_content = getCsvContent(head_content, body_content, ["PRODUCTS"]);
          const columnTotalIndex = [5];
          const foot_content = getTotal(body_content, columnTotalIndex);
          setFilename(
            "PRODUCTS" + "_" + "WareHouse" + "_" + new Date().toLocaleDateString() + ".csv"
          );
          setCsv(csv_content);
          setHead(head_content);
          setBody(body_content);
          setFoot(foot_content);
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box px={isMobileScreen ? 0 : 25} py={isMobileScreen ? 15 : 25}>
            <Flex justify={"space-between"} align="end" wrap={"wrap"}>
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Products
                </Text>
                <AppBreadcrumb
                  crumbs={[
                    {
                      text: "Master",
                    },
                    {
                      text: "Products",
                    },
                  ]}
                />
              </Box>
              <Flex align={"center"} justify={"center"} mt={isMobileScreen ? 10 : 0} gap={10}>
                <Button
                  onClick={() => {
                    setIsCsv(true);
                    printFilter();
                  }}
                  leftIcon={<IconDownload />}
                >
                  CSV
                </Button>
                {/* <Button
                  leftIcon={<IconPrinter />}
                  onClick={() => {
                    print(true);
                  }}
                >
                  Print
                </Button> */}
                <Button
                  leftIcon={<IconPrinter />}
                  onClick={() => {
                    printFilter();
                  }}
                >
                  Print
                </Button>
                <Button
                  onClick={() => {
                    navigate("add");
                  }}
                >
                  <IconPlus style={{ width: "20px" }} />
                  <Text ml={10}> Add Product </Text>
                </Button>
                <Button variant="outline" onClick={syncBatches}>
                  <IconRefreshDot style={{ width: "20px" }} />
                  <Text ml={10}> Sync Batches </Text>
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        {/* Add Product Form */}

        {/* Add Product Form */}
        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              //manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={columnFilters} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Product List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  <Tooltip label="View Product">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      onClick={() => {
                        navigate(`view/?id=${row.original.id}`);
                      }}
                    >
                      <IconEye style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Manage Batch">
                    <ActionIcon
                      ml={10}
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      onClick={() => {
                        setSelectedProduct(row.original);
                        setUserFormVisible(true);
                      }}
                    >
                      <IconPackage style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>

                  <Tooltip label="Edit Product">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      ml={10}
                      onClick={() => {
                        navigate(`edit?id=${row.original.id}`);
                      }}
                    >
                      <IconEdit style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>

                  <Tooltip label="Delete Product">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.red[6] })}
                      ml={10}
                      onClick={() => {
                        openDeleteConfirmation(row.original.id);
                      }}
                    >
                      <IconTrash style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              )}
              initialState={{ density: "xs" }}
              //state={{ isLoading: products.isLoading || products.isRefetching }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={isMobileScreen}
              enableColumnActions={false}
              data={productsData || []}
              enableStickyHeader
              enablePagination
              autoResetPageIndex={false}
              enableGlobalFilter={true} //turn off a feature
            />
          </Box>
        </Box>
      </Container>
      {/* ENDS :: Main content */}

      {/* STARTS :: Add User Modal */}
      <Modal
        opened={userFormVisible}
        onClose={() => {
          setUserFormVisible(false);
        }}
        title={
          <Box px={10}>
            <Text fw={700}> Manage Batches </Text>
          </Box>
        }
        size={"960px"}
        centered
      >
        <BatchMaster product={selectedProduct} />
      </Modal>

      <div style={{ display: "none" }}>
        <PrintModal title="Products" head={head} body={body} foots={foot} ref={componentRef} />
      </div>
      {/* ENDS :: Add User Modal */}
      {isMobileScreen ? (
        <>
          <FloatingMenu
            m={5}
            left
            size={50}
            onClick={() => {
              setIsCsv(true);
              printFilter();
            }}
          >
            <IconDownload color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            ml={60}
            left
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
          <FloatingMenu m={5} right size={50} mr={60} onClick={syncBatches}>
            <IconRefreshDot color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              navigate("add");
            }}
          >
            <IconPlus color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
