import {
  ActionIcon,
  Box,
  Button,
  Container,
  Flex,
  Select,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconDownload, IconEdit, IconFile, IconPlus, IconPrinter, IconTrash } from "@tabler/icons";
import AddPaymentReceipt from "components/AddPaymentReceipt";
import AppBreadcrumb from "components/AppBreadcrumb";
import FloatingMenu from "components/FloatingMenu";
import { PrintModal } from "components/PrintModal";
import { MantineReactTable } from "mantine-react-table";
import moment from "moment/moment";
import { getCounterId, hasPermission, isCounterAdmin } from "pages/Auth/permissionGuard";
import { counters_api } from "pages/master/counter/counter.service";
import { getAlteredCounters } from "pages/master/customers/helperFunctions";
import { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useReactToPrint } from "react-to-print";
import {
  getBodyContent,
  getCsvContent,
  getCustomId,
  getFields,
  getFilterPayload,
  getHeaders,
  getTotal,
} from "services/filterHelperFunctions";
import { api_filter } from "services/master.service";
import { showErrorToast } from "utilities/Toast";

import { api_getPaymentReceipt } from "./paymentReceipt.service";

const confirm_delete_props = {
  title: "Please confirm delete payment receipt",
  children: (
    <Text size="sm">
      Are you sure you want to delete this payment receipt ? Everything related to this payment
      receipt will be deleted.
    </Text>
  ),
  labels: { confirm: "Delete Payment Receipt", cancel: "Cancel" },
  onCancel: () => console.log("Cancel"),
  confirmProps: { color: "red" },
};

export default function PaymentReceipt() {
  const theme = useMantineTheme();
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [editingPaymentReceiptId, setEditingPaymentReceiptId] = useState("");
  const [invoicesData, setInvoicesData] = useState([]);
  const [paymentReceiptData, setPaymentReceiptData] = useState([]);
  // const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentReceiptFormVisible, setPaymentReceiptFormVisible] = useState(false);

  const [counter, setCounter] = useState(isCounterAdmin() ? getCounterId() : "");
  // END : States

  const [countersList, setCountersList] = useState([]);
  const [selectedPaymentReceipt, setSelectedPaymentReceipt] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [filename, setFilename] = useState("file");

  const counter_data = useQuery({
    queryKey: ["counters-data-list"],
    queryFn: () => counters_api.get(),
    onSuccess: data => {
      setCountersList(getAlteredCounters(data.data));
    },
  });

  const openDeleteConfirmation = id => {
    openConfirmModal({
      ...confirm_delete_props,
      // onConfirm: () => deletePaymentReceipt(id),
    });
  };

  const paymentReceipt = useQuery({
    queryKey: ["paymentReceipt", counter],
    queryFn: () => api_getPaymentReceipt(counter),
    onSuccess: data => {
      console.log(data.data);
      setPaymentReceiptData(data.data);
    },
  });

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "id", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Invoice",
        accessorKey: "invoiceid", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        accessorFn: originalRow => new Date(originalRow.created_at),
        id: "created_at",
        header: "Payment Date",
        accessorKey: "created_at",
        size: "auto",
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
        filterVariant: "date-range",
      },
      {
        header: "Counter",
        accessorKey: "counter_name", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "User",
        accessorKey: "received_user", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Payment Method",
        accessorKey: "mode_of_payment", //simple accessorKey pointing to flat data
        size: "auto",
      },
      {
        header: "Amount",
        accessorKey: "amount", //simple accessorKey pointing to flat data
        size: "auto",
        filterVariant: "range",

        Cell: ({ cell }) => (
          <span className="ls-1">₹ {cell.getValue().toLocaleString("en-IN")}</span>
        ),
      },
      {
        accessorFn: originalRow => new Date(originalRow.updated_at),
        id: "updated_at",
        header: "Updated",
        accessorKey: "updated_at",
        size: "auto",
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
        filterVariant: "date-range",
      },
    ],
    []
  );

  const initEditingPaymentReceipt = row => {
    setIsEditing(true);
    console.log(row.original);
    setSelectedPaymentReceipt({
      id: row.original.id,
      invoice: row.original.invoice,
      payment_type: row.original.mode_of_payment,
      date: row.original.created_at,
      amount: row.original.amount,
    });
    setPaymentReceiptFormVisible(true);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  //   //return parseFloat(text.replace(/[^0-9.]/g, ""));
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   return total;
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length !== i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   const cols = [3];
  //   let sum = [];
  //   get_sum(removeAction).map((e, i) => {
  //     if (isNaN(e) || !cols?.includes(i, 0)) {
  //       sum.push("");
  //     } else {
  //       sum.push(e);
  //     }
  //   });
  //   csv_data.push(sum);
  //   setCsv(csv_data);
  // };

  const printFilter = () => {
    const model = "PaymentReceipt";

    const appendData = [
      { id: "id", append: "" },
      { id: "invoice_id", append: "invoice__", replace_id: "invoiceid" },
      { id: "created_at", append: "" },
      { id: "name", append: "invoice__counter__", replace_id: "counter_name" },
      { id: "username", append: "received_user__", replace_id: "received_user" },
      { id: "mode_of_payment", append: "" },
      { id: "amount", append: "" },
      { id: "updated_at", append: "" },
    ];
    const custom = getCustomId(columns, columnFilters, appendData);
    const filter = getFilterPayload(custom?.columns, custom?.columnFilters);
    const fields = getFields(custom?.columns);

    api_filter(model, filter, fields)
      .then(res => {
        if (res.success) {
          console.log(res.data);
          const head_content = getHeaders(columns);
          const body_content = getBodyContent(res.data, fields);
          const csv_content = getCsvContent(head_content, body_content, ["PAYMENT RECEIPT"]);
          const columnTotalIndex = [5];
          const foot_content = getTotal(body_content, columnTotalIndex);
          setFilename(
            "PAYMENT_RECEIPT" +
              "_" +
              countersList?.find(e => e.id === counter)?.label +
              "_" +
              new Date().toLocaleDateString() +
              ".csv"
          );
          setCsv(csv_content);
          setHead(head_content);
          setBody(body_content);
          setFoot(foot_content);
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      {isMobileScreen ? (
        <>
          <Select
            p={5}
            label="Counter"
            defaultValue={counter || null}
            placeholder="Select Counter"
            data={countersList || []}
            onChange={setCounter}
            clearable
            disabled={isCounterAdmin()}
          />
        </>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Payment Receipt
                </Text>
                <AppBreadcrumb />
              </Box>
              <Box>
                <Flex align={"end"} gap={10}>
                  <Button
                    onClick={() => {
                      setIsCsv(true);
                      printFilter();
                    }}
                    leftIcon={<IconDownload />}
                  >
                    CSV
                  </Button>
                  {/* <Button
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      print(true);
                    }}
                  >
                    Print
                  </Button> */}
                  <Button
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      printFilter();
                    }}
                  >
                    Print
                  </Button>
                  {hasPermission("add_paymentreceipt") && (
                    <Box>
                      <Button
                        onClick={() => {
                          // addPaymentReceiptForm.reset();
                          setIsEditing(false);
                          setPaymentReceiptFormVisible(true);
                        }}
                      >
                        <IconPlus style={{ width: "20px" }} />
                        <Text ml={10}> Add Payment Receipt </Text>
                      </Button>
                    </Box>
                  )}
                  <Select
                    label="Counter"
                    defaultValue={counter || null}
                    placeholder="Select Counter"
                    data={countersList || []}
                    onChange={setCounter}
                    clearable
                    disabled={isCounterAdmin()}
                  />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={columnFilters} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Payment Receipt List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  {hasPermission("change_paymentreceipt") && (
                    <>
                      <Tooltip label="Edit Payment Receipt">
                        <ActionIcon
                          ml={10}
                          sx={theme => ({ color: theme.colors.brand[7] })}
                          onClick={() => {
                            initEditingPaymentReceipt(row);
                          }}
                        >
                          <IconEdit style={{ width: 20 }} />
                        </ActionIcon>
                      </Tooltip>
                      {/* <Tooltip label="Delete Payment Receipt">
                        <ActionIcon
                          sx={theme => ({ color: theme.colors.red[6] })}
                          ml={10}
                          onClick={() => {
                            openDeleteConfirmation(row.original.id);
                          }}
                        >
                          <IconTrash style={{ width: 20 }} />
                        </ActionIcon>
                      </Tooltip> */}
                    </>
                  )}
                </Flex>
              )}
              initialState={{ density: "xs" }}
              //state={{ isLoading: paymentReceipt.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={paymentReceiptData || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      <AddPaymentReceipt
        opened={paymentReceiptFormVisible}
        defaultData={selectedPaymentReceipt}
        edit={isEditing}
        onClose={() => {
          setPaymentReceiptFormVisible(false);
          paymentReceipt.refetch();
        }}
      />
      <div style={{ display: "none" }}>
        <PrintModal
          cols={[3]}
          title="Payment Receipt"
          head={head}
          body={body}
          foots={foot}
          ref={componentRef}
        />
      </div>

      {hasPermission("add_paymentreceipt") && isMobileScreen ? (
        <>
          <FloatingMenu
            m={5}
            ml={60}
            left
            size={50}
            onClick={() => {
              setIsCsv(true);
              printFilter();
            }}
          >
            <IconDownload color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            left
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              setIsEditing(false);
              setPaymentReceiptFormVisible(true);
            }}
          >
            <IconPlus color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
