import { httpClient } from '../lib/axios';

export const api_getProducts = async () => {
  return await httpClient
    .get('product/')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const api_addProduct = async (payload) => {
  return await httpClient
    .post('product/', payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const api_editProduct = async (payload) => {
  return await httpClient
    .put('product/', payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const api_deleteProduct = async (id) => {
  return await httpClient
    .delete(`product/?product_id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const api_getProductCategories = async () => {
  return await httpClient
    .post('crud/', {
      function_type: 'read_all',
      data: null,
      many: true,
      app_name: 'com',
      model_name: 'subcategory',
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

// Batches API

export const api_getBatch = async (id) => {
  return await httpClient
    .get(`batch/?product_id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const api_getUnits = async () => {
  return await httpClient
    .post(`crud/`, {
      function_type: 'read_all',
      data: null,
      many: true,
      app_name: 'com',
      model_name: 'unit',
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const api_addBatch = async (payload) => {
  return await httpClient
    .post(`batch/`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const api_editBatch = async (payload) => {
  return await httpClient
    .put(`batch/`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const api_deleteBatch = async (id) => {
  return await httpClient
    .delete(`batch/?batch_id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
