import {
  ActionIcon,
  Box,
  Button,
  Col,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  NumberInput,
  Radio,
  Switch,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import {
  IconCheck,
  IconDownload,
  IconEdit,
  IconPlus,
  IconPrinter,
  IconTrash,
  IconX,
} from "@tabler/icons";
import FloatingMenu from "components/FloatingMenu";
import { PrintModal } from "components/PrintModal";
import produce from "immer";
import { MantineReactTable } from "mantine-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useReactToPrint } from "react-to-print";
import {
  getBodyContent,
  getCsvContent,
  getFields,
  getFilterPayload,
  getHeaders,
  getTotal,
} from "services/filterHelperFunctions";
import { api_filter } from "services/master.service";

import { coupons_api } from "./coupons.service";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";

export default function Coupons() {
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  const theme = useMantineTheme();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [coupons, setCoupons] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [filename, setFilename] = useState("file");
  const [modalOpened, setModalOpened] = useState(false);
  const [viewReturnModalOpened, setViewReturnModalOpened] = useState(false);
  const [returnModalDetails, setReturnModalDetails] = useState({});
  const [editingId, setEditingId] = useState(null);
  // END : States
  const [columnFilters, setColumnFilters] = useState([]);

  const handleEdit = values => {
    setIsEditing(true);
    console.log(values);
    returnForm.setValues(values);
    setEditingId(values.id);
    setModalOpened(true);
  };

  const returnForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      coupon_code: "",
      coupon_qty: "",
      coupon_type: "flat",
      value: "",
    },
    validate: {
      coupon_code: value => (value ? null : "Coupon code is required"),
      coupon_type: value => (value ? null : "Coupon qty is required"),
      value: value => (value ? null : "Value is required"),
    },
  });

  const openDeleteConfirmation = id => {
    openConfirmModal({
      title: "Please confirm delete coupon",
      children: (
        <Text size="sm">
          Are you sure you want to delete this coupon ? This action can't be undone.
        </Text>
      ),
      labels: { confirm: "Delete Coupon", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteCoupon(id),
      confirmProps: { color: "red" },
    });
  };

  const deleteCoupon = async id => {
    await coupons_api.delete(id).then(res => {
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.message });
        coupons_data.refetch();
      } else {
        showErrorToast({ title: "Error", message: res.message });
      }
    });
  };

  const changeStatus = (id, status) => {
    setCoupons(
      produce(draft => {
        let product = draft.find(e => e.id === id);
        product.active = status;
      })
    );
  };

  const handleStatusChange = async (productId, status) => {
    await coupons_api
      .changeStatus({
        product_id: productId,
      })
      .then(res => {
        if (res.success) {
          changeStatus(productId, status);
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      });
  };

  const coupons_data = useQuery("coupons", coupons_api.get, {
    onSuccess: data => setCoupons(data.data),
  });

  const handleFormSubmit = async values => {
    console.log("Coupon values", values);

    await coupons_api.add(values).then(res => {
      console.log(res);
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Coupon added successfully.",
        });
        setModalOpened(false);
        coupons_data.refetch();
      } else {
        showSuccessToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  const handleCouponEdit = async values => {
    console.log("Coupon values", values);

    await coupons_api
      .edit(editingId, {
        ...values,
      })
      .then(res => {
        console.log(res);
        if (res.success) {
          showSuccessToast({
            title: "Success",
            message: "Coupon edited successfully.",
          });
          setModalOpened(false);
          coupons_data.refetch();
        } else {
          showSuccessToast({
            title: "Error",
            message: res.message,
          });
        }
      });
  };

  const columns = useMemo(
    () => [
      {
        header: "Coupon Code",
        accessorKey: "coupon_code",
        size: "auto",
      },
      {
        header: "Type",
        accessorKey: "coupon_type",
        size: "auto",
        Cell: ({ cell, row }) => <Text tt={"capitalize"}>{`${cell.getValue()}`}</Text>,
      },
      {
        header: "Value",
        accessorKey: "value",
        size: "auto",
        filterVariant: "range",
        Cell: ({ cell, row }) => (
          <Text>
            {" "}
            {row.original.coupon_type === "Flat"
              ? `${cell.getValue()} %`
              : `Rs ${cell.getValue()}`}{" "}
          </Text>
        ),
      },
      {
        header: "Total Coupons",
        accessorKey: "coupon_qty",
        size: "auto",
        filterVariant: "range",
      },
      {
        header: "Status",
        accessorKey: "active",
        size: "auto",
        filterVariant: "select",
        mantineFilterSelectProps: {
          data: ["true", "false"],
        },
        Cell: ({ cell, row }) => (
          <Switch
            checked={cell.getValue()}
            thumbIcon={
              cell.getValue() ? (
                <IconCheck
                  size="0.8rem"
                  color={theme.colors.teal[theme.fn.primaryShade()]}
                  stroke={3}
                />
              ) : (
                <IconX size="0.8rem" color={theme.colors.red[theme.fn.primaryShade()]} stroke={3} />
              )
            }
          />
        ),
      },
      // {
      //   header: 'Coupons Remaining',
      //   accessorKey: 'remaining_coupons',
      //   size: 50,
      // },
    ],
    []
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   return total;
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length - 1 > i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length - 1 > i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   const cols = [2, 3];
  //   let sum = [];
  //   get_sum(removeAction).map((e, i) => {
  //     if (isNaN(e) || !cols?.includes(i, 0)) {
  //       sum.push("");
  //     } else {
  //       sum.push(e);
  //     }
  //   });
  //   csv_data.push(sum);
  //   setCsv(csv_data);
  // };

  const printFilter = async () => {
    const model = "Coupon";
    const filter = getFilterPayload(columns, columnFilters);
    const fields = getFields(columns);

    await api_filter(model, filter, fields)
      .then(res => {
        if (res.success) {
          console.log(res.data);
          const head_content = getHeaders(columns);
          const body_content = getBodyContent(res.data, fields);
          const csv_content = getCsvContent(head_content, body_content, ["COUPONS"]);
          const columnTotalIndex = [2, 3];
          const foot_content = getTotal(body_content, columnTotalIndex);
          setFilename(
            "COUPONS" + "_" + "WareHouse" + "_" + new Date().toLocaleDateString() + ".csv"
          );
          setCsv(csv_content);
          setHead(head_content);
          setBody(body_content);
          setFoot(foot_content);
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Coupons
                </Text>
                <AppBreadcrumb />
              </Box>
              <Box>
                <Flex align={"center"} justify={"center"} gap={10}>
                  <Button
                    onClick={() => {
                      setIsCsv(true);
                      printFilter();
                    }}
                    leftIcon={<IconDownload />}
                  >
                    CSV
                  </Button>
                  {/* <Button
                    ml={10}
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      print(true);
                    }}
                  >
                    Print
					</Button> */}
                  <Button
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      printFilter();
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    onClick={() => {
                      setModalOpened(true);
                    }}
                  >
                    <IconPlus style={{ width: "20px" }} />
                    <Text ml={10}> Add Coupon </Text>
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        {/* Add Product Form */}

        {/* Add Product Form */}

        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={columnFilters} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Coupons
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => {
                return (
                  <Flex>
                    <Tooltip label="Edit Coupon">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        ml={10}
                        onClick={() => {
                          handleEdit(row.original);
                        }}
                      >
                        <IconEdit style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>

                    <Tooltip label="Delete Coupon">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.red[6] })}
                        ml={10}
                        onClick={() => {
                          openDeleteConfirmation(row.original.id);
                        }}
                      >
                        <IconTrash style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                );
              }}
              initialState={{ density: "xs" }}
              //state={{ isLoading: coupons_data.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={coupons || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setIsEditing(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>{isEditing ? "Edit coupon" : "Add coupon"}</Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Box>
            <form
              onSubmit={returnForm.onSubmit(values => {
                !isEditing ? handleFormSubmit(values) : handleCouponEdit(values);
              })}
              onError={err => {
                console.log(err);
              }}
            >
              <Grid>
                <Col sm={6}>
                  <TextInput
                    label="Coupon Code"
                    placeholder="Enter coupon code"
                    {...returnForm.getInputProps("coupon_code")}
                  />
                </Col>
                <Col sm={6}>
                  <NumberInput
                    type="number"
                    label="Total Coupons"
                    placeholder="Enter Total Coupons"
                    {...returnForm.getInputProps("coupon_qty")}
                  />
                </Col>
                <Col sm={6}>
                  <Radio.Group
                    defaultValue="flat"
                    label="Select Discount Type"
                    {...returnForm.getInputProps("coupon_type")}
                  >
                    <Group mt="xs">
                      <Radio value="flat" label="Flat" />
                      <Radio value="amount" label="Amount" />
                    </Group>
                  </Radio.Group>
                </Col>
                {returnForm.values.coupon_type === "flat" ? (
                  <Col sm={6}>
                    <NumberInput
                      type="number"
                      label="Flat Percentage"
                      placeholder="Enter Flat Percentage"
                      max={100}
                      {...returnForm.getInputProps("value")}
                    />
                  </Col>
                ) : (
                  <Col sm={6}>
                    <NumberInput
                      type="number"
                      label="Amount"
                      placeholder="Enter coupon amount"
                      {...returnForm.getInputProps("value")}
                    />
                  </Col>
                )}

                <Col span={12} my={10}>
                  <Flex h={"100%"} direction={"column"} justify="end" align={"center"}>
                    <Button w={"100%"} type="submit">
                      {isEditing ? "Edit Coupon" : "Add Coupon"}
                    </Button>
                  </Flex>
                </Col>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
      <div style={{ display: "none" }}>
        <PrintModal
          cols={[2, 3]}
          title="Coupons"
          head={head}
          body={body}
          foots={foot}
          ref={componentRef}
        />
      </div>

      {isMobileScreen ? (
        <>
          <FloatingMenu
            m={5}
            left
            size={50}
            onClick={() => {
              setIsCsv(true);
              printFilter();
            }}
          >
            <IconDownload color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            ml={60}
            left
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              setModalOpened(true);
            }}
          >
            <IconPlus color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
