import { httpClient } from "lib/axios";

export const order_api = {
  get: async (pageSize, pageNumber, counter) => {
    let url = `order/admin/?page_size=${pageSize}&page_number=${pageNumber}`;
    if (counter) {
      url = `order/admin/?page_size=${pageSize}&page_number=${pageNumber}&counter_id=${counter}`;
    }
    return await httpClient
      .get(url)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_order: async id => {
    return await httpClient
      .get(`order/admin/?order_id=${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_delivery_partner: async counter => {
    return await httpClient
      .get(`delivery_partner/?counter_id=${counter}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  assign_delivery_partner: async payload => {
    return await httpClient.put("order/admin/?assign_delivery=true/", payload);
  },
  changeStatus: async payload => {
    return await httpClient.put("order/", payload);
  },
  cancleOrder: async payload => {
    return await httpClient.put("order/", payload);
  },
  delete: async review_id => {
    return await httpClient
      .delete(`review/?review_id=${review_id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  raiseInvoice: async (order_id, box, bag, rice_bag) => {
    return await httpClient
      .post(`/invoice/?order=${order_id}&box=${box}&bag=${bag}&rice_bag=${rice_bag}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  bulkDelete: async payload => {
    return await httpClient
      .delete(`review/?delete=true`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
};
