import { httpClient } from "../../../lib/axios";

export const api_getBanners = async () => {
  return await httpClient
    .get("banner/")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_addBanner = async payload => {
  return await httpClient
    .post("banner/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_editBannerImage = async payload => {
  return await httpClient
    .put("banner/?image=true", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_editBannerLocations = async payload => {
  return await httpClient
    .put("banner/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_editBannerProduct = async (product_id, payload) => {
  return await httpClient
    .put(`banner/?product=${product_id}`, payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_deleteBanner = async id => {
  return await httpClient
    .delete(`banner/?banner_id=${id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
