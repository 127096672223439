import { Box, Button, Divider, Modal, MultiSelect, Select, Text } from "@mantine/core";
import { getAlteredCities } from "pages/master/products/helperFunctions";
import { products_api } from "pages/master/products/products.service";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";
import { openDeleteConfirmation } from "utilities/utility";

import { stock_apis } from "./stock-transfer.service";

// import CounterUsersList from "./CounterUsersList";
// import { counters_api } from "./counter.service";

function SelectProductModal({ opened, onClose, handleAddProduct }) {
  const queryClient = useQueryClient();

  const [data, setData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedBatch, setselectedBatch] = useState();

  const [productId, setProductId] = useState(null);
  const [batchFocus, setBatchFocus] = useState(false);
  const [batches, setBatches] = useState([]);
  const buttonRef = useRef();
  const batchRef = useRef();

  // const orders_data = useQuery("orders", order_api.get);
  const products = useQuery({
    queryKey: ["stock-product-data"],
    onSuccess: data => {
      // console.log("this is `let`a", data.data);
      setData(
        data.data.map(e => {
          return { value: e.id, label: e.title };
        })
      );
    },
    queryFn: () => stock_apis.get_products(),
  });

  const getProduct = () => {
    console.log(products.data.data);
  };

  const handleSelectProduct = id => {
    setProductId(id);
    let product = products.data.data.find(e => e.id === id);
    setSelectedProduct(product);

    console.log(product);

    setBatches(
      product.batch.map(e => {
        const expirationDate = new Date(e.exp_date);
        const currentDate = new Date();
        const timeDifference = expirationDate.getTime() - currentDate.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        console.log(e, daysDifference);
        const datePlural = (daysDifference > 1) ? ' days' : ' day'
        const label = (daysDifference > 0) ? e.batch_no + ' (' + daysDifference + datePlural + ' to expire)' : e.batch_no + ' (expired)'
        return {
          label: label,
          value: e.id,
          stock: e.stock,
        };
      })
    );

    // handleAddProduct(product);
  };

  const handleSelectBatch = id => {
    // setselectedBatch();
    let batch = batches.find(e => e.value === id);
    setselectedBatch(batch);
  };

  const handleAddProductChild = () => {
    console.log("Selected product", selectedProduct);
    console.log("Selected batch", selectedBatch);

    const final = {
      ...selectedProduct,
      batch: selectedBatch,
      stock_qty: selectedBatch.stock
    };

    console.log(final);

    if (selectedProduct && selectedBatch) {
      handleAddProduct(final);
      onClose();
      setBatchFocus(false);
    } else {
      showErrorToast({ title: "Error", message: "Please enter product & batch both!" });
    }
  };

  return (
    <div>
      <Modal
        opened={opened}
        onClose={onClose}
        title={
          <Box px={10}>
            <Text fw={600}> Select Product </Text>
          </Box>
        }
        size={"800px"}
        centered
        autoFocus={false}
      >
        <Box px={10} mb={20}>
          <Divider mb={20} />
          <Box>
            <Box>
              <Select
                data-autofocus
                data={data || []}
                placeholder="Search products.."
                searchable
                withinPortal
                onChange={id => {
                  handleSelectProduct(id);
                  setBatchFocus(true);
                  // batchRef.current.click();
                  // batchRef.current.focus();
                  // console.log(batchRef);
                }}
                // onKeyDown={e => {
                //   if (e.key === "Enter") {
                //     batchRef.current.focus();
                    
                //   }
                // }}
              />
              {batchFocus && (
                <Select
                  autoFocus
                  ref={batchRef}
                  mt={10}
                  data={batches || []}
                  placeholder="Select Batch"
                  onChange={id => {
                    handleSelectBatch(id);
                    // console.log("child", id);
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter" && selectedBatch!==undefined) {
                      buttonRef.current.focus();
                      
                    }
                  }}
                  searchable
                  withinPortal
                />
              )}

              <Button ref={buttonRef} w={"100%"} mt={10} onClick={handleAddProductChild}>
                Add Product
              </Button>
            </Box>
          </Box>
          {/* <CounterUsersList data={data.counter_user} onUnassignUser={onClose}> */}
        </Box>
      </Modal>
    </div>
  );
}

SelectProductModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  editData: PropTypes.object,
};

export default SelectProductModal;
