import * as moment from "moment";
import { ActionIcon, Box, Button, Container, Flex, Text, Tooltip } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconDownload, IconPrinter, IconSquareRoundedCheck } from "@tabler/icons";
import FloatingMenu from "components/FloatingMenu";
import { PrintModal } from "components/PrintModal";
import { MantineReactTable } from "mantine-react-table";
import { hasPermission } from "pages/Auth/permissionGuard";
import { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  getBodyContent,
  getCsvContent,
  getCustomId,
  getFields,
  getFilterPayload,
  getHeaders,
} from "services/filterHelperFunctions";
import { api_filter } from "services/master.service";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import { report_api } from "./report.service";
import AppBreadcrumb from "../../../components/AppBreadcrumb";

export default function DeliveryReport() {
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  const [date, setDate] = useState(moment());
  let isMobileScreen = useMediaQuery("(max-width: 768px)");
  const [columnFilters, setColumnFilters] = useState([]);
  const [filename, setFilename] = useState("file");

  const openAcceptStockConfirmation = id => {
    openConfirmModal({
      size: "500px",
      centered: true,
      title: "Are you sure you want to acknowledge this ?",
      children: <Text size="sm">This cannot be undone.</Text>,
      labels: { confirm: "Acknowledge", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => await acknowledge(id),
      confirmProps: { color: "green" },
      position: "center",
    });
  };

  const acknowledge = async id => {
    await report_api.acknowledge_cutoff({ id }).then(res => {
      console.log(res);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.message });
        data.refetch();
      } else {
        showErrorToast({ title: "Error", message: res.message });
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Delivery Partner",
        accessorKey: "first_name",
        Cell: ({ row }) => <span> {row.original.delivery?.first_name} </span>,
        size: "auto",
      },
      {
        header: "Username",
        accessorKey: "username",
        Cell: ({ row }) => <span> {row.original.delivery?.username} </span>,
        size: "auto",
      },
      {
        header: "Cash",
        accessorKey: "cash__sum",
        Cell: ({ row }) => <span> {row.original.payment_receipt?.cash__sum || "-"} </span>,
        size: "auto",
      },
      {
        header: "Card",
        accessorKey: "card__sum",
        Cell: ({ row }) => <span> {row.original.payment_receipt?.card__sum || "-"} </span>,
        size: "auto",
      },
      {
        header: "Credit",
        accessorKey: "credit__sum",
        Cell: ({ row }) => <span> {row.original.payment_receipt?.credit__sum || "-"} </span>,
        size: "auto",
      },
      {
        header: "Gift",
        accessorKey: "gift__sum",
        Cell: ({ row }) => <span> {row.original.payment_receipt?.gift__sum || "-"} </span>,
        size: "auto",
      },
    ],
    []
  );

  const data = useQuery({
    queryKey: ["delivery-report", date],
    queryFn: () => report_api.get_delivery_report(),
    onSuccess: data => {
      console.log(data);
      if (!data.success) {
        showErrorToast({ title: "Error", message: data.message });
      }
    },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  //   //return parseFloat(text.replace(/[^0-9.]/g, ""));
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   return total;
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length !== i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   const cols = [];
  //   let sum = [];
  //   get_sum(removeAction).map((e, i) => {
  //     if (isNaN(e) || !cols?.includes(i, 0)) {
  //       sum.push("");
  //     } else {
  //       sum.push(e);
  //     }
  //   });
  //   csv_data.push(sum);
  //   setCsv(csv_data);
  // };

  const printFilter = async () => {
    console.log(columnFilters, data?.data?.data);

    let rows = [];
    data?.data?.data.map(e => {
      let cell = [];
      cell.push(e?.delivery?.first_name);
      cell.push(e?.delivery?.username);
      cell.push(e?.payment_receipt?.cash__sum);
      cell.push(e?.payment_receipt?.card__sum);
      cell.push(e?.payment_receipt?.credit__sum);
      cell.push(e?.payment_receipt?.gift__sum);
      rows.push(cell);
    });

    const head_content = getHeaders(columns);
    const body_content = rows;
    const csv_content = getCsvContent(head_content, body_content, ["DELIVERY_REPORT"]);
    setFilename(
      "DELIVERY_REPORT" + "_" + "WareHouse" + "_" + new Date().toLocaleDateString() + ".csv"
    );
    setCsv(csv_content);
    setHead(head_content);
    setBody(body_content);

    // const model = "Coupon";
    // const filter = getFilterPayload(columns, columnFilters);
    // const fields = getFields(columns);

    // await api_filter(model, filter, fields)
    //   .then(res => {
    //     if (res.success) {
    //       console.log(res.data);
    //       const head_content = getHeaders(columns);
    //       const body_content = getBodyContent(res.data, fields);
    //       const csv_content = getCsvContent(head_content, body_content);
    //       const columnTotalIndex = [2, 3];
    //       const foot_content = getTotal(body_content, columnTotalIndex);
    //       setCsv(csv_content);
    //       setHead(head_content);
    //       setBody(body_content);
    //       setFoot(foot_content);
    //     } else {
    //       showErrorToast({ title: "Error", message: res.message });
    //     }
    //   })
    //   .catch(e => {
    //     showErrorToast({ title: "Error", message: e.message });
    //   });
  };

  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      {isMobileScreen ? (
        <>
          {" "}
          <DatePickerInput
            p={5}
            onChange={setDate}
            label="Date"
            defaultValue={date}
            placeholder="Please Select Date"
            valueFormat="YYYY-MM-DD"
          />
        </>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Delivery Report
                </Text>
                <AppBreadcrumb />
              </Box>
              <Flex gap={10} align={"flex-end"}>
                <Button
                  onClick={() => {
                    setIsCsv(true);
                    printFilter();
                  }}
                  leftIcon={<IconDownload />}
                >
                  CSV
                </Button>
                {/* <Button
                  leftIcon={<IconPrinter />}
                  onClick={() => {
                    print(false);
                  }}
                >
                  Print
                </Button> */}
                <Button
                  leftIcon={<IconPrinter />}
                  onClick={() => {
                    printFilter();
                  }}
                >
                  Print
                </Button>
                <DatePickerInput
                  w={200}
                  onChange={setDate}
                  label="Date"
                  defaultValue={date}
                  placeholder="Please Select Date"
                  valueFormat="YYYY-MM-DD"
                />
              </Flex>
            </Flex>
          </Box>
        </Container>
      )}

      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={columnFilters} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable" }}
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    List
                  </Text>
                </Box>
              )}
              initialState={{ density: "xs" }}
              //state={{ isLoading: data.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={data.data?.data || []}
              enableStickyHeader
              enableGlobalFilter={false}
            />
          </Box>
        </Box>
      </Container>
      <div style={{ display: "none" }}>
        <PrintModal
          title="Delivery Report"
          head={head}
          body={body}
          foot={foot}
          ref={componentRef}
        />
      </div>

      {isMobileScreen ? (
        <>
          <FloatingMenu
            m={5}
            ml={60}
            left
            size={50}
            onClick={() => {
              setIsCsv(true);
              printFilter();
            }}
          >
            <IconDownload color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
