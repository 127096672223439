import _ from "lodash";
import moment from "moment";

/**
 * This functions returns products list with batches sorted in asscending order by expiry_date
 * @param products_list Takes products list array
 */
export const sortProductBatchesByDate = products_list => {
  let list = products_list;
  for (let i of list) {
    i.batches = i.batches.sort((a, b) =>
      moment(a.expiry_date, "DD/MM/YYYY").diff(moment(b.expiry_date, "DD/MM/YYYY"))
    );
  }
  return list;
};

/**
 * 
   Assigning first batch as selected batch for every product
  - Selecting batch which is nearest to expiry date and has stock > 0
   - Setting batch_code as unique identifier for product
 * @param sorted_products_list Takes sorted products list array which is retrived from sortProductBatchesByDate()
 */
export const assignInitialBatch = sorted_products_list => {
  let list = sorted_products_list;
  for (let i of list) {
    let batch_to_select = {};
    for (let j of i.batches) {
      if (_.isEmpty(batch_to_select)) {
        if (j.stock > 0) {
          batch_to_select = j;
          i.batch_code = j.batch_code;
        }
      }
    }
    i.selected_batch = batch_to_select;
  }
  return list;
};

/**
 * Modifying products array by the requirement of select component of ui library
 * @param products_list Takes array received from assigned_batch_list
 */
export const modifyProductsDataForSelect = products_list => {
  let modifiedArray = products_list.map(e => {
    return {
      ...e,
      // For select component
      label: e.product_name,
      value: e.batch_code,
    };
  });
  return modifiedArray;
};

/**
 * Modifying products array by the requirements
 * 1. It sorts every product's batch array by expiry date in asscending order
 * 2. It assign the batch to the product which is nearest to expiry and has enough stock
 * 3. It modifies the structure so that it can be used in search component
 * @param initial_products_list It takes product array retrived from the api
 */
export const getModifiedList = initial_products_list => {
  const sorted_list = sortProductBatchesByDate(initial_products_list);
  const assigned_batch_list = assignInitialBatch(sorted_list);
  const modified_list = modifyProductsDataForSelect(assigned_batch_list);

  return modified_list;
};

/**
 * Constructs invoice item with needed properties for invoice calculations
 * @param product Takes product to be added to invoice list & returns proper invoice item
 */
export const constructInvoiceItem = product => {
  return {
    ...product,
    box: 0,
    pieces: 0,
    free_samples: 0,
    discount: 0,
    discountPercentage: 0,
    qty: 0,
    entered_qty: "",
    total: 0,
    basic_amount: 0,
    tax_total: 0,
    selected_unit: "pieces",
  };
};
