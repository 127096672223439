import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  FileInput,
  Flex,
  Grid,
  Modal,
  NumberInput,
  Select,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { IconEdit, IconEye, IconPlus, IconTrash } from "@tabler/icons";
import FloatingMenu from "components/FloatingMenu";
import { image_url } from "config";
import { MantineReactTable } from "mantine-react-table";
import React, { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";

import { api_addCities, api_editCities, api_getCities } from "./cities.service";
import { getAlteredCities, getAlteredCitiesGroup } from "./helperFunctions";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";

export default function Cities() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [isEditing, setIsEditing] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [viewReturnModalOpened, setViewReturnModalOpened] = useState(false);
  const [returnModalDetails, setReturnModalDetails] = useState({});
  const [citiesGroupAltertedData, setCitiesGroupAltertedData] = useState([]);
  const [citiesAndGroupData, setCitiesAndGroupData] = useState([]);
  const [editingCityID, setEditingCityID] = useState("");
  // END : States

  // STARTS : States
  const [userFormVisible, setUserFormVisible] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  // ENDS : States

  // Fetching cities
  const cities = useQuery("cities", api_getCities, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setCitiesGroupAltertedData(getAlteredCitiesGroup(data.data));
      setCitiesAndGroupData(
        data.data
          .map(group =>
            group.city.map(city => [
              {
                city_id: city.id,
                city: city.city,
                city_image: city.image,
                group_id: group.id,
                group: group.name,
                group_image: group.image,
                pincode: Number(city.pincode),
              },
            ])
          )
          .reduce((r, a) => r.concat(a), [])
          .reduce((r, a) => r.concat(a), [])
      );
    },
  });
  //console.log(citiesAndGroupData);

  const handleEdit = values => {
    setIsEditing(true);
    console.log(values);

    setEditingCityID(values.city_id);
    returnForm.setFieldValue("city", values.city, { shouldValidate: true });
    //returnForm.setFieldValue("image", values.city_image, { shouldValidate: true });
    returnForm.setFieldValue("group", values.group_id, { shouldValidate: true });
    returnForm.setFieldValue("pincode", values.pincode, { shouldValidate: true });

    setModalOpened(true);
  };

  const returnForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      city: "",
      image: null,
      group: "",
      pincode: "",
    },
    validate: {
      city: value => (value ? null : "City is required"),
      group: value => (value ? null : "City Group is required"),
      pincode: value => {
        if (value.toString().length === 0) {
          return "Pincode is required";
        }
        if (value.toString().length !== 6) {
          return "Pincode is not valid";
        }
      },
    },
  });

  const openDeleteConfirmation = id => {
    openConfirmModal({
      title: "Please confirm delete return",
      children: (
        <Text size="sm">
          Are you sure you want to delete this return data ? This action can't be undone.
        </Text>
      ),
      labels: { confirm: "Delete City Group", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { color: "red" },
    });
  };

  // const users_data = useQuery('users', api_getUsers);

  const columns = useMemo(
    () => [
      {
        header: "City",
        accessorKey: "city",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => (
          <Flex align={"center"}>
            <img
              src={
                row.original.city_image !== null
                  ? image_url + row.original.city_image
                  : row.original.city_image
              }
              // src={image_url + row.original.thumbnail}
              width={30}
              height={30}
              style={{
                borderRadius: 100,
                border: "2px solid #eee",
                objectFit: "cover",
              }}
              alt=""
            />
            <Text ml={10}> {renderedCellValue} </Text>
          </Flex>
        ),
      },
      {
        header: "City Group",
        accessorKey: "group",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => (
          <Flex align={"center"}>
            <img
              src={
                row.original.group_image !== null
                  ? image_url + row.original.group_image
                  : row.original.group_image
              }
              // src={image_url + row.original.thumbnail}
              width={30}
              height={30}
              style={{
                borderRadius: 100,
                border: "2px solid #eee",
                objectFit: "cover",
              }}
              alt=""
            />
            <Text ml={10}> {renderedCellValue} </Text>
          </Flex>
        ),
      },
    ],
    []
  );

  const addCities = async values => {
    setLoading(true);
    console.log(values);

    const formData = new FormData();
    formData.append("city", values.city);
    formData.append("city_group_id", values.group);
    formData.append("pincode", values.pincode);
    if (values.image) {
      formData.append("image", values.image);
    }

    await api_addCities(formData).then(res => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.messge });
        setUserFormVisible(false);
        setLoginError(false);
        setModalOpened(false);
        cities.refetch();
      } else {
        setLoginErrorMessage(res.message);
        setLoginError(true);
      }
    });
  };

  const editCities = async values => {
    setLoading(true);
    console.log(values);

    const formData = new FormData();
    formData.append("id", editingCityID);
    formData.append("city", values.city);
    formData.append("city_group_id", values.group);
    formData.append("pincode", values.pincode);
    if (values.image) {
      formData.append("image", values.image);
    }

    await api_editCities(formData).then(res => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.messge });
        setUserFormVisible(false);
        setLoginError(false);
        setModalOpened(false);
        cities.refetch();
      } else {
        setLoginErrorMessage(res.message);
        setLoginError(true);
      }
    });
  };
  //console.log("zzz", citiesData);

  return (
    <>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Cities
                </Text>
                <AppBreadcrumb
                  crumbs={[
                    {
                      text: "Transactions",
                    },
                    {
                      text: "Returns",
                    },
                  ]}
                />
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    returnForm.reset();
                    setModalOpened(true);
                  }}
                >
                  <IconPlus style={{ width: "20px" }} />
                  <Text ml={10}> Add City </Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        {/* Add Product Form */}

        {/* Add Product Form */}

        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Cities
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => {
                return (
                  <Flex>
                    <Tooltip label="Edit City">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        ml={10}
                        onClick={() => {
                          handleEdit(row.original);
                        }}
                      >
                        <IconEdit style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>

                    <Tooltip label="Delete City">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.red[6] })}
                        ml={10}
                        onClick={() => {
                          openDeleteConfirmation(row.id);
                        }}
                      >
                        <IconTrash style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                );
              }}
              initialState={{ density: "xs" }}
              // state={{ isLoading: users_data.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={citiesAndGroupData || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setIsEditing(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>{isEditing ? "Edit city" : "Add city"}</Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Box>
            <form
              onSubmit={returnForm.onSubmit(values => {
                console.log(values);
                isEditing ? editCities(values) : addCities(values);
              })}
            >
              <Grid>
                <Col sm={12}>
                  <Select
                    data={citiesGroupAltertedData || [{}]}
                    searchable
                    nothingFound="No cities group found"
                    label="City Group Name"
                    placeholder="Enter City Group Name"
                    {...returnForm.getInputProps("group")}
                  />
                </Col>
                <Col sm={12}>
                  <TextInput
                    label="City Name"
                    placeholder="Enter City Name"
                    {...returnForm.getInputProps("city")}
                  />
                </Col>
                <Col sm={6}>
                  <FileInput
                    {...returnForm.getInputProps("image")}
                    label="City Image"
                    placeholder="Select Image"
                  />
                </Col>
                <Col span={isMobileScreen ? 12 : 6}>
                  <NumberInput
                    label="Pincode"
                    placeholder="Enter pincode"
                    {...returnForm.getInputProps("pincode")}
                    hideControls
                  />
                </Col>
                <Col span={12} my={10}>
                  <Flex h={"100%"} direction={"column"} justify="end" align={"center"}>
                    <Button w={"100%"} type="submit">
                      {isEditing ? "Edit City" : "Add City"}
                    </Button>
                  </Flex>
                </Col>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>

      {/* View Returns Modal */}

      <Modal
        opened={viewReturnModalOpened}
        onClose={() => {
          setViewReturnModalOpened(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>Return Details</Text>
          </Box>
        }
        size={"500px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Grid>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Product
              </Text>
              <Text> {returnModalDetails.product_name} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Batch Code
              </Text>
              <Text> {returnModalDetails.batch_code} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Qty
              </Text>
              <Text> {returnModalDetails.qty} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Amount
              </Text>
              <Text> ₹ {returnModalDetails.amount} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Reason
              </Text>
              <Text> {returnModalDetails.reason} </Text>
            </Col>
            <Col span={6}>
              <Text size={"xs"} opacity={0.4}>
                Status
              </Text>
              <Text> {returnModalDetails.status} </Text>
            </Col>
            <Col span={12}>
              <Text size={"xs"} opacity={0.4}>
                Description
              </Text>
              <Text> {returnModalDetails.description} </Text>
            </Col>
          </Grid>
        </Box>
      </Modal>

      {isMobileScreen ? (
        <FloatingMenu
          m={5}
          right
          size={50}
          onClick={() => {
            returnForm.reset();
            setModalOpened(true);
          }}
        >
          <IconPlus color="white" />
        </FloatingMenu>
      ) : (
        <></>
      )}
    </>
  );
}
