export const API_URL = process.env.REACT_APP_API;
export const image_url = process.env.REACT_APP_IMAGE;
export const theme_colors = process.env.REACT_APP_THEME_COLORS.split(",");
export const theme_font = process.env.REACT_APP_THEME_FONT;
export const theme_font_sizes = process.env.REACT_APP_THEME_FONT_SIZES;
export const logo_name = process.env.REACT_APP_LOGO_PATH;

export const header_bg_shade = parseInt(process.env.REACT_APP_HEADER_BG_SHADE)
export const header_text_shade = parseInt(process.env.REACT_APP_HEADER_TEXT_SHADE)
export const primary_shade = parseInt(process.env.REACT_APP_PRIMARY_SHADE)

export const enable_cashier = parseInt(process.env.REACT_APP_ENABLE_CASHIER)
export const enable_return = parseInt(process.env.REACT_APP_ENABLE_RETURN)
export const return_days = parseInt(process.env.REACT_APP_RETURN_DAYS)
