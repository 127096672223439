import {
  Avatar,
  Box,
  Container,
  Flex,
  Group,
  Menu,
  Text,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconAlbum,
  IconArrowLeft,
  IconArrowsTransferDown,
  IconBox,
  IconBoxModel,
  IconBuilding,
  IconBuildingBridge,
  IconCurrency,
  IconCurrencyRupee,
  IconDatabase,
  IconList,
  IconListDetails,
  IconLogout,
  IconMap,
  IconReport,
  IconStars,
  IconTag,
  IconTicket,
  IconTruck,
  IconTruckDelivery,
  IconTruckReturn,
  IconUser,
  IconUsers,
} from "@tabler/icons";
import { header_bg_shade } from "config";
import { header_text_shade } from "config";
import { logo_name } from "config";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const useStyles = createStyles(theme => ({
  link: {
    display: "block",
    lineHeight: 1,

    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.brand[9],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: 5,
  },
}));

function AppHeader() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const role = localStorage.getItem("role");
  const user = JSON.parse(localStorage.getItem("user"));
  const counter_name = user?.user?.counter_name;
  const location = useLocation();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");


  const [logo, setLogo] = useState('');
  useEffect(() => {
    import(`../static/images/${logo_name}`).then((module) => {
      setLogo(module.default);
    });
  }, [])
  // RENDER STARTS HERE
  return (
    <>
      {/* Top Header */}
      <div>
        <Box
          px={isMobileScreen ? 0 : 20}
          py={isMobileScreen ? 10 : 16}
          sx={theme => ({
            background: theme.colors.brand[header_bg_shade],
          })}
        >
          <Container fluid>
            <Flex justify={"space-between"} align="center" px={5}>
              <Flex align={"center"} justify={"center"}>
                {location.pathname === "/" ? (
                  <></>
                ) : (
                  <>
                    {isMobileScreen ? (
                      <IconArrowLeft
                        onClick={() => {
                          navigate(-1);
                        }}
                        color={theme.colors.brand[5]}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <Box h={"100%"}>
                  <Text
                    fw={800}
                    size={"lg"}
                    style={{
                      letterSpacing: "-1px",
                    }}
                  >
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      to={"/"}
                    >
                      <img
                        src={logo}
                        width={150}
                        height={49}
                        style={{
                          objectFit: "cover",
                        }}
                        alt="logo"
                      />
                    </Link>
                  </Text>
                </Box>
                <Text color={theme.colors.brand[header_text_shade]} size="xl" fw={500}>
                  - {role == "manager" ? "Admin" : counter_name}
                </Text>
              </Flex>

              <Menu position="bottom-end" shadow="md" width={200} trigger="hover">
                <Menu.Target>
                  <Avatar radius="xl" size={"30px"} />
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label> Settings </Menu.Label>
                  <Menu.Item icon={<IconUser size={14} />} component={Link} to="/manage-account">
                    Manage Account
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconLogout size={14} />}
                    onClick={() => {
                      localStorage.clear();
                      navigate("/login");
                    }}
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Flex>
          </Container>
        </Box>
      </div>
      {/* Header */}
      <Box p={isMobileScreen ? 0 : 10} sx={theme => ({ background: "white" })}>
        <Container fluid>
          <Flex justify={isMobileScreen ? "space-between" : "flex-start"}>
            {role === "manager" && (
              <Menu position="bottom-start" shadow="md" width={200} trigger="hover">
                <Menu.Target>
                  <span className={classes.link}>
                    <Flex align={"center"}>
                      <IconDatabase size={"18"} /> <Text ml={5}>Master</Text>
                    </Flex>
                  </span>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label> Master </Menu.Label>
                  <Menu.Item icon={<IconUsers size={14} />} component={Link} to="/users">
                    Users
                  </Menu.Item>
                  <Menu.Item icon={<IconUser size={14} />} component={Link} to="/customers">
                    Customers
                  </Menu.Item>
                  <Menu.Item icon={<IconMap size={14} />} component={Link} to="/address">
                    Addresses
                  </Menu.Item>
                  <Menu.Item icon={<IconBuilding size={14} />} component={Link} to="/counters">
                    Counters
                  </Menu.Item>
                  <Menu.Item icon={<IconTag size={14} />} component={Link} to="/categories">
                    Product Categories
                  </Menu.Item>
                  <Menu.Item icon={<IconBox size={14} />} component={Link} to="/products">
                    Products
                  </Menu.Item>
                  <Menu.Item icon={<IconStars size={14} />} component={Link} to="/reviews">
                    Reviews
                  </Menu.Item>
                  <Menu.Item icon={<IconListDetails size={14} />} component={Link} to="/feedback">
                    Feedback
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconBuildingBridge size={14} />}
                    component={Link}
                    to="/city-groups"
                  >
                    City Groups
                  </Menu.Item>
                  <Menu.Item icon={<IconBuilding size={14} />} component={Link} to="/cities">
                    Cities
                  </Menu.Item>
                  <Menu.Item icon={<IconTicket size={14} />} component={Link} to="/coupons">
                    Coupons
                  </Menu.Item>

                  <Menu.Item icon={<IconAlbum size={14} />} component={Link} to="/banners">
                    Banners
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}

            {role !== "delivery" && (
              <>
                <Menu position="bottom-start" shadow="md" width={200} trigger="hover">
                  <Menu.Target>
                    <span className={classes.link}>
                      <Flex align={"center"}>
                        <IconBox size={"18"} /> <Text ml={5}>Inventory</Text>
                      </Flex>
                    </span>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Label> Inventory </Menu.Label>
                    <Menu.Item icon={<IconList size={14} />} component={Link} to="/stock-list">
                      Stock List
                    </Menu.Item>
                    <Menu.Item icon={<IconTruck size={14} />} component={Link} to="/stock-transfer">
                      Stock Transfer
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
                <Menu position="bottom-start" shadow="md" width={200} trigger="hover">
                  <Menu.Target>
                    <span className={classes.link}>
                      <Flex align={"center"}>
                        <IconArrowsTransferDown size={"18"} /> <Text ml={5}>Transactions</Text>
                      </Flex>
                    </span>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label> Transactions</Menu.Label>
                    <Menu.Item
                      icon={<IconCurrencyRupee size={14} />}
                      component={Link}
                      to="/invoices"
                    >
                      Invoices
                    </Menu.Item>
                    {/* <Menu.Item
                        icon={<IconCurrency size={14} />}
                        component={Link}
                        to="/warehouse-invoices"
                      >
                        Warehouse Invoices
                      </Menu.Item> */}
                    <Menu.Item icon={<IconBoxModel size={14} />} component={Link} to="/orders">
                      Orders
                    </Menu.Item>
                    <Menu.Item icon={<IconTruckReturn size={14} />} component={Link} to="/returns">
                      Returns
                    </Menu.Item>
                    <Menu.Item
                      icon={<IconTruckReturn size={14} />}
                      component={Link}
                      to="/payment-receipt"
                    >
                      Payment Receipt
                    </Menu.Item>

                    <Menu.Item icon={<IconReport size={14} />} component={Link} to="/sales-report">
                      Cut-off sales report
                    </Menu.Item>
                    <Menu.Item
                      icon={<IconTruckDelivery size={14} />}
                      component={Link}
                      to="/delivery-report"
                    >
                      Delivery Report
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </>
            )}

            {/* Deliveries */}
            {role === "delivery" && (
              <Menu position="bottom-start" shadow="md" width={200} trigger="hover">
                <Menu.Target>
                  <span className={classes.link}>
                    <Flex align={"center"}>
                      <IconTruck size={"18"} /> <Text ml={5}>Delivery</Text>
                    </Flex>
                  </span>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label> Delivery </Menu.Label>
                  <Menu.Item
                    icon={<IconTruckDelivery size={14} />}
                    component={Link}
                    to="/deliveries"
                  >
                    All Deliveries
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
            {/* <AppSpotlight /> */}
          </Flex>
        </Container>
      </Box>
    </>
  );
}

export default AppHeader;
