import { httpClient } from "../../../lib/axios";

export const products_api = {
  get: async () => {
    return await httpClient
      .get("product/?page_size=1000&page_number=1&admin=true")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  get_by_id: async id => {
    return await httpClient
      .get(`product/?product_id=${id}&admin=true`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  deleteImage: async imageId => {
    return httpClient
      .post(`/product/?product_image=true&delete_image=${imageId}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  uploadImages: async payload => {
    return httpClient
      .post(`/product/?product_image=true`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  change_active_status: async payload => {
    return await httpClient
      .put("product/?status=active", payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  add: async payload => {
    return await httpClient
      .post("product/?product=true", payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  edit: async payload => {
    return await httpClient
      .put("product/?product=true", payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  delete: async category_id => {
    return await httpClient
      .delete(`category/?category_id=${category_id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  getCities: async () => {
    return await httpClient
      .get("city/")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
  syncBatches: async () => {
    return await httpClient
      .get("batch/?sync_batch=true/")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  },
};
