import { httpClient } from "../../../lib/axios";

export const api_getCustomers = async () => {
  return await httpClient
    .get("customer/")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_addCustomer = async payload => {
  return await httpClient
    .post("customer/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_editCustomer = async payload => {
  return await httpClient
    .put("customer/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_deleteCustomer = async user_id => {
  return await httpClient
    .delete(`customer/?user_id=${user_id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
